import React, { Component } from 'react';
import LoginHeader from './Login/LoginHeader';
import SignupFormWithCard from './SignupFormWithCard';
import SignupForm from './SignupForm';
import IdSelectorForm from './IdSelectorForm';
import {CompanyContext} from '../contexts/company-context';
import {loadStripe} from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';



class Signup extends Component {

  constructor(){
    super();
    this.state = {
      useCreditCardForm: false,
      senderEmail: null,
      senderFirstName: null,
      senderLastName: null,
      useIdSelector: false
    }
    this.onIdSelectedHandler = this.onIdSelectedHandler.bind(this);
  }

  componentDidMount(){
    if(this.context.company.id==166){
      this.setState({
        useIdSelector: true
      })
    }
  }

  onIdSelectedHandler(resp){
    this.setState({
      senderEmail: resp.email,
      senderFirstName: resp.first_name,
      senderLastName: resp.last_name
    })
  }


  render() {
     const stripePromise = loadStripe('pk_test_3Dv62q58K4PO6J0BiNoUA0t7');
     let signupForm = <SignupForm email={this.state.senderEmail} firstName={this.state.senderFirstName} lastName={this.state.senderLastName}/>;
     if(this.state.useIdSelector && !this.state.senderEmail){
      signupForm = <IdSelectorForm onIdSelected={this.onIdSelectedHandler}/>;
     } else{
      if(this.state.useCreditCardForm){
        signupForm = <Elements stripe={stripePromise}>
        <ElementsConsumer>
        {({stripe, elements}) => (
         <SignupFormWithCard stripe={stripe} elements={elements}/>
         )}
        </ElementsConsumer>
        </Elements>
      }
     }

    return (
      <div className="be-wrapper be-login">
      <div className="be-content">
        <div className="main-content container-fluid">
          <div className="splash-container">
      <div className="card card-border-color card-border-color-primary">
        <LoginHeader imageUrl={this.context.company.minisite_logo_url}/>
        <div className="card-body">
        {signupForm}
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    );
  }
}

Signup.contextType = CompanyContext;
export default Signup;
