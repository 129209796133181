import React, { Component } from 'react';
import TableColumnHeader from '../../generic/TableColumnHeader'
import TablePaginationContainer from '../../generic/TablePaginationContainer'
import Modal from '../../generic/Modal';
import EventTypeTemplatesTableRow from './EventTypeTemplatesTableRow'
import EventTypeTemplateFormEditContainer from './EventTypeTemplateFormEditContainer'
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'


class EventTypeTemplatesTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/event_type_templates';
    this.state = {
      eventTypeTemplateId: null,
      eventTypeTemplates: [],
      eventTypeTemplateColumns: [
      { name: 'Event Type Name', sortApiColumn: 'event_type_templates.event_type_id', sortable: true },
      { name: 'Subject Line', sortApiColumn: 'event_type_templates.subject', sortable: true },
      { name: 'Recurrence', sortApiColumn: 'event_type_templates.recurrence_year', sortable: true },
      { name: 'Ecard', sortApiColumn: 'event_type_templates.source_id', sortable: true },
      { name: ''  }
      ],
      currentSortColumn: 'event_type_templates.event_type_id',
      currentSortDirection: 'desc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getEventTypeTemplates = this.getEventTypeTemplates.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadEventTypeTemplate = this.loadEventTypeTemplate.bind(this);
    this.updateEventTypeTemplateState = this.updateEventTypeTemplateState.bind(this);
    this.duplicateEventTypeTemplateState = this.duplicateEventTypeTemplateState.bind(this);
    this.removeEventTypeTemplateState = this.removeEventTypeTemplateState.bind(this);
    this.removeEventTypeTemplate = this.removeEventTypeTemplate.bind(this);
  }

  componentDidMount() {
    this.getEventTypeTemplates();
  }

  componentDidUpdate() {
    if(this.props.eventTypeTemplateCreated){
      this.getEventTypeTemplates();
    }
  }


  loadEventTypeTemplate(eventTypeTemplateId){
    this.setState({
      eventTypeTemplateId: eventTypeTemplateId
    })
  }

  removeEventTypeTemplate(eventTypeTemplateId){
    if(window.confirm('Are you sure you want to remove this eventTypeTemplate?')){
      fetchAuthorized(this.baseUrl+'/'+eventTypeTemplateId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          eventTypeTemplateId: eventTypeTemplateId
          },()=>this.removeEventTypeTemplateState())
      })
    }
  }

  duplicateEventTypeTemplateState(){
    const index = this.state.eventTypeTemplates.findIndex(item => item.id === this.state.eventTypeTemplateId);
    let eventTypeTemplates = [...this.state.eventTypeTemplates] // important to create a copy, otherwise you'll modify state outside of setState call
    let eventTypeTemplateDup = Object.assign({},this.state.eventTypeTemplates[index]);
    return {index: index, eventTypeTemplate: eventTypeTemplateDup, eventTypeTemplates: eventTypeTemplates}
  }

  currentEventTypeTemplate(){
    let dup = this.duplicateEventTypeTemplateState();
    return dup.eventTypeTemplates[dup.index];
  }

  // called from UpdateEventTypeTemplateForm Container component after the api is updated
  // sent to the comonent as saveEventTypeTemplateCallback
  updateEventTypeTemplateState(){
    let dup = this.duplicateEventTypeTemplateState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.eventTypeTemplateId, { type: 'connect'})).then(eventTypeTemplate => {
      dup.eventTypeTemplates[dup.index] = eventTypeTemplate.event_type_template;
      this.setState({eventTypeTemplateId: null, eventTypeTemplates: dup.eventTypeTemplates});
      Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    });
  }

  removeEventTypeTemplateState(){
    let dup = this.duplicateEventTypeTemplateState();
    let filteredEventTypeTemplates = dup.eventTypeTemplates.slice(0, dup.index).concat(dup.eventTypeTemplates.slice(dup.index + 1, dup.eventTypeTemplates.length))
    this.setState({eventTypeTemplatesId: null, eventTypeTemplates: filteredEventTypeTemplates},()=>console.log(this.state.eventTypeTemplates));
  }

 setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getEventTypeTemplates();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getEventTypeTemplates();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getEventTypeTemplates();
    })
  }


  /*
  http://localhost:3000/api/v1/eventTypeTemplates?
        by_status=
        &direction=desc
        &end_date=2019-01-16
        &items_type=Electronic
        &not_archived=true
        &page=1
        &per_page=10
        &production_only=true
        &q=
        &sort=placedAt
        &start_date=2019-01-01
        &type=
  */

  setParams(){
    return {
      type: 'connect',
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }

  getEventTypeTemplates() {
    let params = this.setParams();
    if(this.props.senderId) params['sender_id'] = this.props.senderId;
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(eventTypeTemplates => {
       console.log(eventTypeTemplates);
        if(eventTypeTemplates.error || eventTypeTemplates.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(eventTypeTemplates.event_type_templates.length){
            let paginationData = Object.assign({}, this.state.paginationData);
            paginationData.totalPages =  parseInt(eventTypeTemplates.meta.total_pages);
            paginationData.totalItems =  parseInt(eventTypeTemplates.meta.total_items);
            paginationData.currentPage = parseInt(eventTypeTemplates.meta.page);
            this.setState({ eventTypeTemplates: eventTypeTemplates.event_type_templates, paginationData: paginationData });
            this.props.onEventTypeTemplatesLoaded();
          }else{
            this.setState({eventTypeTemplates: []});
          }
        }
      })
  }

  render() {

    let modal = <Modal id="edit-event-type-template"/>;
    if(this.state.eventTypeTemplateId && this.currentEventTypeTemplate()!=undefined){
      let currentEventTypeTemplate = this.currentEventTypeTemplate();
      modal = <Modal title="Edit Template" id="edit-event-type-template" iconClassName="mdi mdi-account">
        <EventTypeTemplateFormEditContainer saveEventTypeTemplateCallback={this.updateEventTypeTemplateState} eventTypeTemplate={currentEventTypeTemplate} senderId={this.props.senderId}/>
      </Modal>;
    }
    //

    let eventTypeTemplateList = this.state.eventTypeTemplates.map((eventTypeTemplate)=>{
      return <EventTypeTemplatesTableRow key={setKey('eventTypeTemplate-'+eventTypeTemplate.id)} loadEventTypeTemplate={this.loadEventTypeTemplate} removeEventTypeTemplate={this.removeEventTypeTemplate} key={eventTypeTemplate.id} eventTypeTemplate={eventTypeTemplate}/>
    })

    let eventTypeTemplateColumns = this.state.eventTypeTemplateColumns.map((col)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={col.name} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    let paginationSection = <TablePaginationContainer setPage={this.setPage} paginationData = {this.state.paginationData} setEntryLength={this.setEntryLength}/>

    return (
      <div className="card card-table">
      {modal}
        <div className="card-body">
          <div id="eventTypeTemplateTable">
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive event-type-template-table">
                  <thead>
                    <tr>
                    {eventTypeTemplateColumns}
                    </tr>
                  </thead>
                    {eventTypeTemplateList}
                </table>
              </div>
            </div>

            {paginationSection}
          </div>
        </div>
      </div>
    )
  }
}

export default EventTypeTemplatesTable;
