import React, { Component } from 'react';
import { setKey } from '../utils/KeySetter'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import Select from 'react-select';

class EcardSelect extends Component {
  constructor(){
    super();
     this.state = {
      ecards: [],
      currentEcardId: null
    }
    this.getEcards = this.getEcards.bind(this);
    this.selectEcard = this.selectEcard.bind(this);
  }

  componentDidMount(){
    if(this.state.ecards.length == 0){
      this.getEcards();
    }
  }

  getEcards() {
    let url = '/api/v1/categories';
    if(this.props.categoryId)  {
      url = url+'/'+this.props.categoryId
    } else{
      url = url +'/connect_root'
    }

     fetchAuthorized(generateUrl(url, {type: 'connect', per_page: 'all'})).then(category => {
       if(category.error || category.errors){
         console.log('UNAUTHORIZED');
       }else{
         if(category){
           this.setState({
             category: category.category,
             ecards: category.category.ecards,
             currentEcardId: this.props.currentEcardId
           });
         }else{
           this.setState({category: {}, ecards: [], currentEcardId: null});
         }
       }
     })
   }

   selectEcard(e){
    let index = e.currentTarget.dataset.index;
    let ecard =  Object.assign({},this.state.ecards[index]);
    this.setState({
      currentEcardId: ecard.id
    },()=>{
      this.props.onEcardSelected(ecard)
    })

   }


  render() {
    if(!this.state.ecards) return null;
    let classNames = "";
    let classNamePrefixes = this.props.classNamePrefixes ? this.props.classNamePrefixes : "col-6 col-lg-6";
    const ecards = this.state.ecards.map((ecard,index)=>{
      let ecardLabel = this.props.showEcardLabel ? <label className="ecard-label">{ecard.name}</label> : '';

      if(parseInt(this.state.currentEcardId)==parseInt(ecard.id)){
        classNames = classNamePrefixes+" active";
      } else{
        classNames= classNamePrefixes;
      }
      return <div key={setKey("ecard-"+ecard.id.toString())} onClick={this.selectEcard} data-index={index} className={classNames}>
              <img src={ecard.front_image} alt="ecard-front" className="ecard-select-img"/>
              {ecardLabel}
            </div>
    })

    return (
      <div className="row">
            {ecards}
          </div>

      )
  }
}
export default EcardSelect;





