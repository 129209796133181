import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom'

import Logout from '../pages/Logout'
import ManagerHome from '../pages/ManagerHome'
import ManagerEventDates from '../pages/ManagerEventDates'
import ManagerSettings from '../pages/ManagerSettings'
import ManagerHistory from '../pages/ManagerHistory'
import ManagerContacts from '../pages/ManagerContacts'
import NotFound from '../pages/NotFound'

class ManagerRoutes extends Component {

  render() {
    if (this.props.senders.length === 1 && window.location.pathname ==='/') {
      let url ='/senders/'+this.props.senders[0].id;
      return <Redirect to={url}/>
    }
    return <Switch>
          <Route path='/' exact component={ManagerHome} />
          <Route path='/logout' exact component={Logout} />
          <Route path="/senders/:id/contacts" component={ManagerContacts} />
          <Route path="/senders/:id/history" component={ManagerHistory} />
          <Route path="/senders/:id/settings" component={ManagerSettings} />
          <Route path="/senders/:id" component={ManagerEventDates} />
          <Route path="/senders" component={ManagerHome} />
          <Route component={NotFound} />
      </Switch>
  }
}

export default ManagerRoutes;



