import React, { Component } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import {Promise} from "bluebird";
import ErrorList from '../generic/ErrorList';
import { fetch, fetchAuthorized, generateUrl } from '../utils/Fetcher'
import ls from 'local-storage';

class TrialExpiredForm extends Component {
  constructor(){
    super();
    this.state = {
      error: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stripePaymentMethodHandler = this.stripePaymentMethodHandler.bind(this);
  }


  componentDidMount(){
    document.body.classList.add('be-splash-screen');
  }


  // Handle real-time validation errors from the card Element.
  handleChange(event) {
    if (event.error) {
      this.setState({error: event.error.message});
    } else {
      this.setState({error: null});
    }
  }


  // Handle form submission.
  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    let elements = this.props.elements;
    let card = elements.getElement(CardElement);
    let stripe = this.props.stripe;
    let paymentMethodDetails = {
      type: 'card',
      card: card
    }
    Promise.resolve(stripe.createPaymentMethod(paymentMethodDetails)).then((response)=>{
      console.log(response);
      if (response.error) {
        console.log("ERROR");
        // Inform the user if there was an error.
        _this.setState({error: response.error.message});
      } else {
        console.log("NO ERROR");
        _this.setState({error: null});
        // Send the token to your server.
        _this.stripePaymentMethodHandler(response);
      }
    });
  };



/*
 *fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    })
 *formData.append('member[user_attributes][id]', formObjectValues.user_id);
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][password]', formObjectValues.password);
 */


  stripePaymentMethodHandler(response) {
    let _this = this;
    let paymentMethodId = response.paymentMethod.id;
    let formData = new FormData();
    formData.append('member[stripe_token]',paymentMethodId);
    fetchAuthorized('/api/v1/members/'+_this.props.sender.id+'/update_payment_method', {
      method: 'POST',
      body: formData
    }).then(result => {
      if(result.errors){
        this.setState({error: result.errors})
      }else{
        _this.setState({error: null});
        // Create Subscription
        _this.createSubscription(result,response.paymentMethod.id);
      }

   })
  }

  createSubscription(response, paymentMethodId){
    let _this = this;
    let formData = new FormData();
    formData.append('member[stripe_token]',paymentMethodId);
    fetchAuthorized('/api/v1/members/'+_this.props.sender.id+'/create_subscription', {
      method: 'POST',
      body: formData
    }).then(result => {
      if(result.errors){
        this.setState({error: result.errors})
      }else{
        _this.setState({error: null});
        // Create Subscription
        window.location.href= '/';
      }
   })
  }


  render() {
    // Custom styling can be passed to options when creating an Element.
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };

  return (

      <form>
      <ErrorList errors={this.state.error}/>
          <div className="form-group">
          <h3>Your trial has expired.  Please enter your credit card information to continue.</h3>
          <br/><br/>
        <label className="input-header">
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={this.handleChange}
        />
        </div>
        <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.handleSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Start my subscription.</button>
                </p>
              </div>
            </div>
          </div>
    </form>

      )
  }
}

export default TrialExpiredForm;
