import React, { Component } from 'react';
import AdminNav from '../nav/AdminNav';
import Search from '../generic/Search';
import SectionHeader from '../generic/SectionHeader';
import Modal from '../generic/Modal';
import ManagersTable from './Admin/ManagersTable';
import ManagerFormNewContainer from './Admin/ManagerFormNewContainer';
import { setKey } from '../utils/KeySetter'

class AdminManagers extends Component {
  constructor(){
    super();
    this.state ={
      managerCreated: false
    }
    this.resetState = this.resetState.bind(this);
    this.addManagerToTable = this.addManagerToTable.bind(this);
  }
  addManagerToTable(refs){
    this.setState({managerCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  resetState(){
    if(this.state.managerCreated){
      this.setState({managerCreated: false});
    }
  }

  render() {
    return (
      <div className="managers be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
        <Modal title="New Manager" id="new-manager" iconClassName="mdi mdi-account">
          <ManagerFormNewContainer key={setKey()} saveManagerCallback={this.addManagerToTable}/>
        </Modal>
        <Modal title="Import Managers" id="import-managers" iconClassName="mdi mdi-account">
          SENDER SHAKALA
        </Modal>
          <SectionHeader label='Your Managers' newModalTarget='#new-manager' importModalTarget='#import-managers'/>
          <div className="managers-content main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                  <ManagersTable managerCreated={this.state.managerCreated} onManagersLoaded={this.resetState}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminManagers;
