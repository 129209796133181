import React, { Component } from 'react';
import LoginHeader from './Login/LoginHeader';
import TrialExpiredForm from './TrialExpiredForm';
import {CompanyContext} from '../contexts/company-context';
import { SenderContext } from '../contexts/sender-context';
import {loadStripe} from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';



class TrialExpired extends Component {

  constructor(){
    super();
    this.state = {
      sender: null
    }
    this.updateSenderState = this.updateSenderState.bind(this);
  }


  updateSenderState(){
    console.log('wh');
  }


  render() {
    if(!this.context.sender) return null;
     const stripePromise = loadStripe('pk_test_3Dv62q58K4PO6J0BiNoUA0t7');
    return (
      <CompanyContext.Consumer>
      {({company})=>(
      <div className="be-wrapper be-login">
      <div className="be-content">
        <div className="main-content container-fluid">
          <div className="splash-container">
      <div className="card card-border-color card-border-color-primary">
        <LoginHeader imageUrl={company.minisite_logo_url}/>
        <div className="card-body">
        <Elements stripe={stripePromise}>
        <ElementsConsumer>
        {({stripe, elements}) => (
         <TrialExpiredForm stripe={stripe} elements={elements} sender={this.context.sender}/>
         )}
        </ElementsConsumer>
        </Elements>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      )}
      </CompanyContext.Consumer>
    );
  }
}

TrialExpired.contextType = SenderContext;
export default TrialExpired;
