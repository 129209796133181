import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import AdminHome from '../pages/AdminHome'
import Logout from '../pages/Logout'
import AdminContact from '../pages/AdminContact'
import AdminContacts from '../pages/AdminContacts'
import AdminSender from '../pages/AdminSender'
import AdminSenders from '../pages/AdminSenders'
import AdminManager from '../pages/AdminManager'
import AdminManagers from '../pages/AdminManagers'
import NotFound from '../pages/NotFound'

class AdminRoutes extends Component {

  render() {
    return <Switch>
        <Route path='/' exact component={AdminHome} />
          <Route path='/logout'exact component={Logout} />
          <Route path="/contacts/:id" component={AdminContact} />
          <Route path="/contacts" component={AdminContacts} />
          <Route path="/senders/:id" component={AdminSender} />
          <Route path='/senders' exact component={AdminSenders} />
          <Route path="/managers/:id" component={AdminManager} />
          <Route path='/managers' exact component={AdminManagers} />
          <Route component={NotFound} />
        </Switch>
  }
}

export default AdminRoutes;



