import React, { Component } from 'react';
import { SenderContext } from '../../contexts/sender-context';
import SenderFormEditContainer from '../Admin/SenderFormEditContainer';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ProfilePanel extends Component {
  constructor(){
    super();
    this.state = {
      profileUpdated: false,
      sender: {}
    }
    this.updateSenderState = this.updateSenderState.bind(this);
    this.getSender = this.getSender.bind(this);
    this.editorIsCurrentSender = this.editorIsCurrentSender.bind(this);
  }

  componentDidMount(){
    this.getSender();
  }

  editorIsCurrentSender(){
    if(this.context.sender.role=="collaborator"){
      return false;
    } else{
      return true;
    }
  }


  getSender(){
    let url ='';
    if(this.props.fromManager) {
      url = '/api/v1/members/'+this.props.sender.id;
    } else{
      url = '/api/v1/members/current';
    }
    fetchAuthorized(generateUrl(url, {type: 'connect'})).then(member => {
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(member){
            this.setState({
              sender: member.member
            });
          }else{
            this.setState({sender: {}});
          }
        }

    })
  }

  updateSenderState(){
    this.setState({
      profileUpdated: true
    },()=>this.getSender())
  }

  render() {
    let profileUpdatedMessage = '';
    if(this.state.profileUpdated){
      profileUpdatedMessage = 'Your profile has been updated!'
    }
    return (
      <div>
        <div className="row">
          <div className="col-8 col-lg-8">
          <h2>Profile</h2>
          {profileUpdatedMessage}
          </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-12">
              Let's Update your Profile
              <SenderFormEditContainer saveSenderCallback={this.updateSenderState} sender={this.state.sender}/>
            </div>
          </div>
        </div>
      )

  }
}

ProfilePanel.contextType = SenderContext;
export default ProfilePanel;

