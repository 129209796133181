import React, { Component } from 'react';

class LoginHeader extends Component {
  render() {
    return (
      <div className="card-header">
      <img className="logo-img" src={this.props.imageUrl} alt="logo"/>
      <span className="splash-description">Please enter your user information.</span>
      </div>
      )
  }
}
export default LoginHeader;