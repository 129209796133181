import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { stringToDate } from '../utils/DateFormatter';
import { formattedSingleSelect } from '../utils/Helpers'
import SenderSelect from './SenderSelect'
import { setKey } from '../utils/KeySetter'


class OrderItemFormEdit extends Component {
  constructor(){
    super();
    this.state = {
      sendOn: new Date(),
      checked: false,
      useSignature: true,
      held: false,
      senderId: null,
      senderOptions: {},
      textAreaValue: ''
    }
    this.contactName = '';
    this.contactEmail = '';
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleHoldEcardChange = this.handleHoldEcardChange.bind(this);
    this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
    this.handleUseSignatureChange = this.handleUseSignatureChange.bind(this);
    this.setSenderId = this.setSenderId.bind(this);

  }

  componentDidMount(){
    let senderId = this.props.orderItem.sender.id ? this.props.orderItem.sender.id : null;
    let useSignature = this.props.orderItem.use_signature;
    this.setState({
      sendOn: stringToDate(this.props.orderItem.send_on),
      held: this.props.orderItem.held,
      useSignature: useSignature,
      senderId: senderId,
      textAreaValue: this.props.orderItem.message
    },()=>this.setSenderId())
  }



  setSenderId(val){
    console.log(val);
    let formattedVal = val ? val : formattedSingleSelect(this.props.orderItem, 'sender');
    this.setState({
      senderId: formattedVal.ids,
      senderOptions: formattedVal.options
    },()=>console.log(this.state))
  }

  handleTextAreaChange(event){
    this.setState({
      textAreaValue: event.target.value
    },()=>{
       this.props.updatePreviewMessage(this.state.textAreaValue)
    })

  }


  handleDateChange(date) {
    this.setState({
      sendOn: date,
      checked:true
    });
  }

  handleHoldEcardChange(){
    let currentHeld = this.state.held;
    this.setState({
      held: currentHeld ? false : true
    })
  }

  handleUseSignatureChange(){
    let currentUseSignature = this.state.useSignature;
    this.setState({
      useSignature: currentUseSignature ? false : true
    },()=>{
      this.props.updateSignaturePreview(this.state.useSignature)
    })
  }

  onFormSubmit(event){
    event.preventDefault();
    this.refs.sendOn = this.state.sendOn;
    this.refs.holdEcard = this.state.held;
    this.refs.senderId = this.state.senderId;
    this.refs.useSignature = this.state.useSignature;
    this.props.onSubmitEvent(this.refs);
  }


  render() {
    if(this.props.orderItem.contact){
      this.contactEmail = this.props.orderItem.contact.email;
      console.log(this.props.orderItem.contact);
      this.contactName = this.props.orderItem.contact.full_name;
    }

    let signaturePreview='';
    let useSignature = '';
    if(this.props.signaturePreviewUrl){
      signaturePreview = <div className="col-9 col-lg-9">
                <label className="previewnote">Signature Preview</label>
                <img src={this.props.signaturePreviewUrl} className="signature" alt="signature"/>
              </div>
      useSignature = (
        <div className="col-3 col-lg-3">
        <label className="input-header">Use Signature</label>
                <div className="switch-button switch-button-yesno">
                  <input ref="useSignature" type="checkbox" checked={!!this.state.useSignature} onChange={this.handleUseSignatureChange} name="useSignature" id="useSignature"/><span>
                    <label htmlFor="useSignature"></label></span>
                </div>
              </div>
              )
    }

    let senderSelect = '';
    if(!this.state.senderId){
      senderSelect = <SenderSelect key={setKey('ss-oi-'+this.props.orderItem.id)} defaultOptions={this.state.senderOptions} setSenderCallback={this.setSenderId} contactId={this.props.orderItem.contact.id}/>
    }
    let personalMessage = '';
      personalMessage = (<div className="form-group">
            <label className="input-header">Personal Message</label>
            <textarea ref="personalMessage"  className="form-control" name="description" type="text"  id="personalMessage"  value={this.state.textAreaValue} onChange={this.handleTextAreaChange}/>
          </div>)


    return (
      <form>
          {senderSelect}
          <div className="form-group">
          <label className="input-header">Send To:</label>
          </div>
          <div className="form-group row">
          <div className="col-6 col-lg-6">
            <div className="row">
            <label className="col-3 col-lg-2 text-right">Name:</label>
            <div className="col-9 col-lg-10">
            {this.contactName}
            </div>
            </div>
            <div className="row">
            <label className="col-3 col-lg-2 text-right">Email:</label>
            <div className="col-9 col-lg-10">
            {this.contactEmail}
            </div>
            </div>
          </div>
          </div>
          <div className="form-group">
            <label className="input-header">Subject Line</label>
            <input ref="subject"  className="form-control" name="description" type="text"  id="subjectLine" defaultValue={this.props.orderItem.subject}/>
          </div>
          {personalMessage}
          <div className="form-group row">
            <div className="col-8 col-lg-8">
              <label className="input-header">Send On</label>
              <div className="input-group date datetimepicker" data-min-view="2" data-date-format="yyyy-mm-dd">
              <label>
                <DatePicker className="form-control" selected={this.state.sendOn} onChange={this.handleDateChange} dateFormat="MM/dd/yyyy"/>
                <div className="input-group-append">
                <div className="btn btn-primary"><i className="icon-th mdi mdi-calendar"></i></div>
                </div>
              </label>
             </div>
            </div>





            <div className="col-4 col-lg-4">
              <label className="input-header">Hold this ecard:</label>
              <div className="switch-button switch-button-hold switch-button-yesno">
                <input ref="holdEcard" type="checkbox" checked={!!this.state.held} onChange={this.handleHoldEcardChange} name="holdEcard" id="holdEcard"/>
                <span><label htmlFor="holdEcard"></label></span>
              </div>
            </div>
          </div>
          <div className="form-group row">
              {useSignature}
              {signaturePreview}
            </div>
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </form>


      )
  }
}
export default OrderItemFormEdit;










