import React, { Component } from 'react';
import NavIcon from '../generic/NavIcon';
import NavItem from '../generic/NavItem';
import { Link } from 'react-router-dom'

class ManagerNavItemsContainer extends Component {

  render() {
    //<NavIcon iconName='mdi-settings' iconLabel='Settings' url='/cool'/>
    let senderId = this.props.senderId;
    let urls = [
      {label: 'Upcoming Sends', name: 'home',  url: '/'},
      {label: 'Contacts', name: 'contacts',url:  '/contacts'},
      {label: 'History', name: 'history',url:  '/history'},
      {label: 'Settings', name: 'settings',url:  '/settings'}
     ]
    let navItems = urls.map(url=>{
      let key = 'nav-'+url.name
      let urlForNav = senderId ? (url.name=='home' ? '/senders/'+senderId : '/senders/'+senderId+url.url) : url.url;
      return <NavItem key={key} linkName={url.label} url={urlForNav} active={(url.name) === this.props.activeSection}/>
    })

    return (
      <ul className="nav navbar-nav float-right be-icons-nav">
        {navItems}
        <li className='nav-item'>
          <Link to='/' className="nav-link" role="button" aria-expanded="false"><span className="mdi mdi-eye nav-link-eye"></span></Link>
        </li>
      </ul>
      )
  }
}
export default ManagerNavItemsContainer;






