import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import AsyncSelect from 'react-select/lib/Async';

class ContactSelect extends Component {
  constructor(){
    super();
     this.state = {
      contacts: null,
      inputValue: null
    }

    this.setContactOption = this.setContactOption.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.formatSelectedOption = this.formatSelectedOption.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }




  setContactOption(selectedOption){
    this.props.setContactCallback({
      ids: this.formatSelectedOption(selectedOption),
      options: selectedOption
    });
  }

  formatSelectedOption(selectedOption){
    let val = this.props.isMulti ? selectedOption.map((k)=>k.value) : selectedOption.value;
    return val;
  }

  handleInputChange(newValue) {
    let inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: inputValue });
  };


  promiseOptions() {
    if(!this.state.inputValue || this.state.inputValue.length < 2) return true;
    let params = {
      type: 'default',
      direction: 'asc',
      manual_sort_by: 'contacts.first_name',
      page: 1,
      per_page: 'all',
      query: this.state.inputValue
    }

    if(this.props.senderId) params['sender_id'] = this.props.senderId;
    return fetchAuthorized(generateUrl('/api/v1/contacts', params)).then(contacts => {
      if(contacts.error || contacts.errors){
          console.log('UNAUTHORIZED');
      }else{
          return contacts.contacts.map(contact => ({ value: contact.id, label: contact.full_name }));
      }
    })
  }

  render() {
    return (
      <div className="form-group">
           <AsyncSelect cacheOptions defaultOptions  onInputChange={this.handleInputChange} loadOptions={this.promiseOptions} value={this.props.defaultOptions} onChange={this.setContactOption} isSearchable={true} className="react-select-control"/>
      </div>
      )
  }
}
export default ContactSelect;





