import React, { Component } from 'react';
import Modal from './Modal';
import ContactSelect from './ContactSelect'
import EventFormNewContainer from '../pages/Contact/EventFormNewContainer';
import { setKey } from '../utils/KeySetter'

class AddEventSplash extends Component {
  constructor(){
    super();
    this.state = {
      contactId: null,
      contactOptions: {}
    }
    this.setContactId = this.setContactId.bind(this);
  }

  setContactId(val){
    let formattedVal = val ? val : {ids: null, options: {}}
    this.setState({
      contactId: formattedVal.ids,
      contactOptions: formattedVal.options
    })
  }

  render() {
    let eventFormContainer = "";
    if(this.state.contactId){
      eventFormContainer = <div className="add-event-form-container">
          <h3><span>Add an event for:</span>{this.state.contactOptions.label}</h3>
          <EventFormNewContainer loadFormOnMount={true} saveEventCallback={this.props.saveEventCallback} contactId={this.state.contactId}/>
          </div>

    }
     let classNames = ["col-10","col-lg-10","add-contact-dialog", "from-modal"];

    return (
      <div className={classNames.join(" ")}>

        <div className="card text-center">
          <div className="card-body">
          <h3>Select a contact to continue</h3>

          <div className="row">

            <div className="add-event-contact-select">
             <ContactSelect senderId={this.props.senderId} setContactCallback={this.setContactId} defaultOptions={this.state.contactOptions}/>
            </div>
          </div>
          <div className="row">
          {eventFormContainer}
          </div>
          </div>
        </div>
      </div>
      )
  }
}
export default AddEventSplash;







