import React, { Component } from 'react';
import {CompanyContext} from '../contexts/company-context';

class EcardThumbnail extends Component {
  constructor(){
    super();
    this.categoryName = 'No category';
    this.categoryId = '';
  }
  render() {

    let company = this.context.company;
    let ecard = company.ecards.find(item => item.id === this.props.ecardId);
    if(ecard && ecard.category){
      this.categoryName = ecard.category.name;
      this.categoryId = ecard.category.id;
    }
    //
    if(!ecard){
      return (
        <div className="ecard">
        No Card Found
        </div>
      )
    } else{
      return (

      <div className="ecard">
            <div className="thumbnail">
              <img src={ecard.front_image}  alt="E card front"/>
            </div>
            <div className="details">
              <div className="header">
                <span className="category">{this.categoryName}: </span>
                <span className="name">{ecard.name}</span>
              </div>
              <div className="message">
                <span>Message: </span>
                {this.props.message}
              </div>
            </div>
          </div>
      )

    }
  }
}
EcardThumbnail.contextType = CompanyContext;
export default EcardThumbnail;





