import React, { Component } from 'react';
import { withRouter } from "react-router";
import ls from 'local-storage';
import ErrorList from '../../generic/ErrorList';
import { fetch, generateUrl, authenticate } from '../../utils/Fetcher'

class LoginForm extends Component {
  constructor(){
    super();
    this.state ={
      error: null
    }
    this.onClick = this.onClick.bind(this);
  }

/*
 *fetch('/api/v1/members/create_sender_with_plan', {
      method: 'POST',
      body: formData,
      headers: { subdomain: window.location.hostname.split(".")[0] }

    })
    */

  componentDidMount(){
    document.body.classList.add('be-splash-screen');
  }

  onClick(event){
    let _this = this;
    event.preventDefault();
    authenticate(this.refs.username.value,  this.refs.password.value).then(resp=>{
      if(resp=="redirect"){
        window.location.href = '/';
      } else{
        _this.setState({error: resp});
      }
    })
  }



  render() {
    let errors = <ErrorList errors={this.state.error}/>

    return (

      <form method='post'>
      {errors}
        <div className="form-group">
          <input ref="username" className="form-control" id="username" name="username" type="text" placeholder="Username" autoComplete="off"/>
        </div>
        <div className="form-group">
          <input ref="password" className="form-control" id="password" name="password" type="password" placeholder="Password"/>
        </div>
        <div className="form-group row login-tools">
          <div className="col-6 login-remember">
            <label className="custom-control custom-checkbox">
              <input  className="custom-control-input" type="checkbox"/><span className="custom-control-label">Remember Me</span>
            </label>
          </div>
          <div className="col-6 login-forgot-password"><a href="pages-forgot-password.html">Forgot Password?</a></div>
        </div>
        <div className="form-group login-submit">
          <a onClick={this.onClick} className="white-btn btn btn-primary btn-xl" data-dismiss="modal">Sign me in</a>
        </div>
      </form>


      )
  }
}

export default withRouter(LoginForm);
