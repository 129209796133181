import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { fetchAuthorized } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class ContactFormNewContainer extends Component {
  constructor(){
    super();
    this.state = {
      errors: null,
      completed: null
    }
    this.baseUrl = '/api/v1/contacts/';
    this.contact = null;
    this.createContact = this.createContact.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  createContact(formObj){
    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('contact[email]', formObj['contact']['email']);
    formData.append('contact[title]', formObj['contact']['title']);
    formData.append('contact[office]', formObj['contact']['office']);
    formData.append('contact[first_name]', formObj['contact']['first_name']);
    formData.append('contact[last_name]', formObj['contact']['last_name']);
    if(formObj["newCompanyAccount"]) formData.append('contact[new_company_account]',formObj['newCompanyAccount']);
    if(formObj['companyAccountId']) formData.append('contact[company_account_id]', formObj['companyAccountId']);
    if(formObj['senderIds']) formData.append('contact[member_ids]', formObj['senderIds']);

    formObj['events'].map((event,index)=>{
      formData.append('contact[events_attributes]['+index+'][name]', event['name']);
      formData.append('contact[events_attributes]['+index+'][occurs_on]', event['occursOn']);
      formData.append('contact[events_attributes]['+index+'][recurs]', event['recurs']);
      if (event['eventTypeId']){
        formData.append('contact[events_attributes]['+index+'][event_type_id]', event['eventTypeId']);
      }
    })

    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
      let obj = formObj;
        if(resp.errors){
          this.setState({
            errors: resp.errors,
            completed: null
          })
        }else{
          this.setState({
            errors: null,
            completed: formObj['contact']['email']+' has been added.  Refresh your browser to see the new contact in your list. You can add another contact below.'
          })

        }
        console.log('Successfully completed the post');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <ContactForm key={setKey('contact-new-')} contact={this.contact} errors={this.state.errors} completed={this.state.completed} onSubmitEvent={this.createContact} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default ContactFormNewContainer;










