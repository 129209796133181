import React, { Component } from 'react';

class EventTypeTemplatesPanel extends Component {
  constructor(){
    super();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-8 col-lg-8">
            <h2>Templates for {this.props.sender.full_name}</h2>
          </div>
          <div className="col-4 col-lg-4 text-right">
            <div data-toggle="modal" data-target="#new-event-type-template" className="btn btn-secondary">+ New Template</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="splash-content-tabs tab-container">
              {this.props.eventTypeTemplates}
            </div>
          </div>
        </div>
      </div>
      )
  }
}
export default EventTypeTemplatesPanel;