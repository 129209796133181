import { Component } from 'react';
import ls from 'local-storage';

class Logout extends Component {
  componentDidMount() {
    ls.clear();
    window.location.href = '/';
  }
  render() {
    return null;
  }
}

export default Logout;
