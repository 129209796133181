import React, { Component } from 'react';
import ManagerNav from '../nav/ManagerNav';
import Modal from '../generic/Modal';
import ContactsTable from './Contacts/ContactsTable';
import ContactsSnapshot from './Contacts/ContactsSnapshot';
import AddContactSplash from '../generic/AddContactSplash';
import SectionHeader from '../generic/SectionHeader';
import ContactFormNewContainer from './Contact/ContactFormNewContainer';
import ContactsImportFormContainer from './Contacts/ContactsImportFormContainer';
import { setKey } from '../utils/KeySetter'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

class ManagerContacts extends Component {
  constructor(){
    super();
    this.state = {
      sender: null
    }
    this.getSender = this.getSender.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  componentDidMount() {
      this.getSender();
  }

  getSender(){
    console.log("hi");
    const { id } = this.props.match.params;
    fetchAuthorized(generateUrl('/api/v1/members/'+id, {type: 'connect'})).then(member => {
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(member){
            this.setState({
              sender: member.member
            });
          }else{
            this.setState({sender: {}});
          }
        }

    })
  }

  updateSenderState(){
    this.getSender();
  }

  resetState(){
    if(this.state.contactCreated){
      this.setState({contactCreated: false});
    }
  }

  render() {
    if(!this.state.sender) return null;
    let contacts = '';
    let sectionHeader = '';
    let newModal = '';
    let importModal = '';
    if(this.state.sender.contacts_count==0){
      newModal = '';
      importModal = '';
      contacts = <AddContactSplash senderId={this.state.sender.id} saveContactCallback={this.getSender}/>
    } else{
      sectionHeader = <SectionHeader label='Your Contacts' icon="mdi mdi-accounts"  newModalTarget='#new-contact' importModalTarget='#import-contact'/>
      contacts =  <ContactsTable contactCreated={this.state.contactCreated} onContactsLoaded={this.resetState} senderId={this.state.sender.id}/>
      newModal = <Modal title="New Contact" id="new-contact" iconClassName="mdi mdi-account">
          <ContactFormNewContainer key={setKey()} saveContactCallback={this.getSender} senderId={this.state.sender.id}/>
        </Modal>

      importModal= <Modal title="Import Contact" id="import-contact" iconClassName="mdi mdi-account">
            <ContactsImportFormContainer saveContactCallback={this.getSender} senderId={this.state.sender.id}/>
        </Modal>
    }
    return (
      <div className="be-wrapper be-nosidebar-left site-manager with-sub-nav">
      {newModal}
      {importModal}
      <ManagerNav sender={this.state.sender}/>
        <div className="be-content">
        {sectionHeader}
          <div className="main-content container-fluid main-content-padding manager-sender-contacts">
            <div className="row stats-snapshot">
              <div className="col-12 col-lg-12">
                <div className="row">
                <h2>Snapshot</h2>
                </div>
                <ContactsSnapshot senderId={this.state.sender.id}/>
              </div>
            </div>
            <div className="row">
            <h2>Contact List</h2>
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {contacts}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerContacts;
