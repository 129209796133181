import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class DailyDigestForm extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members/';
     this.state = {
      errors: null
    }
    this.updateMember = this.updateMember.bind(this);
  }


  // sent to the component as onSubmitEvent
  updateMember(event){
    event.preventDefault();

    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('member[daily_digest_emails]', this.refs.dailyDigestEmails.value);

    return fetchAuthorized(this.baseUrl+this.props.sender.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.onSubmitCallback();
        alert('Daily Digest Emails Updated!');
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }


  render() {

    let sender = this.props.sender ? this.props.sender : {};
    let errors = '';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }

    return (
      <form>
        {errors}


          <div className="row col-12">
            <div className="col-6 form-group">
            <input ref="dailyDigestEmails" className="form-control" type="text" placeholder="Enter Emails" defaultValue={sender.daily_digest_emails}/>
            </div>
            <div className="col-3 form-group">
                <button onClick={this.updateMember} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
            </div>
          </div>
        </form>


      )
  }
}
export default DailyDigestForm;










