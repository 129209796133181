import React, { Component } from 'react';

class ContactsImportFinished extends Component {

  render() {
    let result = this.props.result;
    let resultMessage="";
    let errorMessage="";
    let errorTitle="";
    if(result.contacts.length){
      resultMessage=<p>Your list is currently processing and should be imported in a few moments.<br/>Please contact us if you do not see your new contacts in the next 5 minutes.</p>;
    }else if(result.using_bulk_import){
      resultMessage=<p>Your list is currently processing - you will receive an email when the import is complete</p>;
    }
    if(result.errors.length){
      errorTitle = <h1>There were errors with your import:</h1>;
      errorMessage = result.errors.map((error)=>{
        return <p>{error}</p>;
      })
    }
    return (
      <div className="card-body import-finished">
        <div className="row">
        <div className="col-12 col-lg-12  text-center">
         <h1>All Done!</h1>
             <div className="row justify-content-md-center">
              <div className="col-6 col-lg-6">
              {resultMessage}
              {errorTitle}
              {errorMessage}
               <button onClick={this.props.onFinish} className="btn btn-secondary"><i className="icon icon-left mdi mdi-chevron-right"></i> Import a new list</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
  }
}
export default ContactsImportFinished;
