import React, { Component } from 'react';
import AdminNav from '../nav/AdminNav';
import Modal from '../generic/Modal';
import ContactFormEditContainer from './Contact/ContactFormEditContainer'
import Search from '../generic/Search';
import AvatarImage from '../generic/AvatarImage';
import ContactTabs from './Contact/ContactTabs';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { setKey } from '../utils/KeySetter'

class AdminContact extends Component {
  constructor(){
    super();
    this.state = {
      contact: null
    }
    this.getContact = this.getContact.bind(this);
    this.updateContactState = this.updateContactState.bind(this);
  }

  componentDidMount() {
    this.getContact();
  }


  getContact(){
    const { id } = this.props.match.params;
    fetchAuthorized(generateUrl('/api/v1/contacts/'+id, {type: 'connect'})).then(contact => {
      if(contact.error || contact.errors){
        console.log('UNAUTHORIZED');
      }else{
        if(contact){
          this.setState({
            contact: contact.contact
          },()=>{
            Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
          });
        }else{
          this.setState({contact: {}});
        }
      }
    })
  }

  updateContactState(){
    this.getContact();
  }

  render() {

    if(!this.state.contact) return null;
    let memberData = "";
    if(this.state.contact.members){
      memberData = this.state.contact.members.map(member=>{
        let senderLink = "/senders/"+member.id;
        return <div key={setKey('senderLink-'+member.id)}><a href={senderLink}>{member.full_name}</a></div>
      })
    }

    return (
      <div className="be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
        <Modal title="Edit Contact" id="edit-contact" iconClassName="mdi mdi-account">
        <ContactFormEditContainer saveContactCallback={this.updateContactState} contact={this.state.contact}/>
      </Modal>
          <div className="contact-content main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-10 col-lg-10 contact-user-display">
                <AvatarImage email={this.state.contact.email} url={this.state.contact.avatar_url}/>
                <div className="display-info">
                <h1>{this.state.contact.full_name}</h1>
                <p>{this.state.contact.email}<br/>
                {this.state.contact.relationship_strength}
                </p>
                </div>
              </div>
              <div className="col-1 col-lg-1 text-center contact-stats-container">
                  <div className="counter">{this.state.contact.opens_count}</div>
                          <div className="title">Opens</div>

              </div>

              <div className="col-1 col-lg-1 text-center contact-stats-container">
                  <div className="counter">{this.state.contact.upcoming_sends_count}</div>
                  <div className="title">Upcoming Sends</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12 contact-user-details">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                      <table>
                      <tbody>
                        <tr>
                          <td className="label">Company</td>
                          <td>{this.state.contact.company_account_name}</td>
                        </tr>
                        <tr>
                          <td className="label">Title</td>
                          <td>{this.state.contact.title}</td>
                        </tr>
                        <tr>
                          <td className="label">Office</td>
                          <td>{this.state.contact.office}</td>
                        </tr>
                        </tbody>
                      </table>
                      </div>
                      <div className="col-7 points-of-contact">
                        <b>Points of Contact</b><br/>
                        {memberData}
                      </div>
                      <div className="col-1 text-center">

                        <div className="sync icon-container">
                            <div className="icon">
                              <span className="mdi mdi-refresh-sync"></span>
                            </div>
                          </div>
                          <div className="title">
                            Sync to CRM
                        </div>
                      </div>
                     <div className="col-1 text-center edit-icon-btn" data-toggle="modal" data-target="#edit-contact">
                        <div className="sync icon-container">
                            <div className="icon">
                              <span className="mdi mdi-edit"></span>
                            </div>
                          </div>
                          <div className="title">
                            Edit
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
                <ContactTabs contactId={this.state.contact.id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminContact;
