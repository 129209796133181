import React, { Component } from 'react';
import AdminNav from '../nav/AdminNav';
import Search from '../generic/Search';
import SectionHeader from '../generic/SectionHeader';
import Modal from '../generic/Modal';
import ContactsTable from './Contacts/ContactsTable';
import ContactFormNewContainer from './Contact/ContactFormNewContainer';
import ContactsImportFormContainer from './Contacts/ContactsImportFormContainer';
import { setKey } from '../utils/KeySetter'

class AdminContacts extends Component {
  constructor(){
    super();
    this.state ={
      contactCreated: false
    }
    this.resetState = this.resetState.bind(this);
    this.addContactToTable = this.addContactToTable.bind(this);
  }
  addContactToTable(){
    this.setState({contactCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  resetState(){
    if(this.state.contactCreated){
      this.setState({contactCreated: false});
    }
  }

  render() {

    return (
      <div className="senders be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
        <Modal title="New Contact" id="new-contact" iconClassName="mdi mdi-account">
          <ContactFormNewContainer key={setKey()} saveContactCallback={this.addContactToTable}/>
        </Modal>

         <Modal title="Import Contact" id="import-contact" iconClassName="mdi mdi-account">
            <ContactsImportFormContainer saveContactCallback={this.addContactToTable}/>
        </Modal>
          <SectionHeader label='Your Contacts' newModalTarget='#new-contact' importModalTarget='#import-contact'/>
          <div className="senders-content main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                  <ContactsTable contactCreated={this.state.contactCreated} onContactsLoaded={this.resetState}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminContacts;
