import React, { Component } from 'react';

class MemberNoticesPanel extends Component {
  constructor(){
    super();
  }

render() {
  return (
 <div><div className="row  hide-element">
          <div className="col-8 col-lg-8">
          <h2>Notifications for {this.props.sender.full_name}</h2>
          </div>
          <div className="col-4 col-lg-4 text-right">
           <div data-toggle="modal" data-target="#new-member-notice" className="btn btn-secondary">+ New Notification</div>
          </div>
            </div>
            <div className="row">
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {this.props.children}
                </div>
              </div>
            </div>

            <div className="row hide-element">
              <div className="col-8 col-lg-8">
              <h2>Send Notifications to:</h2>
              <label>If no emails are specified, notifications will be sent to the manager of this account.</label>
              </div>
            </div>
            <div className="row">
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {this.props.notificationEmailsForm}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8 col-lg-8">
              <h2>Send Daily Notifications to:</h2>
               <label>If no emails are specified, no daily digest will be sent.</label>
             </div>
            </div>
            <div className="row">
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {this.props.dailyDigestForm}
                </div>
              </div>
            </div>

            <div className="row hide-element">
              <div className="col-8 col-lg-8">
              <h2>Send Weekly Digest to:</h2>
               <label>If no emails are specified, no weekly digest will be sent.</label>
             </div>
            </div>
            <div className="row hide-element">
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {this.props.weeklyDigestForm}
                </div>
              </div>
            </div>
            </div>
             )
  }
}
export default MemberNoticesPanel;