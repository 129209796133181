import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class ContactFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/contacts/';
    this.updateContact = this.updateContact.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateContact(formObj){
    var formData  = new FormData();

    formData.append('type','connect');
    formData.append('contact[email]', formObj['contact']['email']);
    formData.append('contact[title]', formObj['contact']['title']);
    formData.append('contact[office]', formObj['contact']['office']);
    formData.append('contact[first_name]', formObj['contact']['first_name']);
    formData.append('contact[last_name]', formObj['contact']['last_name']);
    if(formObj["newCompanyAccount"]) formData.append('contact[new_company_account]',formObj['newCompanyAccount']);
    if(formObj['companyAccountId']) formData.append('contact[company_account_id]', formObj['companyAccountId']);
    if(formObj['senderIds']) formData.append('contact[member_ids]', formObj['senderIds']);

    return fetchAuthorized(this.baseUrl+this.props.contact.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveContactCallback(formObj);
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }



  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <ContactForm key={this.props.contact.id} contact={this.props.contact} onSubmitEvent={this.updateContact} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default ContactFormEditContainer;










