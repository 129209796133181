import ls from 'local-storage';

export const fetch = (endpoint,params) => {
  let endpointWithHost = process.env.REACT_APP_API_HOST+endpoint;
  if(params){
    return window.fetch(endpointWithHost,params)
    .then(response => response.json())
    .catch(error => console.log(error));
  }else{
    return window.fetch(endpointWithHost)
    .then(response => response.json())
    .catch(error => console.log(error));
  }
}

export const fetchAuthorized = (endpoint,params) => {
  let addlHeaderParams = {};
  let endpointWithHost = process.env.REACT_APP_API_HOST+endpoint;
  let paramsForFetch = params ? params : {};
  if(paramsForFetch['headers']) addlHeaderParams = Object.assign({}, params['headers']);

  if(ls.get('token') && ls.get('user_email')){
    let authObj = {
      'Authorization': 'Token token="'+ls.get('token')+'", user_email="'+ls.get('user_email')+'"',
      subdomain: window.location.hostname.split(".")[0]
    }

    paramsForFetch["headers"] = Object.assign(authObj,addlHeaderParams);

    return window.fetch(endpointWithHost,paramsForFetch)
    .then(response => {
      return response.status!==204 ? response.json() : response.status
    })
    .catch(error => console.log(error));
  } else{
    return new Promise(function(resolve, reject) {
      reject('Please sign in to continue')
    })

  }
}

export const authenticate = (email,password)=>{
  var formData  = new FormData();
  formData.append('user[email]', email);
  formData.append('user[password]', password);

  return fetch('/api/v1/login',{
      method: 'POST',
      body: formData,
      headers: { subdomain: window.location.hostname.split(".")[0] }
    }).then(resp=>{
      if(resp.errors){
       return resp.errors
      }else{
        ls.set('authenticator', 'devise');
        ls.set('token', resp.token);
        ls.set('user_email', resp.user_email);
        return "redirect";
      }
    }).catch(error => {
      return error;
    })
}

export const generateUrl = (baseUrl, params)=>{
    let attrArr = [];
    for (const [key, value] of Object.entries(params)) attrArr.push(key+'='+value);
    return baseUrl+'?'+attrArr.join('&');
}