import React, { Component } from 'react';
import NavHeader from '../generic/NavHeader';
import NavIcon from '../generic/NavIcon';
import NavItem from '../generic/NavItem';
import {CompanyContext} from '../contexts/company-context';

class SenderNav extends Component {
  render() {
    return (
      <CompanyContext.Consumer>
      {({company})=>(
      <nav className="navbar navbar-expand fixed-top be-top-header">
       <div className="container-fluid">
        <div className="be-navbar-header"><NavHeader imageUrl={company.minisite_logo_url}/></div>
        <div className="be-right-navbar">
          <ul className="nav navbar-nav float-right be-icons-nav">
            <NavItem linkName='Home' url='/'/>
            <NavItem linkName='My Contacts' url='/contacts'/>
            <NavItem linkName='History' url='/history'/>
            <NavIcon iconName='mdi-settings' iconLabel='Settings' url='/settings'/>
            <NavIcon iconName='mdi-power' iconLabel='Logout' url='/logout'/>
          </ul>
        </div>
       </div>
      </nav>
      )}
      </CompanyContext.Consumer>
      )
  }
}
export default SenderNav;






