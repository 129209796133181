import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { setKey } from '../utils/KeySetter';

class EventTypeNav extends Component {
  constructor(){
    super();
    this.setEventTypeOption = this.setEventTypeOption.bind(this);
  }


  setEventTypeOption(e){
    let id = e.currentTarget.dataset.eventtypeid;
    let name = e.currentTarget.dataset.eventtypename;
    let index = parseInt(e.currentTarget.dataset.index);
    this.props.setEventTypeCallback({
      ids: id,
      options: {label: name, value: id}
    });
  }


  render() {
    let eventTypeNavItems = "";
    eventTypeNavItems = this.props.breakdown.map((eventType,index) => {
      if(eventType.count > 0){
        let nameForBtn = eventType.name+" "+"("+eventType.count+")";
        let classNames = eventType.id == this.props.eventTypeId ?  "btn btn-primary" : "btn btn-secondary";
        return (<button key={setKey('event-type'+eventType.id)} onClick={this.setEventTypeOption} data-index={index} data-eventtypeid={eventType.id} data-eventtypename={eventType.name} className={classNames}>{nameForBtn}</button>)
      }
    })

    return (
      <div className="form-group event-type-nav">
          {eventTypeNavItems}
      </div>
      )
  }
}
export default EventTypeNav;





