import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavIcon extends Component {
  iconClassNames(){
    let prefixes = ['icon','mdi'];
    prefixes.push(this.props.iconName);
    return prefixes.join(' ');
  }
  render() {
    return (
      <li className="nav-item nav-icon-big">
        <Link to={this.props.url} className="nav-link" role="button" aria-expanded="false">
          <span className={this.iconClassNames()}></span>&nbsp;&nbsp;{this.props.iconLabel}
        </Link>
      </li>
      )
  }
}
export default NavIcon;


