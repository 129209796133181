import React, { Component } from 'react';

class TableEntryLength extends Component {
  render() {
    let entryLengthList = this.props.entryLengths.map((entryLength)=>{
      return <option key={entryLength}  value={entryLength}>{entryLength}</option>
    })

    return (
      <div className="entry-length float-right">
        <label className="entry-length-label">Show
          <select onChange={this.props.onEntryLengthChange} value={this.props.entryLength} defaultValue={this.props.entryLength} name="table1_length" className="entry-length-select custom-select custom-select-sm form-control form-control-sm">
            {entryLengthList}
          </select> entries
        </label>
      </div>
      )
  }
}
export default TableEntryLength;