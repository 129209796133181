import React, { Component } from 'react';
import NavHeader from '../generic/NavHeader';
import NavIcon from '../generic/NavIcon';
import NavItem from '../generic/NavItem';
import { formattedSingleSelect, generateAvatarUrl } from '../utils/Helpers'
import ManagerNavSenderSelect from './ManagerNavSenderSelect';
import ManagerNavItemsContainer from './ManagerNavItemsContainer';
import {CompanyContext} from '../contexts/company-context';

class ManagerNav extends Component {
  constructor(){
    super();
    this.state = {
      senderId: null,
      senderOptions: {},
      activeSection: 'home'
    }
    this.setSenderId = this.setSenderId.bind(this);
    this.redirectUrl = this.redirectUrl.bind(this);
    this.findActiveSection = this.findActiveSection.bind(this);
  }
  componentDidMount(){
    this.setSenderId();
  }
  findActiveSection(){
    let location = window.location.href.split('senders/');
    if (location[1] === undefined) return 'home';
    return (location[1].split('/')[1] !== undefined) ? (location[1].split('/')[1]) : 'home';
  }
  redirectUrl(){
    let location = window.location.href.split('senders/');
    let urlRoot = location[0].slice(0, -1); // remove trailing '/'
    let newUrl = [urlRoot, 'senders', this.state.senderId];

    // add location affix
    if(location[1].split('/')[1] !== undefined)  newUrl.push(location[1].split('/')[1]);

    window.location.href = newUrl.join('/');
  }

  setSenderId(val){
    let avatarUrl = this.props.sender ?  generateAvatarUrl(this.props.sender.avatar_url, this.props.sender.email) : '';
    let formattedVal = val ? val : formattedSingleSelect(this.props, 'sender', avatarUrl);
    this.setState({
      senderId: formattedVal.ids,
      senderOptions: formattedVal.options,
      activeSection: this.findActiveSection()
    },()=>{
      if(val) this.redirectUrl()
    })
  }
  render() {
    let subnav = '';
    if(this.state.senderId) {
      subnav =  <div className="row manager-subnav">
              <div className="col-5 col-lg-5 align-middle text-left">
                <ManagerNavSenderSelect defaultOptions={this.state.senderOptions} setSenderCallback={this.setSenderId}/>
              </div>
              <div className="col-7 col-lg-7 text-right">
                <ManagerNavItemsContainer senderId={this.state.senderId} activeSection={this.state.activeSection}/>
              </div>
            </div>
    }
    //<NavIcon iconName='mdi-settings' iconLabel='Settings' url='/cool'/>
    return (
      <CompanyContext.Consumer>
      {({company})=>(
      <nav className="navbar navbar-expand fixed-top be-top-header">
      <div className="container-fluid">
       <div className="col-12 col-lg-12">
        <div className="row">
          <div className="col-4 col-lg-4">
            <NavHeader imageUrl={company.minisite_logo_url}/>
            </div>
            <div className="col-8 col-lg-8 text-right">
              <ul className="nav navbar-nav float-right be-icons-nav">
                <NavItem linkName='My Account' url='/senders'/>
                <NavIcon iconName='mdi-power' iconLabel='Logout' url='/logout'/>
              </ul>
           </div>
         </div>
         {subnav}
          </div>
         </div>
      </nav>
      )}
      </CompanyContext.Consumer>
      )
  }
}
export default ManagerNav;






