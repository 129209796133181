import React, { Component } from 'react';
import AvatarImage from '../../generic/AvatarImage';
import IdLink from '../../generic/IdLink';
import ChooseEcard from '../../generic/ChooseEcard';
import EcardThumbnail from '../../generic/EcardThumbnail';
import { formattedDate } from '../../utils/DateFormatter';

class AdminHomeEventDatesTableRow extends Component {
  constructor(){
    super();
    this.senderId = '';
    this.senderName = '';
    this.contactName = 'No Name Given';
    this.contactId = '';
    this.contactEmail = '';
    this.contactAvatar = null;
    this.locations = 'No Office Assigned';
    this.groups = 'None Assigned';
    this.managers = null;

  }


  render() {
    let eventDate = this.props.eventDate;


    if(eventDate.user!=null){
      this.senderId = eventDate.user.id
      this.senderName = eventDate.user.full_name
      this.location = eventDate.user.location ? eventDate.user.location.name : ''

      if(eventDate.user.groups){


      this.groups = eventDate.user.groups.map((group)=>
        <a key={group.id}>{group.name}</a>
      )
       }
      this.managers = eventDate.user.managers
    }

    if(eventDate.contact!=null){
      this.contactName = eventDate.contact.full_name
      this.contactId = eventDate.contact.id
      this.contactEmail = eventDate.contact.email
      this.contactAvatar = eventDate.contact.avatar_url
    }

    let ecardLink = "";
    if(eventDate.order){
      ecardLink = <EcardThumbnail ecardId={eventDate.order.order_item.ecard_id} message={eventDate.order.order_item.message}/>
    } else{
      ecardLink = <ChooseEcard loadEventDate={this.props.loadEventDate} eventDate={eventDate}/>
    }

    let editLink="";
    if(eventDate.order){
      editLink =  <button onClick={()=>this.props.loadEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
              <i className="mdi mdi-edit"></i>
            </button>
    }

    let removeLink = <button onClick={()=>this.props.removeEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" type="button">
              <i className="mdi mdi-delete"></i>
            </button>

    let managers = "";
    if(this.managers){
      let managerList = this.managers.map((manager)=>
        <span key={manager.id} className="assigned-to-name">{manager.full_name}</span>
      )
      managers = <div className="assigned-to">Assigned to: {managerList}</div>;


    }

    return (
      <tr key={eventDate.id}>
        <td className="hidden">{eventDate.id}</td>
        <td>
          <div className="sender">
            <IdLink label={this.senderName} id={this.senderId} className='name' baseUrl='/senders/'>
              <span className="office">&nbsp;{this.location}</span>
            </IdLink>
            {managers}
          </div>
        </td>
        <td>
          <div className="practice-group">
            {this.groups}
          </div>
        </td>
        <td>
          <div className="recipient">
          <AvatarImage email={this.contactEmail} url={this.contactAvatar}/>
            <IdLink label={this.contactName} id={this.contactId} className='name' baseUrl='/contacts/'/>
            <div className="stars">
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star-half"/>
            </div>
          </div>
        </td>
        <td>
          {ecardLink}
        </td>
        <td>{formattedDate(eventDate.send_on_date)}</td>
        <td>
          <div className="actions">
          {editLink}
          {removeLink}
          </div>

        </td>
      </tr>
      )
  }
}

export default AdminHomeEventDatesTableRow;