import React, { Component } from 'react';
import ManagerNav from '../nav/ManagerNav';
import OrderHistoryTimeline from '../generic/OrderHistoryTimeline';
import OrderHistoryTimelineEntry from '../generic/OrderHistoryTimelineEntry';
import AddContactSplash from '../generic/AddContactSplash';
import SectionHeader from '../generic/SectionHeader';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

import { setKey } from '../utils/KeySetter'

class ManagerHistory extends Component {
  constructor(){
    super();
    this.state = {
      sender: null,
      orderParams: null,
      orderColumns: [
      { name: ''},
      { name: '' },
      { name: '' },
      { name: '' }
      ],
    }
    this.getSender = this.getSender.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
  }
  componentDidMount() {
      this.getSender();
  }
  getSender(){
    const { id } = this.props.match.params;
    fetchAuthorized(generateUrl('/api/v1/members/'+id, {type: 'connect'})).then(member => {
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(member){
            this.setState({
              sender: member.member,
              orderParams: {  sender_id: member.member.id  }
            });
          }else{
            this.setState({sender: {}});
          }
        }

    })
  }

  updateSenderState(){
    this.getSender();
  }

  render() {
    if(!this.state.sender) return null;
    let orders = '';
    let sectionHeader  = '';
    if(this.state.sender.contacts_count==0){
      orders = <AddContactSplash senderId={this.state.sender.id} saveContactCallback={this.getSender}/>
    } else{
      if(this.state.sender.past_sends_count > 0){
        orders =  <OrderHistoryTimeline key={setKey('edt')} type="SenderOrders" rowComponent={<OrderHistoryTimelineEntry/>} id="history" orderColumns={this.state.orderColumns} orderParams={this.state.orderParams}/>

      } else{
        orders = <h3>You do not have any past sends.</h3>
      }
      sectionHeader = <SectionHeader label='History'  icon="mdi mdi-mail-send" hideNew={true}/>;
    }

    return (
      <div className="be-wrapper be-nosidebar-left site-manager with-sub-nav">
      <ManagerNav sender={this.state.sender}/>
        <div className="be-content">
          {sectionHeader}
          <div className="main-content container-fluid main-content-padding manager-sender-history">
            <div className="row">
              <div className="col-12 col-lg-12">
                {orders}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerHistory;
