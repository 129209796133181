import React, { Component } from 'react';
import IdLink from '../../generic/IdLink';
import AvatarImage from '../../generic/AvatarImage';
import { formattedDate } from '../../utils/DateFormatter';
import ContactsTableRowDetail from './ContactsTableRowDetail';

class ContactsTableRow extends Component {
  constructor(){
    super();
    this.state = {
      loadDetail: false
    }
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  toggleDetail(){
    this.setState({
      loadDetail: this.state.loadDetail ? false : true
    })
  }

  render(){
    let nextSend = "None"
    if(this.props.contact.next_send){
      nextSend = formattedDate(this.props.contact.next_send)
    }
    let companyAccountName = '';
    if(this.props.contact.company_account){
      companyAccountName = this.props.contact.company_account.name
    }
    let baseUrl = window.location.pathname+'/'
    let contactDetail= this.state.loadDetail ? <ContactsTableRowDetail removeContact={this.props.removeContact} loadContact={this.props.loadContact} loadEventForm={this.props.loadEventForm} toggleDetail={this.toggleDetail} contact={this.props.contact}/> : null;
    let rowId = "toggle-row-"+this.props.contact.id;
    return (
      <tbody>
      <tr>
          <td>
            <AvatarImage email={this.props.contact.email} url={this.props.contact.avatar_url}/>
            <div className="contact-name" onClick={this.toggleDetail}>
                {this.props.contact.full_name}
            </div>
          </td>
          <td>
            <div className="name" onClick={this.toggleDetail}>
                {this.props.contact.email}
            </div>
          </td>
          <td>{companyAccountName}</td>
          <td>{this.props.contact.relationship_strength}</td>
          <td>{nextSend}</td>
          <td>
             <button onClick={()=>this.props.loadContact(this.props.contact.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
                  <i className="mdi mdi-edit"></i>
                </button>
              <button onClick={this.toggleDetail} className="icon btn btn-space btn-secondary" id={rowId} type="button">
                  <i className="mdi mdi-eye"></i>
              </button>

               <button onClick={()=>this.props.removeContact(this.props.contact.id)} className="icon btn btn-space btn-secondary" type="button">
                  <i className="mdi mdi-delete"></i>
              </button>
          </td>
        </tr>
        {contactDetail}
      </tbody>
      )
  }
}


export default ContactsTableRow;