import React, { Component } from 'react';
import EventForm from './EventForm';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class EventFormNewContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/events/';
    this.state ={
      eventForm: null,
      eventFormLoaded: false,
      event: {},
      errors: null,
      categories: [],
      contacts: []
    }

    this.createEvent = this.createEvent.bind(this);
    this.updateEventObject = this.updateEventObject.bind(this);
    this.removeEventObject = this.removeEventObject.bind(this);
    this.loadEventForm = this.loadEventForm.bind(this);
  }

  componentDidMount() {
    if(this.props.loadFormOnMount) this.loadEventForm();
  }

  updateEventObject(event){
    console.log(event);
    this.setState({event: event});
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  createEvent(formObj){
    let _this = this;
    var formData  = new FormData();
    formData.append('type','connect');

    formData.append('event[contact_id]', (this.state.event['contactId'] ? this.state.event['contactId'] : this.props.contactId));
    if(this.state.event['eventTypeId']) {
      formData.append('event[event_type_id]', this.state.event['eventTypeId']);
    }
    if(this.state.event['name']!=='') {
      formData.append('event[name]', this.state.event['name']);
    }
    if (this.state.event['categoryId']){
      formData.append('event[category_id]', this.state.event['categoryId']);
    }

    formData.append('event[occurs_on]', this.state.event['occursOn']);
    formData.append('event[recurs]', this.state.event['recurs']);


    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
       if(resp.errors){
        this.setState({
          errors: resp.errors
        },()=>{
          _this.loadEventForm()
        })
       }else{
        console.log('Successfully completed the post');
        return this.props.saveEventCallback(resp.event.event_date);
       }

    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  removeEventObject(){
    this.setState({event: {}, eventForm: null},()=>console.log(this.state.event));
  }

  loadEventForm(){
    let key = setKey();
    this.setState({
      eventForm: <EventForm onRemove={this.removeEventObject} errors={this.state.errors} updateEventObject={this.updateEventObject} key={key} id={key}/>
    })
  }



  render() {
    let newEventBtn = <button onClick={this.loadEventForm} className="btn btn-secondary create-event">+ Add an Event</button>;
    let createEventBtn = '';
    if(this.state.eventForm) {
      createEventBtn = <button onClick={this.createEvent} className='btn btn-primary create-event'>Create Event</button>;
      newEventBtn='';
    }

    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left event-form">
        {newEventBtn}
        {this.state.eventForm}
        {createEventBtn}
      </div>
      </div>
      )
  }
}
export default EventFormNewContainer;










