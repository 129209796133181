import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import Select from 'react-select';


class EventTypeSelect extends Component {
  constructor(){
    super();
     this.state = {
      eventTypes: null
    }

    this.setEventTypeOption = this.setEventTypeOption.bind(this);
    this.getEventTypes = this.getEventTypes.bind(this);
  }

  componentDidMount(){
    if(!this.state.eventTypes){
      this.getEventTypes();
    }
  }

  setEventTypeOption(selectedOption){
    this.props.setEventTypeCallback({
      ids: selectedOption.value,
      options: selectedOption
    });
  }

  getEventTypes() {
    let params = {type: 'connect', direction: 'asc', manual_sort_by: 'event_types.name',page: 1, per_page: 'All'}

    fetchAuthorized(generateUrl('/api/v1/event_types', params)).then(event_types => {
       if(event_types.error || event_types.errors){
          console.log('UNAUTHORIZED');
        }else{
          console.log(event_types);
          let stateObj = {eventTypes: []}
          if(event_types.event_types.length){
            stateObj.eventTypes = event_types.event_types;
          }
          this.setState(stateObj);
      }
      })
  }


  render() {
    if(!this.state.eventTypes) return null;
    const eventTypes = this.state.eventTypes.map(eventType => ({ value: eventType.id, label: eventType.name }))

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted CCCCCC',
        padding: 10,
        backgroundImage: state.data.avatar_url
      }),
      control: (provided) => ({
        ...provided,
        padding: 5
      })
    }

    return (
      <div className="form-group">
           <label className="input-header">Event Type:</label>
            <Select styles={customStyles} value={this.props.defaultOptions} maxMenuHeight={170} onChange={this.setEventTypeOption} isSearchable={false} options={eventTypes} className="react-select-control event-type-selector"/>
      </div>
      )
  }
}
export default EventTypeSelect;





