import React, { Component } from 'react';
import { generateAvatarUrl } from '../utils/Helpers'

class AvatarImage extends Component {

  render() {
    let label = this.props.label ? <div className="avatar-label">{this.props.label}</div> : '';
    return (
      <div className="avatar">
      <img src={generateAvatarUrl(this.props.url, this.props.email)} alt="avatar" />
      {label}
      </div>
      )
  }
}
export default AvatarImage;





