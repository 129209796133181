import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AvatarImage from '../generic/AvatarImage';

class SenderCircle extends Component {
  render() {
    let url = '/senders/'+this.props.sender.id;
    return(
      <div className="col-lg-4">
      <Link className='card sender-circle-container'  to={url}>
        <div sender={this.props.sender.id} >
          <AvatarImage email={this.props.sender.email} url={this.props.sender.avatar_url} label={this.props.sender.full_name}/>
        </div>
      </Link>
      </div>
    )
  }
}
export default SenderCircle;





