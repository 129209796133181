import React, { Component } from 'react';
import ContactList from '../../utils/ContactList';
import FieldMapper from './FieldMapper'

class ContactsImportStep2 extends Component {
  constructor(){
    super();
    this.state = {
      list: null,
      uploadColumns: [],
      errors: null
    }
    this.finishStepEvent = this.finishStepEvent.bind(this);
    this.assignField = this.assignField.bind(this);
  }

  componentDidMount(){
    if(this.props.workbook){
      var _this = this;
      var list = new ContactList({ uploadedbook: this.props.workbook });
      list.setAppColumns().then(function(){
        _this.setState({
          list: list,
          uploadColumns: list.uploadColumns
        })
      })
    }
  }

  finishStepEvent(){
    let list = this.state.list;
    list.uploadColumns = this.state.uploadColumns;
      if(!list.emailFieldMapped()){
        this.setState({errors: 'Email is required.  Please make sure this field is mapped to continue.'})
      }else{
        list.prepareForImport();
        this.props.onFinish(list);
     }
  }

  assignField(appColumnId, uploadColumnId){
    const index = this.state.uploadColumns.findIndex(item => item.id === uploadColumnId);
    let uploadColumns = [...this.state.uploadColumns] // important to create a copy, otherwise you'll modify state outside of setState call
    let uploadColumnSelectedDup = Object.assign({},this.state.uploadColumns[index]);
    ///// update data goes here
    uploadColumnSelectedDup.maptoid = appColumnId
    uploadColumns[index] = uploadColumnSelectedDup;
    this.setState({uploadColumns: uploadColumns},()=>console.log(this.state.uploadColumns));
  }
  render() {
     const rows = this.state.uploadColumns.map(uploadColumn => {
      return <FieldMapper key={uploadColumn.id} appColumns={this.state.list.appColumns} onMap={this.assignField} uploadColumn={uploadColumn}/>
    })
    let errors = '';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }
    return (
      <div className="card-body import-step-two">
        <div className="row">
        <div className="col-5 col-lg-5  text-center">
           <div className="step-description">
           <h1>Step Two</h1>
           <p>To finalize your upload, let's map your file's columns to ur system's required and suggested fields.</p>
           </div>
           <div className="step-example">
           <h2>For Example</h2>
           </div>
        </div>
        <div className="col-7 col-lg-7 text-center">
          {errors}
          <table>
          <thead>
          <tr>
          <th>Columns You Provided</th>
          <th>&nbsp;</th>
          <th>Our Fields</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          <tr>
          <td colSpan="3">
          <button onClick={this.finishStepEvent} className="btn btn-space btn-secondary"><i className="icon icon-left mdi mdi-chevron-right"></i>Preview Import</button>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
        </div>
        </div>
      )
  }
}
export default ContactsImportStep2;