import React, { Component } from 'react';
import EventTypeTemplateForm from './EventTypeTemplateForm';
import { fetchAuthorized } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class EventTypeTemplateFormNewContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/event_type_templates/';
    this.eventTypeTemplate = null;
    this.createEventTypeTemplate = this.createEventTypeTemplate.bind(this);
  }


  // called from EventTypeTemplateForm when the form is submitted
  // sent to the component as onSubmitEvent
  createEventTypeTemplate(formObj){
    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('event_type_template[email_message]', formObj['eventTypeTemplate']['email_message']);
    formData.append('event_type_template[subject]', formObj['eventTypeTemplate']['subject']);
    formData.append('event_type_template[member_id]', formObj['eventTypeTemplate']['member_id']);
    formData.append('event_type_template[event_type_id]', formObj['eventTypeTemplate']['event_type_id']);
    formData.append('event_type_template[recurrence_year]', formObj['eventTypeTemplate']['recurrence_year']);
    if(formObj['eventTypeTemplate']['ecard_id']){
      formData.append('event_type_template[source_id]', formObj['eventTypeTemplate']['ecard_id']);
    }

    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
        this.props.saveEventTypeTemplateCallback(formObj);
        console.log('Successfully completed the post');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <EventTypeTemplateForm key={setKey('event-type-template-new-')} contact={this.eventTypeTemplate} onSubmitEvent={this.createEventTypeTemplate} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default EventTypeTemplateFormNewContainer;










