import React, { Component } from 'react';
import SenderForm from './SenderForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class SenderFormNewContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members/create_with_user/';
    this.sender = {};
    this.createSender = this.createSender.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  createSender(refs){
    var formData  = new FormData();

    let formObjectValues = {
      user_id: refs.userId.value,
      email: refs.email.value,
      last_name: refs.lastName.value,
      first_name: refs.firstName.value,
      password: refs.password,
      group_ids: refs.groupIds,
      manager_ids: refs.managerIds,
      location_ids: refs.locationIds,
      avatar_data: refs.avatarData,
      signature_data: refs.signatureData

    }

    formData.append('type','connect');
    formData.append('member[group_ids]', formObjectValues.group_ids);
    formData.append('member[manager_ids]', formObjectValues.manager_ids);
    formData.append('member[location_ids]', formObjectValues.location_ids);
    formData.append('member[role]', 'user');
    formData.append('member[user_attributes][signature]', formObjectValues.signature_data);
    formData.append('member[user_attributes][avatar]', formObjectValues.avatar_data);
    formData.append('member[user_attributes][id]', formObjectValues.user_id);
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][first_name]', formObjectValues.first_name);
    formData.append('member[user_attributes][password]', formObjectValues.password);

    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
        this.props.saveSenderCallback(formObjectValues);
        console.log('Successfully completed the post');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-12  text-left ecard-form">
          <SenderForm sender={this.sender} onSubmitEvent={this.createSender}/>
        </div>
      </div>
      )
  }
}
export default SenderFormNewContainer;










