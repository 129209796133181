import React, { Component } from 'react';
import IdLink from '../../generic/IdLink';
import AvatarImage from '../../generic/AvatarImage';
import { formattedDate, formattedMonthDay } from '../../utils/DateFormatter';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ContactsEventsList extends Component {
  constructor(){
    super();
    this.state = {
      contactId: null,
      events: []
    }
    this.baseUrl = '/api/v1/events';
    this.getEvents = this.getEvents.bind(this);
    this.removeEvent = this.removeEvent.bind(this);
    this.duplicateEventState = this.duplicateEventState.bind(this);
  }

  componentDidMount(){
    this.setState({
      contactId: this.props.contactId
      },()=>{
        this.getEvents()
      })
  }

  duplicateEventState(eventId){
    const index = this.state.events.findIndex(item => item.id === eventId);
    let events = [...this.state.events] // important to create a copy, otherwise you'll modify state outside of setState call
    let eventDup = Object.assign({},this.state.events[index]);
    return {index: index, event: eventDup, events: events}
  }

  removeEvent(eventId){
    let dup = this.duplicateEventState(eventId);
    if(window.confirm('Are you sure you want to remove this event?')){
      fetchAuthorized(this.baseUrl+'/'+eventId,{ method: 'DELETE' }).then(resp=>{
        let filteredEvents = dup.events.slice(0, dup.index).concat(dup.events.slice(dup.index + 1, dup.events.length))
        this.setState({events: filteredEvents})
      })
    }
  }

  setParams(){
    return { type: 'connect', contact_id: this.state.contactId}
  }

  getEvents() {
    let params = this.setParams();
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(events => {
       console.log(events);
        if(events.error || events.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(events.events.length){
            this.setState({ events: events.events });
          }else{
            this.setState({events: []});
          }
        }

      })
  }

  render(){
    let events = this.state.events.map((event)=>{
      if(event.recurs){
        let eventDivId = "event-div-"+event.id;
        return <li id={eventDivId} key={event.id}>
        <div className="label">{formattedMonthDay(event.occurs_on)}</div>
        <div className="value">{event.name}</div>

        <div className="remove" onClick={()=>this.removeEvent(event.id)}><span className="mdi mdi-delete"></span></div>
        </li>
      }
    });
    return (<ul>
        {events}
     </ul>

      )
  }
}


export default ContactsEventsList;