import React, { Component } from 'react';

class ChooseEcard extends Component {

  render() {

      return (

      <div className="ecard">
            <div className="thumbnail"  onClick={()=>this.props.loadEventDate(this.props.eventDate.id)} data-toggle="modal" data-target="#eventDateTimelineModal" >
                <img src='/choose-ecard.png' alt="E card choose"/>
            </div>
            <div className="details">
              <div className="header">
                <span className="category">{this.props.eventDate.occasion}</span>
              </div>
              <div className="message">
                Click on the pencil icon to the left to select an e card for this occasion.
              </div>
            </div>
          </div>
      )
}
}
export default ChooseEcard;





