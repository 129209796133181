import React, { Component } from 'react';

class TabPane extends Component {
  constructor(){
    super();
    this.defaultClassNames = ['tab-pane']
    this.state = {
      classNames: this.defaultClassNames
    }
  }
  setClassNames(){
    let classNames = [...this.state.classNames];
    if(this.props.isActive){
      if(!classNames.includes('active')){
        classNames.push('active');
        this.setState({classNames: classNames})
      }
    } else{
      if(classNames.includes('active')){
        this.setState({classNames: this.defaultClassNames})
      }
    }
  }

  componentDidMount(){
    this.setClassNames();
  }

  componentDidUpdate(){
    this.setClassNames();
  }



  render() {
    return (
      <div className={this.state.classNames.join(' ')} id={this.props.id} role="tabpanel">
        {this.props.children}
      </div>
      )
  }
}
export default TabPane;








