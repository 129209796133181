import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import AsyncSelect from 'react-select/lib/Async';

class SenderSelect extends Component {
  constructor(){
    super();
     this.state = {
      senders: null,
      inputValue: null
    }

    this.setSenderOption = this.setSenderOption.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(newValue) {
    let inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: inputValue });
  };

  setSenderOption(selectedOption){
    this.props.setSenderCallback({
      ids: selectedOption.map((k)=>k.value),
      options: selectedOption
    });
  }

  promiseOptions() {
    if(!this.state.inputValue || this.state.inputValue.length < 2) return true;
    let params = {
      type: 'connect',
      direction: 'asc',
      manual_sort_by: 'members.id',
      page: 1,
      per_page: 'all',
      query: this.state.inputValue
    }
    if(this.props.contactId) params['contact_id'] = this.props.contactId;
    if(this.props.managersOnly) params["managers"] = "true";

    return fetchAuthorized(generateUrl('/api/v1/members', params)).then(members => {
      if(members.error || members.errors){
          console.log('UNAUTHORIZED');
      }else{
          return members.members.map(member => ({ value: member.id, label: member.full_name }));
      }
    })
  }

  render() {
    return (
      <div className="form-group">
           <label className="input-header">Assign to: (optional):</label>
           <AsyncSelect cacheOptions defaultOptions  onInputChange={this.handleInputChange} loadOptions={this.promiseOptions} isMulti={true}  isSearchable={true} value={this.props.defaultOptions} onChange={this.setSenderOption}  className="react-select-control"/>
      </div>
      )
  }
}
export default SenderSelect;





