import React, { Component } from 'react';
import AvatarImage from '../generic/AvatarImage';
import md5 from 'md5';

export const hasValue = (val)=>{
  if(Array.isArray(val)){
    return val.length > 0;
  } else if(typeof attr ==='object'){
    return val !== null && Object.keys(val).length;
  } else {
    return val !== null
  }
}

export const formattedSelect =(obj, key)=>{
    let list = obj ? (obj[key] ? obj[key] : []) : [];
    let labelAttr='full_name'
    if(list[0] && list[0]['name']) labelAttr = 'name';
    return {
      ids: list.map(member=>member.id),
      options:  list.map(member=>({value: member.id, label: member[labelAttr]}))
    }
}

export const formattedSingleSelect =(obj, key, imageUrl)=>{
    let list = obj ? (obj[key] ? obj[key] : {}) : {};
    let labelAttr='full_name'
    if(list && list['name']) labelAttr = 'name';
    let option = {value: list.id, label: list[labelAttr]};
    if(imageUrl) option.label = <AvatarImage url={imageUrl} label={list[labelAttr]}/>;
    return {
      ids: list.id,
      options:  option
    }
}

export const generateAvatarUrl = (url,email)=>{
  if(url) return url;
  return url ? url : generateGravatarUrl(email);
}

export const generateGravatarUrl = (email)=>{
  let md5Hash = '00000000000000000000000000000000';
  if(email){
    let formattedEmail = email.trim().toLowerCase();
    md5Hash = md5(formattedEmail);
  }
  return "https://www.gravatar.com/avatar/"+md5Hash+"?d=mp&f=y";

}

export const upto = ()=>{

}
// Ruby = 1.upto(5) { |i| puts i }
// JS   = (1).upto(5, function(i){console.log(i);})
Number.prototype.upto = function(t, cb) {
  var i = this;

  if(t < this) return +this;

  while (i <= t) {
    cb(i++);
  }

  return +this;
};