import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import AsyncSelect from 'react-select/lib/Async';

class LocationSelect extends Component {
  constructor(){
    super();
     this.state = {
      locations: null,
      inputValue: null
    }

    this.setLocationOption = this.setLocationOption.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(newValue) {
    let inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: inputValue });
  };

  setLocationOption(selectedOption){
    this.props.setLocationCallback({
      ids: selectedOption.map((k)=>k.value),
      options: selectedOption
    });
  }

  promiseOptions() {
    if(!this.state.inputValue || this.state.inputValue.length < 2) return true;
    let params = {
      type: 'connect',
      direction: 'asc',
      manual_sort_by: 'locations.id',
      page: 1,
      per_page: 'all',
      query: this.state.inputValue
    }

    return fetchAuthorized(generateUrl('/api/v1/locations', params)).then(locations => {
      if(locations.error || locations.errors){
          console.log('UNAUTHORIZED');
      }else{
          return locations.locations.map(location => ({ value: location.id, label: location.name }));
      }
    })
  }

  render() {
    return (
      <div className="form-location">
           <label className="input-header">Assign to a Location: (optional):</label>
           <AsyncSelect cacheOptions defaultOptions  onInputChange={this.handleInputChange} loadOptions={this.promiseOptions} isMulti={true}  isSearchable={true} value={this.props.defaultOptions} onChange={this.setLocationOption}  className="react-select-control"/>
      </div>
      )
  }
}
export default LocationSelect;





