import React, { Component } from 'react';
import { fetchRedTailAuthorized } from '../../utils/RedTail'
import { fetchAuthorized } from '../../utils/Fetcher'
import moment from 'moment'
/*
//uploadColumns looks like  [{id: "A", maptoid: undefined, name: "Name"}]
//numberOfRecords is integer
/*
email: "cool@gim.com"
events_attributes: []
first_name: "C.L. Nathanson"
last_name: "GCC Portfolio"
relationship_strength: "P.O. Box 261812"
row_number: 2
title: "Plano, TX"
*/

class RedTailImportStep1 extends Component {
  constructor(){
    super();
    this.state = {
      response: null,
      contacts: null,
      senderId: null
    }
    this.fetchRedTail = this.fetchRedTail.bind(this);
    this.finishStepEvent = this.finishStepEvent.bind(this);
    this.formatResponseIntoList =this.formatResponseIntoList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount(){
    this.setState({
      senderId: this.props.senderId
    })
  }
  fetchRedTail(){
   fetchAuthorized('/api/v1/contacts/import_from_redtail').then((resp)=>{
      this.formatResponseIntoList(resp);
    });
  }
  formatResponseIntoList(resp){
    let list = [];
    resp.Detail.map((contact,i)=>{
      if(contact.FirstName){
        let email = (contact.FirstName+contact.LastName+"@testcompany.com").toLowerCase();
      let list_item = {
        email: email,
        events_attributes: [{event_type_id: 25, occurs_on: moment.utc(contact.DateofBirth).toDate()}],
        first_name: contact.FirstName,
        last_name: contact.LastName,
        relationship_strength: 5,
        selected: true
      }
      list.push(list_item)
      }

    })
    this.setState({
      contacts: list
    })
  }
  finishStepEvent(){
    var formData  = new FormData();
    let contactsToImport = this.state.contacts.filter((contact)=>contact.selected==true);
    contactsToImport.map((c)=> {delete c.selected});
    formData.append('type','connect');
    formData.append('contacts', JSON.stringify(contactsToImport));
    formData.append('sender_id', this.state.senderId);

    fetchAuthorized('/api/v1/contacts/create_multiple',{
      method: 'POST',
      body: formData
    }).then(contacts => {
      this.props.onFinish(contacts)
    })
  }

  handleInputChange(i){
    let newList = [...this.state.contacts];
    newList[i].selected  = !newList[i].selected
    this.setState({
      contacts: newList
    })
  }


  render() {
    let mainDiv = "";
    let mainDivTitle = "";
    let redTailIntro = <div className='redtail-splash-container'>
      <div className='redtail-logo'><img src='/RTlogo_red_747x167.png'/></div>
      <button onClick={this.fetchRedTail} className='btn btn-space btn-primary btn-xl'>Click to Get Started</button>
    </div>;
    let rows='';
    let syncButton = '';
    if(this.state.contacts){
      rows = this.state.contacts.map((contact,i)=>{

        return <tr><td>{contact.first_name}</td><td>{contact.last_name}</td><td>{contact.email}</td><td>{moment(contact.events_attributes[0].occurs_on).format('MM.DD.YYYY')}</td>
            <td><input name="import-contact" type="checkbox" checked={contact.selected} onChange={()=>this.handleInputChange(i)} /></td>
        </tr>
      });
      //
      mainDivTitle = <h2 className='redtail-header'>We found the following contacts to import</h2>;
      mainDiv = <div className='redtail-contacts-container'>
      <table className='redtail-contacts'>
      <tr><th>First Name</th><th>Last Name</th><th>Email</th><th>Birthday</th><th></th></tr>
      {rows}
      </table>
      </div>;

      redTailIntro = '';
      syncButton = <button className='btn btn-space btn-primary btn-xl' onClick={this.finishStepEvent}>Sync Contacts</button>;
    }
    return (
      <div className="card-body import-step-one">
        <div className="col-12 col-lg-12  text-center">
          {redTailIntro}
          {mainDivTitle}
          {mainDiv}
          {syncButton}
        </div>
        </div>
      )
  }
}
export default RedTailImportStep1;



/*
 * Clicck
 *
 * */






