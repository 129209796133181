import React, { Component } from 'react';
import TabNavItem from '../../generic/TabNavItem';
import MemberNoticesPanel from './MemberNoticesPanel';
import EventTypeTemplatesPanel from './EventTypeTemplatesPanel';
import BillingPanel from './BillingPanel';
import ProfilePanel from './ProfilePanel';
import { setKey } from '../../utils/KeySetter'

class SettingsTabs extends Component {
  constructor(){
    super();
    this.state = {
      activePanelIndex: 0,
      tabPanels: [
      {
        key:'memberNotices',
        name: 'Notifications'
      },
      {
        key:'templates',
        name: 'Templates'
      },
      {
        key: 'profile',
        name: 'Profile'
      }
      ]
    }
    this.setActivePanel = this.setActivePanel.bind(this);
  }

  componentDidMount(){
    this.setActivePanel(0);
  }

  setActivePanel(index){
    this.setState({activePanelIndex: index});
  }

  render() {
    let navItems = this.state.tabPanels.map((panel,i) => {
      let isActive = this.state.activePanelIndex == i ? true : false;
      return <TabNavItem key={panel.key} isActive={isActive} index={i} onClick={this.setActivePanel} name={panel.name}/>
    });

    let panelContent = ''
    if(this.state.activePanelIndex == 0){
      panelContent = <MemberNoticesPanel sender={this.props.sender} notificationEmailsForm={this.props.notificationEmailsForm} dailyDigestForm={this.props.dailyDigestForm} weeklyDigestForm={this.props.weeklyDigestForm}>
          {this.props.children}
          </MemberNoticesPanel>;
    } else if(this.state.activePanelIndex === 1){
      panelContent = <EventTypeTemplatesPanel sender={this.props.sender} eventTypeTemplates={this.props.eventTypeTemplates}/>;
    } else{
      panelContent = <ProfilePanel fromManager={this.props.fromManager} sender={this.props.sender}/>;
    }

    /* else if(this.state.activePanelIndex === 2){
      panelContent = <BillingPanel fromManager={this.props.fromManager} sender={this.props.sender}/>;
    }*/

    return (

      <div className="splash-content-tabs tab-container">
        <ul className="nav nav-tabs nav-tabs-classic" role="tablist">
           {navItems}
        </ul>
        <div className="tab-content settings-tab-pane-content">
          {panelContent}
        </div>
      </div>
      )
  }
}
export default SettingsTabs;





