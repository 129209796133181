import React, { Component } from 'react';
import EventTypeTemplateForm from './EventTypeTemplateForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class EventTypeTemplateFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/event_type_templates/';
    this.updateEventTypeTemplate = this.updateEventTypeTemplate.bind(this);
  }


  // called from EventTypeTemplateForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateEventTypeTemplate(formObj){
    var formData  = new FormData();

    formData.append('type','connect');
    formData.append('event_type_template[email_message]', formObj['eventTypeTemplate']['email_message']);
    formData.append('event_type_template[subject]', formObj['eventTypeTemplate']['subject']);
    formData.append('event_type_template[event_type_id]', formObj['eventTypeTemplate']['event_type_id']);
    formData.append('event_type_template[recurrence_year]', formObj['eventTypeTemplate']['recurrence_year']);
    if(formObj['eventTypeTemplate']['ecard_id']){
      formData.append('event_type_template[source_id]', formObj['eventTypeTemplate']['ecard_id']);
    }

    return fetchAuthorized(this.baseUrl+this.props.eventTypeTemplate.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveEventTypeTemplateCallback(formObj);
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }



  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <EventTypeTemplateForm key={this.props.eventTypeTemplate.id} eventTypeTemplate={this.props.eventTypeTemplate} onSubmitEvent={this.updateEventTypeTemplate} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default EventTypeTemplateFormEditContainer;










