import React, { Component } from 'react';
import EcardThumbnail from '../../generic/EcardThumbnail';
import ChooseEcard from '../../generic/ChooseEcard';
import { formattedDate } from '../../utils/DateFormatter';

class ContactEventDatesTableRow extends Component {
  constructor(){
    super();

    this.senderName = 'No Name Given';
    this.contactName = 'No Name Given';
    this.locations = 'No Office Assigned';
    this.groups = 'None Assigned';
    this.managers = 'None Assigned';

  }

  render() {
    let eventDate = this.props.eventDate;
    /*
    if(eventDate.contact!=null){
      this.contactName = eventDate.contact.email
    }

    */
    let ecardLink = "";
    if(eventDate.order){
      ecardLink = <EcardThumbnail ecardId={eventDate.order.order_item.ecard_id} message={eventDate.order.order_item.message}/>
    } else{
      ecardLink = <ChooseEcard loadEventDate={this.props.loadEventDate} eventDate={eventDate}/>
    }

    let editLink="";
    if(eventDate.order){
      editLink =  <button onClick={()=>this.props.loadEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
              <i className="mdi mdi-edit"></i>
            </button>
    }

    let removeLink = <button onClick={()=>this.props.removeEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" type="button">
              <i className="mdi mdi-delete"></i>
            </button>
    return (
      <tr key={eventDate.id}>
        <td>{formattedDate(eventDate.send_on_date)}</td>
        <td>{eventDate.occasion}</td>
        <td>
        {ecardLink}
        </td>
        <td>
          <div className="actions">
          {editLink}
          {removeLink}
          </div>
        </td>
      </tr>
      )
  }
}

export default ContactEventDatesTableRow;