import React, { Component } from 'react';
import { setKey } from '../../utils/KeySetter'
import SenderForm from './SenderForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class SenderFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members/';
    this.updateSender = this.updateSender.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateSender(refs){
    var formData  = new FormData();

    let formObjectValues = {
      email: refs.email.value,
      last_name: refs.lastName.value,
      first_name: refs.firstName.value,
      logo_url: refs.logoUrl.value,
      user_id: refs.userId.value,
      role: refs.role,
      group_ids: refs.groupIds,
      manager_ids: refs.managerIds,
      avatar_data: refs.avatarData,
      signature_data: refs.signatureData,
      location_ids: refs.locationIds == undefined ? '' : refs.locationIds,
    }


    formData.append('type','connect');

    formData.append('member[group_ids]', formObjectValues.group_ids);
    formData.append('member[manager_ids]', formObjectValues.manager_ids);
    formData.append('member[location_ids]', formObjectValues.location_ids);
    formData.append('member[role]', formObjectValues.role);
    if(refs.updateAvatarData){
      formData.append('member[user_attributes][avatar]', formObjectValues.avatar_data);
    }
    if(refs.updateSignatureData){
      formData.append('member[user_attributes][signature]', formObjectValues.signature_data);
    }
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][first_name]', formObjectValues.first_name);
    formData.append('member[user_attributes][logo_url]', formObjectValues.logo_url);
    formData.append('member[user_attributes][id]', formObjectValues.user_id);

    return fetchAuthorized(this.baseUrl+this.props.sender.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveSenderCallback(formObjectValues);
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }



  render() {
    console.log(this.props.sender);
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <SenderForm key={setKey('sender-form-'+this.props.sender.id)} sender={this.props.sender} onSubmitEvent={this.updateSender}/>
      </div>
      </div>
      )
  }
}
export default SenderFormEditContainer;










