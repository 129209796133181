import React, { Component } from 'react'
import AdminRoutes from './routes/AdminRoutes';
import ManagerRoutes from './routes/ManagerRoutes';
import SenderRoutes from './routes/SenderRoutes';
import DefaultRoutes from './routes/DefaultRoutes';
import {CompanyContext, company} from './contexts/company-context';
import {SenderContext, sender} from './contexts/sender-context';
import {SendersContext, senders} from './contexts/senders-context';
import { fetch, fetchAuthorized, generateUrl } from './utils/Fetcher'
import CssLoader from './generic/CssLoader';

import ls from 'local-storage';
//import Footer from './layouts/Footer'

class App extends Component {
  constructor(){
    super();
    this.state={
      subdomain: undefined,
      company: company,
      sender: sender,
      senders: senders
    }
    this.getSender = this.getSender.bind(this);
    this.getSenders = this.getSenders.bind(this);
    this.getCompany = this.getCompany.bind(this);
    this.getSubdomainFromUrl = this.getSubdomainFromUrl.bind(this);
  }
  //
  getSubdomainFromUrl(){
    return window.location.hostname.split(".")[0];
  }
  //
  getCompany(){
    return fetch('/api/v1/companies/'+this.state.subdomain+'?type=connect').then(company => {
      if(company.error || company.errors){
        console.log('UNAUTHORIZED');
      }else{
        //company.company.minisite_logo_url = "/bt-logo.png"
        return company.company;
      }
    })
  }

  getSender(){
    return new Promise(function(resolve, reject) {
      if(ls.get('user_email')){
        return fetchAuthorized('/api/v1/members/current').then(member => {
          if(!member || member.error || member.errors){
            reject('Please sign in to continue')
          } else{
            resolve(member.member);
          }
        })
      } else{
        reject('Please sign in to continue')
      }
    })
  }

  getSenders(){
    let params = { type: 'connect', direction: 'asc', manual_sort_by: 'users.last_name', page: 1, per_page: 'All'}
    if(this.state.sender.role === 'collaborator'){
      return fetchAuthorized(generateUrl('/api/v1/members', params)).then(members => {
          if(!members) return false;
          if(members.error || members.errors){
            return false;
          } else{
            if(members.members.length){
              this.setState({ senders: members.members });
            }
          }
      })
    } else{
      return false;
    }
  }

  componentDidMount(){
    var subdomain = this.getSubdomainFromUrl();
    //
    this.setState({subdomain: subdomain},()=>{
      this.getCompany().then(company=>{
        this.getSender().then(sender=>{
          this.setState({
            sender: sender,
            company: company
          },()=>this.getSenders());
        }).catch((resp)=>{
          this.setState({
            sender: {role: 'default'},
            company: company,
          })
        })
      });
    })
  }


  render () {
    if(!this.state.company) return null;
    if(!Object.keys(this.state.sender).length) return null;

    let routeList =  <DefaultRoutes/>;
    let siteSpecificCss = <CssLoader css={this.state.company.minisite_css}/>;


    if(this.state.sender.role === 'user'){
      require('./styles/sender.scss');
      document.body.className += ' ' + 'gcc-connect sender logged-in';
      routeList = <SenderRoutes/>
    } else if(this.state.sender.role === 'collaborator'){
      require('./styles/manager.scss');
      document.body.className += ' ' + 'gcc-connect manager logged-in';
      routeList = <ManagerRoutes senders={this.state.senders}/>;
    } else if(this.state.sender.role === 'admin'){
      document.body.className += ' ' + 'gcc-connect admin logged-in';
      require('./styles/index.scss');
      routeList = <AdminRoutes/>
    } else{
      require('./styles/manager.scss');
      document.body.className += ' ' + 'gcc-connect logged-out';
    }

    return <CompanyContext.Provider value={this.state}>
        <SenderContext.Provider value={this.state}>
          <SendersContext.Provider value={this.state}>
            {routeList}
            {siteSpecificCss}
          </SendersContext.Provider>
        </SenderContext.Provider>
      </CompanyContext.Provider>
  }
}

export default App