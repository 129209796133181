import React, { Component } from 'react';
import { formattedDate } from '../../utils/DateFormatter';

class EventTypeTemplatesTableRow extends Component {
  constructor(){
    super();
  }

  render(){
    let eventTypeName = this.props.eventTypeTemplate.event_type ? this.props.eventTypeTemplate.event_type.name : '';
    let sourceId = this.props.eventTypeTemplate.source_id;
    let sourceThumbnail = this.props.eventTypeTemplate.source_thumbnail ? <img src={this.props.eventTypeTemplate.source_thumbnail} alt={sourceId}/> : 'No card selected';
    return (
      <tbody>
      <tr>
          <td>
            <div className="event-type">
                <strong>{eventTypeName}</strong>
            </div>
          </td>
          <td>
            <div className="name">
                <strong>{this.props.eventTypeTemplate.subject}</strong>
            </div>
          </td>

          <td>
            <div className="recurrence">
                <strong>{this.props.eventTypeTemplate.recurrence_year}</strong>
            </div>
          </td>

          <td>
            <div className="source-thumbnail">
                {sourceThumbnail}
            </div>
          </td>

          <td>
             <button onClick={()=>this.props.loadEventTypeTemplate(this.props.eventTypeTemplate.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-event-type-template" type="button">
                  <i className="mdi mdi-edit"></i>
                </button>
               <button onClick={()=>this.props.removeEventTypeTemplate(this.props.eventTypeTemplate.id)} className="icon btn btn-space btn-secondary" type="button">
                  <i className="mdi mdi-delete"></i>
              </button>
          </td>
        </tr>
      </tbody>
      )
  }
}


export default EventTypeTemplatesTableRow;