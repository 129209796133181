import React, { Component } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import {Promise} from "bluebird";
import PasswordField from '../generic/PasswordField';
import ErrorList from '../generic/ErrorList';
import { fetch, generateUrl,authenticate } from '../utils/Fetcher'
import ls from 'local-storage';

class SignupFormWithCard extends Component {
  constructor(){
    super();
    this.state = {
      error: null,
      password: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stripePaymentMethodHandler = this.stripePaymentMethodHandler.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handlePasswordChange(val){
    this.setState({password: val})
  }

  componentDidMount(){
    document.body.classList.add('be-splash-screen');
  }


  // Handle real-time validation errors from the card Element.
  handleChange(event) {
    if (event.error) {
      this.setState({error: event.error.message});
    } else {
      this.setState({error: null});
    }
  }


  // Handle form submission.
  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    let elements = this.props.elements;
    let card = elements.getElement(CardElement);
    let stripe = this.props.stripe;
    let paymentMethodDetails = {
      type: 'card',
      card: card,
      billing_details: {
        email: this.refs.email.value
      }
    }
    console.log(paymentMethodDetails)
    Promise.resolve(stripe.createPaymentMethod(paymentMethodDetails)).then((response)=>{
      console.log(response);
      if (response.error) {
        console.log("ERROR");
        // Inform the user if there was an error.
        _this.setState({error: response.error.message});
      } else {
        console.log("NO ERROR");
        _this.setState({error: null});
        // Send the token to your server.
        _this.stripePaymentMethodHandler(response,_this.refs);
      }
    });
  };



/*
 *fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    })
 *formData.append('member[user_attributes][id]', formObjectValues.user_id);
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][password]', formObjectValues.password);
 */


  stripePaymentMethodHandler(response,refs) {
    let _this = this;
    let formData = new FormData();
    formData.append('member[user_attributes][email]', refs.email.value);
    formData.append('member[user_attributes][last_name]', refs.lastName.value);
    formData.append('member[user_attributes][password]', this.state.password);
    formData.append('member[payment_method]',response.paymentMethod.id);
    fetch('/api/v1/members/create_sender_with_plan', {
      method: 'POST',
      body: formData,
      headers: { subdomain: window.location.hostname.split(".")[0] }

    }).then(result => {
      if(result.errors){
        this.setState({error: result.errors})
      }else{
        authenticate(refs.email.value,  this.state.password).then(resp=>{
          if(resp=="redirect"){
            window.location.href = '/';
          } else{
            _this.setState({error: resp});
          }
        })
      }

   })
  }


  render() {
    // Custom styling can be passed to options when creating an Element.
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };



  let errors = <ErrorList errors={this.state.error}/>
  let passwordField = <PasswordField onChange={this.handlePasswordChange}/>;

  return (

      <form>
      {errors}
 <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={this.props.email}/>
          </div>

           <div className="form-group">
            <label className="input-header">First Name:</label>
            <input ref="firstName" className="form-control" id="firstName" type="text" placeholder="Enter First Name" defaultValue={this.props.first_name}/>
          </div>

           <div className="form-group">
            <label className="input-header">Last Name:</label>
            <input ref="lastName" className="form-control" id="lastName" type="text" placeholder="Enter Last Name" defaultValue={this.props.last_name}/>
          </div>
          {passwordField}
          <div className="form-group">
        <label className="input-header">
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={this.handleChange}
        />
        </div>
        <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.handleSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Sign Up</button>
                </p>
              </div>
            </div>
          </div>
    </form>

      )
  }
}

export default SignupFormWithCard;
