import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import NotFound from '../pages/NotFound'

class DefaultRoutes extends Component {

  render() {

    return <Switch>
          <Route path='/' exact component={Login} />
           <Route path='/signup' exact component={Signup}/>
           <Route component={NotFound} />
          </Switch>
  }
}

export default DefaultRoutes;



