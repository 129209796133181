import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class NotFound extends Component {
  render () {
    return <div className="be-wrapper be-nosidebar-left">
        <div className="be-content">
          <div className="main-content container-fluid">
            <h1>404: Not found</h1>
            <Link to='/'>Back to home</Link>
          </div>
      </div>
    </div>
  }
}

export default NotFound