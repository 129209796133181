import React, { Component } from 'react';
import OrderItemFormEdit from './OrderItemFormEdit';
import { fetchAuthorized } from '../utils/Fetcher'
import { formatDatePickerForApi } from '../utils/DateFormatter'
import ReactPlayer from 'react-player'

class OrderItemFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/order_items/';
    this.state = {
      orderItem: {},
      previewMessage: '',
      previewSignature: false
    }
    this.updateOrderItem = this.updateOrderItem.bind(this);
    this.updatePreviewMessage = this.updatePreviewMessage.bind(this);
    this.updateSignaturePreview = this.updateSignaturePreview.bind(this);
  }

  // turns out this is unnecessary since we are getting all the order data we need for the form from the parent
   componentDidMount() {
     this.updatePreviewMessage(this.props.orderItem.message);
     this.updateSignaturePreview(this.props.orderItem.use_signature);
   }

  updatePreviewMessage(message){
    this.setState({
      previewMessage: message
    })
  }

  updateSignaturePreview(signaturePreview){
    this.setState({
      previewSignature: signaturePreview
    })
  }


  // called from OrderForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateOrderItem(refs){
    console.log(refs.useSignature.value);
    var formData  = new FormData();

    let formObjectValues = {
      message: refs.personalMessage === undefined ? '' : refs.personalMessage.value,
      send_on: formatDatePickerForApi(refs.sendOn),
      held: refs.holdEcard,
      subject: refs.subject.value,
      sender_id: refs.senderId,
      use_signature: refs.useSignature
    }

    console.log(refs.sendOn);
    formData.append('type','connect');
    formData.append('message', formObjectValues.message);
    formData.append('use_signature', formObjectValues.use_signature);
    formData.append('subject', formObjectValues.subject);
    formData.append('order_item[member_id]', formObjectValues.sender_id);
    formData.append('order_item[held]', formObjectValues.held);
    formData.append('order_item[send_on]', formObjectValues.send_on);
    return fetchAuthorized(this.baseUrl+this.props.orderItem.secure_code,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
       this.props.saveOrderItemCallback();
       console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }


  render() {
    let form = '';
    if(this.props.orderItem){
      form = <OrderItemFormEdit orderItem={this.props.orderItem} signaturePreviewUrl={this.props.sender.signature_url} updatePreviewMessage={this.updatePreviewMessage} updateSignaturePreview={this.updateSignaturePreview} onSubmitEvent={this.updateOrderItem}/>
    }
    let url='';
    if(this.props.orderItem){
      url = "https://greetingcc.wistia.com/medias/"+this.props.orderItem.wistia_id;
    }

    let signature='';
    if(this.props.sender.signature_url){
      if(this.state.previewSignature){
        signature=<p className="email-signature-preview"><img src={this.props.sender.signature_url}/></p>
      }
    }

    let recipientEmailUrl='';
    let divStyle = {};
    let logoStyle = {};
    if(this.props.orderItem){
      if(this.props.orderItem.recipient_email_border){
        divStyle = {
          borderColor: this.props.orderItem.recipient_email_border
        };
      }
      if(this.props.orderItem.recipient_email_logo_display_height){
        // get the percent width of the logo
        let width = 600; //width of email that will go out - this is constant
        let displayWidth = this.props.orderItem.recipient_email_logo_display_width;
        let pctWidth = parseInt(parseFloat(displayWidth / width) * 100).toString()+"%";
        logoStyle = {
          width: pctWidth,
          height: 'auto'
        };
      }
      recipientEmailUrl = <div className="col-12 col-lg-12">
          <h1>Recipient Email Preview</h1>
          <div className="recipient-email-preview">
            <div className="email-preview-top-logo">
              <img src={this.props.orderItem.recipient_email_logo_url} style={logoStyle} alt="company-logo"/>
            </div>
            <div className="email-preview-content">
              <div className="outer-border" style={divStyle}>
                <div className="inner-border" style={divStyle}>
                  <div className="from-desk-of">From the desk of {this.props.sender.full_name}</div>
                  <img src={this.props.orderItem.recipient_email_top_image} className="top-greeting-imagery" alt="recipient-preview"/>
                  <div className="email-preview-mid">
                    {this.state.previewMessage}{signature}
                  </div>
              <div className="email-preview-bot">
              <img className="email-overlay" src={this.props.orderItem.recipient_email_url} alt="email-overlay"/>
              <p className="note-text">Can't see the link above? Copy/paste this URL into your web browser:<br/>
              <span class="link-colored-text"><b>http://viewer.gccecards.com/v/kD7IbTl6UGlMcInQX1a2Lw</b></span>
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    }

    return (
      <div className="row">
      <div className="col-7 col-lg-7  text-left ecard-form">
        {form}
      </div>
      <div className="col-5 col-lg-5  text-center ecard-form-previews">
        <div className="row">
        <div className="col-12 col-lg-12 video-preview">
          <h1>Video Preview</h1>
          <ReactPlayer url={url} width="300px" height="173px"/>
        </div>
      </div>
      <div className="row">
        {recipientEmailUrl}
      </div>
      </div>
      </div>
      )
  }
}
export default OrderItemFormEditContainer;










