import React, { Component } from 'react';
import TableColumnHeader from '../../generic/TableColumnHeader'
import TablePaginationContainer from '../../generic/TablePaginationContainer'
import Modal from '../../generic/Modal';
import MemberNoticesTableRow from './MemberNoticesTableRow'
import MemberNoticeFormEditContainer from './MemberNoticeFormEditContainer'
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'


class MemberNoticesTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/member_notices';
    this.state = {
      memberNoticeId: null,
      memberNotices: [],
      memberNoticeColumns: [
      { name: 'Notification To Be Sent', sortApiColumn: 'member_notices.time_to_order_delivery', sortable: true },
      { name: '' }
      ],
      currentSortColumn: 'member_notices.time_to_order_delivery',
      currentSortDirection: 'desc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getMemberNotices = this.getMemberNotices.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadMemberNotice = this.loadMemberNotice.bind(this);
    this.updateMemberNoticeState = this.updateMemberNoticeState.bind(this);
    this.duplicateMemberNoticeState = this.duplicateMemberNoticeState.bind(this);
    this.removeMemberNoticeState = this.removeMemberNoticeState.bind(this);
    this.removeMemberNotice = this.removeMemberNotice.bind(this);
  }

  componentDidMount() {
    this.getMemberNotices();
  }

  componentDidUpdate() {
    console.log("MEMBER NOTICES TABLE - COMPONENT UPDATED");

    console.log("this.props.memberNoticeCreated");
    console.log(this.props.memberNoticeCreated);
    if(this.props.memberNoticeCreated){
      this.getMemberNotices();
    }
  }


  loadMemberNotice(memberNoticeId){
    this.setState({
      memberNoticeId: memberNoticeId
    })
  }

  removeMemberNotice(memberNoticeId){
    if(window.confirm('Are you sure you want to remove this memberNotice?')){
      fetchAuthorized(this.baseUrl+'/'+memberNoticeId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          memberNoticeId: memberNoticeId
          },()=>this.removeMemberNoticeState())
      })
    }
  }

  duplicateMemberNoticeState(){
    const index = this.state.memberNotices.findIndex(item => item.id === this.state.memberNoticeId);
    let memberNotices = [...this.state.memberNotices] // important to create a copy, otherwise you'll modify state outside of setState call
    let memberNoticeDup = Object.assign({},this.state.memberNotices[index]);
    return {index: index, memberNotice: memberNoticeDup, memberNotices: memberNotices}
  }

  currentMemberNotice(){
    let dup = this.duplicateMemberNoticeState();
    return dup.memberNotices[dup.index];
  }

  // called from UpdateMemberNoticeForm Container component after the api is updated
  // sent to the comonent as saveMemberNoticeCallback
  updateMemberNoticeState(){
    let dup = this.duplicateMemberNoticeState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.memberNoticeId, { type: 'connect'})).then(memberNotice => {
      dup.memberNotices[dup.index] = memberNotice.member_notice;
      this.setState({memberNoticeId: null, memberNotices: dup.memberNotices});
      Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    });
  }

  removeMemberNoticeState(){
    let dup = this.duplicateMemberNoticeState();
    let filteredMemberNotices = dup.memberNotices.slice(0, dup.index).concat(dup.memberNotices.slice(dup.index + 1, dup.memberNotices.length))
    this.setState({memberNoticesId: null, memberNotices: filteredMemberNotices},()=>console.log(this.state.memberNotices));
  }

 setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getMemberNotices();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getMemberNotices();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getMemberNotices();
    })
  }


  /*
  http://localhost:3000/api/v1/memberNotices?
        by_status=
        &direction=desc
        &end_date=2019-01-16
        &items_type=Electronic
        &not_archived=true
        &page=1
        &per_page=10
        &production_only=true
        &q=
        &sort=placedAt
        &start_date=2019-01-01
        &type=
  */

  setParams(){
    return {
      type: 'connect',
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }

  getMemberNotices() {
    let params = this.setParams();
    if(this.props.senderId) params['sender_id'] = this.props.senderId;
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(memberNotices => {
       console.log(memberNotices);
        if(memberNotices.error || memberNotices.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(memberNotices.member_notices.length){
            let paginationData = Object.assign({}, this.state.paginationData);
            paginationData.totalPages =  parseInt(memberNotices.meta.total_pages);
            paginationData.totalItems =  parseInt(memberNotices.meta.total_items);
            paginationData.currentPage = parseInt(memberNotices.meta.page);
            this.setState({ memberNotices: memberNotices.member_notices, paginationData: paginationData });
            this.props.onMemberNoticesLoaded();
          }else{
            this.setState({memberNotices: []});
          }
        }
      })
  }

  render() {

    let modal = <Modal/>;
    if(this.state.memberNoticeId && this.currentMemberNotice()!=undefined){
      let currentMemberNotice = this.currentMemberNotice();
      modal = <Modal title="Edit Notification" iconClassName="mdi mdi-account">
        <MemberNoticeFormEditContainer saveMemberNoticeCallback={this.updateMemberNoticeState} memberNotice={currentMemberNotice} senderId={this.props.senderId}/>
      </Modal>;
    }
    //

    let memberNoticeList = this.state.memberNotices.map((memberNotice)=>{
      return <MemberNoticesTableRow key={setKey('memberNotice-'+memberNotice.id)} loadMemberNotice={this.loadMemberNotice} removeMemberNotice={this.removeMemberNotice} key={memberNotice.id} memberNotice={memberNotice}/>
    })

    let memberNoticeColumns = this.state.memberNoticeColumns.map((col)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={col.name} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    let paginationSection = <TablePaginationContainer setPage={this.setPage} paginationData = {this.state.paginationData} setEntryLength={this.setEntryLength}/>

    return (
      <div className="card card-table">
      {modal}
        <div className="card-body">
          <div id="memberNoticeTable">
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive">
                  <thead>
                    <tr>
                    {memberNoticeColumns}
                    </tr>
                  </thead>
                    {memberNoticeList}
                </table>
              </div>
            </div>

            {paginationSection}
          </div>
        </div>
      </div>
    )
  }
}

export default MemberNoticesTable;
