import React, { Component } from 'react';
import AdminNav from '../nav/AdminNav';
import Search from '../generic/Search';
import AvatarImage from '../generic/AvatarImage';
import SectionHeader from '../generic/SectionHeader';
import Modal from '../generic/Modal';
import SenderFormEditContainer from './Admin/SenderFormEditContainer';
import UserFormEditPassword from '../generic/UserFormEditPassword';
import SenderTabs from './Admin/SenderTabs';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

class AdminSender extends Component {
  constructor(){
    super();
    this.state = {
      sender: null
    }
    this.getSender = this.getSender.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
  }
  componentDidMount() {
      this.getSender();
  }
   getSender(){
    const { id } = this.props.match.params;
    fetchAuthorized(generateUrl('/api/v1/members/'+id, {type: 'connect'})).then(member => {
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(member){
            this.setState({
              sender: member.member
            },()=>{
              Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
            });
          }else{
            this.setState({sender: {}});
          }
        }

    })
  }

  updateSenderState(){
    this.getSender();
  }

  render() {
    if(!this.state.sender) return null;
    //
    let groups = "";
    if(this.state.sender.groups){
      groups = this.state.sender.groups.map((group)=>
      <a key={group.id}>{group.name}</a>
      )
   }

   let managers = "";
    if(this.state.sender.managers){
      managers = this.state.sender.managers.map((manager)=>{
      let managerLink = '/managers/'+manager.id;
      return <a key={manager.id} href={managerLink}>{manager.full_name}</a>
      })
   }

    return (
      <div className="be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
          <Modal title="Edit Sender" id="edit-sender" iconClassName="mdi mdi-account">
            <SenderFormEditContainer saveSenderCallback={this.updateSenderState} sender={this.state.sender}/>
          </Modal>
          <Modal title="Edit Sender Password" id="edit-sender-password" iconClassName="mdi mdi-account">
            <UserFormEditPassword saveUserCallback={this.updateSenderState} userId={this.state.sender.user_id}/>
          </Modal>
          <SectionHeader label=''/>
          <div className="individual-sender-content main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="user-display">
                    <div className="user-display-bottom">
                      <div className="user-display-avatar">
                      <AvatarImage email={this.state.sender.email} url={this.state.sender.avatar_url}/>
                      </div>
                      <div className="user-display-info">
                        <div className="name">{this.state.sender.full_name}: {this.state.sender.locations}</div>
                        <div className="nick"><span className="mdi mdi-email"></span>&nbsp;{this.state.sender.email}</div>
                      </div>
                      <div className="row user-display-details">
                        <div className="col-4">
                          &nbsp;
                        </div>
                        <div className="col-4 text-center">
                        <div className="counter">{this.state.sender.upcoming_sends_count}</div>
                          <div className="title">Upcoming Sends</div>

                        </div>
                        <div className="col-4 text-center">
                         <div className="counter">{this.state.sender.contacts_count}</div>
                          <div className="title">Contacts</div>

                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="card">
                  <div className="card-body">
                  <div className="row assignments-sync-container">
                    <div className="col-3 text-center assignment">
                         <div className="counter"><span className="mdi mdi-accounts"></span></div>
                          <div className="title">
                          <b>Practice Groups</b>
                          {groups}
                          </div>

                        </div>
                        <div className="col-3 text-center assignment">
                        <div className="counter"><span className="mdi mdi-account"></span></div>
                          <div className="title">
                          <b>Assigned To</b>
                          {managers}
                          </div>

                        </div>
                        <div className="col-2 text-center sync">
                          <div className="counter icon-container">
                            <div className="icon">
                              <span className="mdi mdi-refresh-sync"></span>
                            </div>
                          </div>
                          <div className="title">
                            CRM Sync
                          </div>
                        </div>

                        <div className="col-2 text-center edit-icon-btn"  data-toggle="modal" data-target="#edit-sender">
                          <div className="counter icon-container">
                            <div className="icon">
                              <span className="mdi mdi-edit"></span>
                            </div>
                          </div>
                          <div className="title">
                            Edit
                          </div>
                        </div>

                        <div className="col-2 text-center edit-icon-btn"  data-toggle="modal" data-target="#edit-sender-password">
                          <div className="counter icon-container">
                            <div className="icon">
                              <span className="mdi mdi-edit"></span>
                            </div>
                          </div>
                          <div className="title">
                            Edit Password
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
                <SenderTabs senderId={this.state.sender.id}/>
              </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminSender;
