import React, { Component } from 'react';
import ManagerForm from './ManagerForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class ManagerFormNewContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members/create_with_user/';
    this.manager = {};
    this.createManager = this.createManager.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  createManager(refs){
    var formData  = new FormData();

    let formObjectValues = {
      user_id: refs.userId.value,
      email: refs.email.value,
      last_name: refs.lastName.value,
      first_name: refs.firstName.value,
      password: refs.password,
      sender_ids: refs.senderIds,
      avatar_data: refs.avatarData
    }

    formData.append('type','connect');
    formData.append('member[sender_ids]', formObjectValues.sender_ids);
    formData.append('member[role]', 'collaborator');
    formData.append('member[user_attributes][avatar]', formObjectValues.avatar_data);
    formData.append('member[user_attributes][id]', formObjectValues.user_id);
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][first_name]', formObjectValues.first_name);
    formData.append('member[user_attributes][password]', formObjectValues.password);

    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
        this.props.saveManagerCallback(formObjectValues);
        console.log('Successfully completed the post');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-12  text-left ecard-form">
          <ManagerForm manager={this.manager} onSubmitEvent={this.createManager}/>
        </div>
      </div>
      )
  }
}
export default ManagerFormNewContainer;










