import React, { Component } from 'react';
import LoginForm from './Login/LoginForm';
import LoginHeader from './Login/LoginHeader';
import {CompanyContext} from '../contexts/company-context';

class Login extends Component {
  constructor(){
    super();
    this.onSignUpClick = this.onSignUpClick.bind(this);
  }

  onSignUpClick(event){
    event.preventDefault();
    window.location.href = '/signup';
  }

  render() {
    return (
      <div className="be-wrapper be-login">
      <div className="be-content">
        <div className="main-content container-fluid">
          <div className="splash-container">
      <div className="card card-border-color card-border-color-primary">
        <LoginHeader imageUrl={this.context.company.minisite_logo_url}/>
        <div className="card-body">
          <LoginForm/>
          <div className="form-group login-submit signup-now-link">
            <a onClick={this.onSignUpClick} className="white-btn btn btn-primary btn-xl" data-dismiss="modal">Not a user? Sign Up Now</a>
        </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    );
  }
}
Login.contextType = CompanyContext;
export default Login;
