import React, { Component } from 'react';
import EventTypeSelect from "../../generic/EventTypeSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EventForm extends Component {
  constructor(){
    super();
    this.state = {
      id: null,
      name: '',
      occursOn: new Date(),
      recurs: true,
      checked: false,
      categoryId: '',
      eventTypeId: null,
      eventTypeOptions: null,
      newEventType: null,
    }
    this.setEventTypeId = this.setEventTypeId.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }
  componentDidMount(){
    this.setState({
      id: this.props.id
    })
    this.setEventTypeId();
  }

  setEventTypeId(val){
    console.log(val);
    let formattedVal = val ? val : {ids: '', options: ''}
    this.setState({
        eventTypeId: formattedVal.ids,
        eventTypeOptions: formattedVal.options,
        newEventType: formattedVal.options.__isNew__ ? formattedVal.ids : null
    },()=>this.props.updateEventObject(this.state))
  }

  handleDateChange(date) {
    this.setState({
      occursOn: date,
      checked:true
    }, ()=>this.props.updateEventObject(this.state));
  }

  handleFormChange(event){
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState, ()=>this.props.updateEventObject(this.state))
  }

  onRemove(){
    this.props.onRemove(this.state.id, this.props.arrayIndex);
  }

  render() {
      let eventTypeSelector = <EventTypeSelect defaultOptions={this.state.eventTypeOptions} setEventTypeCallback={this.setEventTypeId}/>
      let eventName = '';
      if(this.state.eventTypeId && this.state.eventTypeOptions.label!=='Birthday' && this.state.eventTypeOptions.label!=='Work Anniversary'){
        eventName =  <div className="col-lg-12 col-12">
            <label className="input-header">Name (optional):</label>
            <input type="text" className="event-name-input form-control" name='name' placeholder='Enter an Event Name' onChange={this.handleFormChange} value={this.state.name}/>
           </div>
      }
      let errors =[];
      if(this.props.errors){
      for (var key in this.props.errors) {
        errors.push(<div className="alert alert-danger">{this.props.errors[key]}</div>)
      }
    }

    return (
      <div className="event-form">
          {errors}
          <div className="row">
            <div className="col-lg-6 col-6">
            {eventTypeSelector}
           </div>
            <div className="col-lg-6 col-6">
              <label className="input-header">Occurs On:</label>
              <div className="input-group date datetimepicker" data-min-view="2" data-date-format="yyyy-mm-dd">
                <label>

                  <DatePicker className="form-control" selected={this.state.occursOn} onChange={this.handleDateChange} dateFormat="MM/dd/yyyy"/>
                  <div className="input-group-append">
                  <div className="btn btn-primary"><i className="icon-th mdi mdi-calendar"></i></div>
                  </div>
                </label>
               </div>
             </div>
             {eventName}
            </div>

      </div>

      )
  }
}
export default EventForm;


/*
 *
 * event name needs to be a select dropdown
 * event needs optional title
 * event name selection should automatically select category for e card
 * recurs should only show for birthday/work anniversary
 * recurs should NOT show for congratulations/ thank you
 * on create should then go directly to choose e card screen
 *
 */











