import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class NavItem extends Component {
  render() {
    let className=this.props.active ? 'nav-item active' : 'nav-item';
    return (
      <li className={className}>
      <Link to={this.props.url} className="nav-link" role="button" aria-expanded="false">{this.props.linkName}</Link>
      </li>
      )
  }
}
export default NavItem;


