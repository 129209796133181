import React, { Component } from 'react';
import XLSX from 'xlsx';
import Dropzone from 'react-dropzone';

class ContactsImportStep1 extends Component {
  constructor(){
    super();
    this.state = {
      files: []
    }
    this.onDrop = this.onDrop.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.dropzoneRef = '';
  }

  onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);
    console.log(rejectedFiles)
    const reader = new FileReader();
    reader.onload = () => {
       var data = reader.result;
        //const fileAsBinaryString = reader.result;
        // do whatever you want with the file content
        // data = new Uint8Array(data);
        var workbook = XLSX.read(data, {type: 'binary'});
        this.props.onFinish(workbook);
    };
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsBinaryString(acceptedFiles[0]);


    this.setState({files: acceptedFiles});
  }

  onCancel() {
    this.setState({
      files: []
    });
  }

  triggerOpenDialog() {
    this.dropzoneRef.open()
  }

  render() {
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ))
    return (
      <div className="card-body import-step-one">
        <div className="col-12 col-lg-12  text-center">
          <Dropzone ref={(node) => { this.dropzoneRef = node; }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onDrop={this.onDrop} onFileDialogCancel={this.onCancel}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div><h1>Step One</h1>
            <p>Drag your CSV or Excel file here to begin importing or <b>click to upload</b>.</p>
            <div className="icon-container">
              <div className="icon"><span className="mdi mdi-cloud-upload"></span></div>
              <span className="icon-class"></span>
            </div>
            </div>
            </div>
          )}
        </Dropzone>
        <aside>
          <ul>{files}</ul>
        </aside>



        </div>
        </div>
      )
  }
}
export default ContactsImportStep1;










