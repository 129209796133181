import React, { Component } from 'react';
import IdLink from '../../generic/IdLink';
import AvatarImage from '../../generic/AvatarImage';
import { formattedDate } from '../../utils/DateFormatter';

class ManagersTableRow extends Component {
  constructor(){
    super();
    this.groups = '';
    this.locations = '';
    this.next_send = '';
  }
  render(){

     if(this.props.manager.groups){
       this.groups = this.props.manager.groups.map((group)=>
        <a href="#" key={group.id}>{group.name}</a>
        )
     }
     if(this.props.manager.locations){
       this.locations = this.props.manager.locations.map((location)=>
        <a href="#" key={location}>{location}</a>
        )
     }
     if(this.props.manager.next_send){
        this.next_send = formattedDate(this.props.manager.next_send);
     }

      let removeLink = <button onClick={()=>this.props.removeManager(this.props.manager.id)} className="icon btn btn-space btn-secondary" type="button">
              <i className="mdi mdi-delete"></i>
            </button>

    return (
      <tr>
      <td>
         <AvatarImage email={this.props.manager.email} url={this.props.manager.avatar_url}/>
        <IdLink label={this.props.manager.full_name} id={this.props.manager.id} className='manager-name' baseUrl='/managers/'/>
      </td>
      <td>{this.groups}</td>
      <td>{this.locations}</td>
      <td>{this.next_send}</td>
      <td>Stats</td>
      <td>
         <button onClick={()=>this.props.loadManager(this.props.manager.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
              <i className="mdi mdi-edit"></i>
            </button>

            <button onClick={()=>this.props.enrichManager(this.props.manager.id)} className="icon btn btn-space btn-secondary" type="button">
              Enrich
          </button>
          {removeLink}
      </td>
      </tr>
      )
  }
}


export default ManagersTableRow;