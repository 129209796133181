import React, { Component } from 'react';
import { emailIsValid } from '../../utils/EmailValidator'

class FixableField extends Component {
  constructor(){
    super();
    this.state = {
      fixing: false,
      errors: null
    }
    this.initiateFix = this.initiateFix.bind(this);
    this.createFix = this.createFix.bind(this);
    this.cancelFix = this.cancelFix.bind(this);
  }

  initiateFix(){
    this.setState({
      fixing: true,
      errors: null
    })
  }

  createFix(){
    let value = this.refs[this.props.field].value;
    if(this.props.field === 'email'){
      if(emailIsValid(value)){
        this.props.onFieldFix(value,this.props.index);
      } else{
        this.setState({
          errors: 'Please enter a valid email'
        })
      }
    }

  }

  cancelFix(){
    this.setState({
      fixing: false
    })
  }



  render() {

    let fixableViewClassNames= ['fixableView'];
    if(!this.state.fixing) fixableViewClassNames.push('active');
    fixableViewClassNames = fixableViewClassNames.join(' ');

   let fixableEditClassNames= ['fixableEdit'];
   if(this.state.fixing) fixableEditClassNames.push('active')
    fixableEditClassNames = fixableEditClassNames.join(' ');

   let errors = '';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }

    return (
      <div className='fixableFieldContainer'>
        <div className={fixableViewClassNames}>
          {this.props.value}
          <button onClick={this.initiateFix} className="fix-btn btn btn-secondary"><i className="icon icon-left mdi mdi-chevron-right"></i> Fix</button>
        </div>
        <div className={fixableEditClassNames}>
          {errors}
          <input type="text" ref={this.props.field} defaultValue={this.props.value}/>
          <button onClick={this.createFix} className="btn btn-secondary">Update Record</button>
          <button onClick={this.cancelFix} className="btn btn-secondary">Cancel</button>
        </div>
      </div>


      )
  }
}
export default FixableField;










