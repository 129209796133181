import React, { Component } from 'react';
import { formattedDate } from '../../utils/DateFormatter';

class MemberNoticesTableRow extends Component {
  constructor(){
    super();
  }

  render(){
    return (
      <tbody>
      <tr>

          <td>
            <div className="name">
                <strong>{this.props.memberNotice.prettify_time_to_order_delivery}</strong> before event.
            </div>
          </td>
          <td>
             <button onClick={()=>this.props.loadMemberNotice(this.props.memberNotice.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
                  <i className="mdi mdi-edit"></i>
                </button>
               <button onClick={()=>this.props.removeMemberNotice(this.props.memberNotice.id)} className="icon btn btn-space btn-secondary" type="button">
                  <i className="mdi mdi-delete"></i>
              </button>
          </td>
        </tr>
      </tbody>
      )
  }
}


export default MemberNoticesTableRow;