import React, { Component } from 'react';
import { fetchAuthorized } from '../utils/Fetcher'

class HoldEcardButton extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/order_items/';
    this.updateOrderItem = this.updateOrderItem.bind(this);
  }

  // called from OrderForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateOrderItem(){
    let newValue = this.props.orderItem.held ? false : true;
    let formData  = new FormData();
    formData.append('order_item[held]', newValue);
    return fetchAuthorized(this.baseUrl+this.props.orderItem.secure_code,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
      this.props.toggleCallback(this.props.eventDateId, 'order.order_item.held');
       console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    let inputId = "holdEcardStandalone-"+this.props.eventDateId;
    return (
      <div className="switch-button switch-button-hold" >
        <input ref="holdEcard" type="checkbox" onChange={this.updateOrderItem} checked={!!!this.props.orderItem.held} name="holdEcard" id={inputId}/>
        <span><label htmlFor={inputId}></label></span>
      </div>
    );
  }
}

export default HoldEcardButton;



