import React, { Component } from 'react';
import ManagerNav from '../nav/ManagerNav';
import SenderCircle from '../generic/SenderCircle';
import { CompanyContext } from '../contexts/company-context';
import { SenderContext } from '../contexts/sender-context';
import { SendersContext } from '../contexts/senders-context';
import { setKey } from '../utils/KeySetter'

class ManagerHome extends Component {

  render() {
    let manager = this.context.sender;
    const senders = this.context.senders.map(sender => {
      return <SenderCircle key={setKey('sender-circle-'+sender.id)}  sender={sender}/>
    });
    if(!manager) return null;

    let name = manager.first_name ? manager.first_name : manager.full_name;
    return (
      <div className="be-wrapper be-nosidebar-left site-manager">
      <ManagerNav/>
      <div className="be-content">
          <div className="main-content container-fluid main-content-padding home-sender-list">

            <div className="row">
              <div className="col-12 col-lg-12 text-center">
                    <div className="welcome text-center">
                <h1>Hi, {name}</h1>
              </div>
                    <div className="welcome-message">
                    <h4>Let's get started.</h4>
                    <h5>Click below on the {this.context.company.sender_label.toLowerCase()} you'd like to manage.</h5>
                    </div>
                    <div className="row">
                      {senders}
                    </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
        <div className="copyright">&copy; 2021 GCC Connect.  All Rights Reserved</div>
      </div>
      </div>

    );
  }
}

ManagerHome.contextType = CompanyContext;
ManagerHome.contextType = SenderContext;
ManagerHome.contextType = SendersContext;
export default ManagerHome;
