import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import AsyncSelect from 'react-select/lib/Async';

class GroupSelect extends Component {
  constructor(){
    super();
     this.state = {
      groups: null,
      inputValue: null
    }

    this.setGroupOption = this.setGroupOption.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(newValue) {
    let inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: inputValue });
  };

  setGroupOption(selectedOption){
    this.props.setGroupCallback({
      ids: selectedOption.map((k)=>k.value),
      options: selectedOption
    });
  }

  promiseOptions() {
    if(!this.state.inputValue || this.state.inputValue.length < 2) return true;
    let params = {
      type: 'default',
      direction: 'asc',
      manual_sort_by: 'groups.id',
      page: 1,
      per_page: 'all',
      query: this.state.inputValue
    }

    return fetchAuthorized(generateUrl('/api/v1/groups', params)).then(groups => {
      if(groups.error || groups.errors){
          console.log('UNAUTHORIZED');
      }else{
          return groups.groups.map(group => ({ value: group.id, label: group.name }));
      }
    })
  }

  render() {
    return (
      <div className="form-group">
           <label className="input-header">Assign to a Group: (optional):</label>
            <AsyncSelect cacheOptions defaultOptions  onInputChange={this.handleInputChange} loadOptions={this.promiseOptions} isMulti={true}  isSearchable={true} value={this.props.defaultOptions} onChange={this.setGroupOption}  className="react-select-control"/>
      </div>
      )
  }
}
export default GroupSelect;





