import React, { Component } from 'react';
import TableColumnHeader from '../../generic/TableColumnHeader'
import TablePaginationContainer from '../../generic/TablePaginationContainer'
import Modal from '../../generic/Modal';
import ManagersTableRow from './ManagersTableRow'
import ManagerFormEditContainer from './ManagerFormEditContainer'
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ManagersTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members';
    this.state = {
      managerId: null,
      managers: [],
      managerColumns: [
      { name: 'Name', sortApiColumn: 'users.last_name', sortable: true },
      { name: 'Practice Group', sortApiColumn: 'groups.name', sortable: true },
      { name: 'Office',sortApiColumn: 'locations.display_name', sortable: true},
      { name: 'Upcoming Send', sortApiColumn: 'order_items.send_on', sortable: true },
      { name: 'Stats', sortApiColumn: 'manager', sortable: true },
      { name: '' }
      ],
      currentSortColumn: 'users.last_name',
      currentSortDirection: 'asc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getManagers = this.getManagers.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadManager = this.loadManager.bind(this);
    this.duplicateManagerState = this.duplicateManagerState.bind(this);
    this.updateManagerState = this.updateManagerState.bind(this);
    this.enrichManager = this.enrichManager.bind(this);
    this.removeManager = this.removeManager.bind(this);
    this.removeManagerState = this.removeManagerState.bind(this);
  }

  loadManager(managerId){
    this.setState({
      managerId: managerId
    })
  }

  enrichManager(managerId){
    fetchAuthorized(generateUrl(this.baseUrl+"/"+managerId+"/enrich",{})).then(manager => {
      console.log(manager);
    })
  }

    removeManager(managerId){
    if(window.confirm('Are you sure you want to remove this manager?')){
      fetchAuthorized(this.baseUrl+'/'+managerId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          managerId: managerId
          },()=>this.removeManagerState())
      })
    }
  }

  removeManagerState(){
    let dup = this.duplicateManagerState();
    let filteredManagers = dup.managers.slice(0, dup.index).concat(dup.managers.slice(dup.index + 1, dup.managers.length))
    this.setState({managerId: null, managers: filteredManagers},()=>console.log(this.state.managers));
  }


  duplicateManagerState(){
    const index = this.state.managers.findIndex(item => item.id === this.state.managerId);
    let managers = [...this.state.managers] // important to create a copy, otherwise you'll modify state outside of setState call
    let managerDup = Object.assign({},this.state.managers[index]);
    return {index: index, manager: managerDup, managers: managers}
  }


  currentManager(){
    let dup = this.duplicateManagerState();
    return dup.managers[dup.index];
  }

  // called from UpdateContactForm Container component after the api is updated
  // sent to the comonent as saveContactCallback
  updateManagerState(){
    let dup = this.duplicateManagerState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.managerId, { type: 'connect'})).then(member => {
      dup.managers[dup.index] = member.member;
      this.setState({managerId: null, managers: dup.managers},()=>console.log(this.state.managers));
      Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    });
  }

  componentDidMount() {
    this.getManagers();
  }

  componentDidUpdate() {
    if(this.props.managerCreated){
      this.getManagers();
    }
  }

  setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getManagers();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getManagers();
    })
  }




  /*
  http://localhost:3000/api/v1/managers?
        by_status=
        &direction=desc
        &end_date=2019-01-16
        &items_type=Electronic
        &not_archived=true
        &page=1
        &per_page=10
        &production_only=true
        &q=
        &sort=placedAt
        &start_date=2019-01-01
        &type=
  */

  setParams(){
    return {
      type: 'connect',
      managers: true,
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }


  getManagers() {
    let params = this.setParams();
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(members => {
       console.log(members);
        if(members.error || members.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(members.members.length){
          let paginationData = Object.assign({}, this.state.paginationData);
          paginationData.totalPages =  parseInt(members.meta.total_pages);
          paginationData.totalItems =  parseInt(members.meta.total_items);
          paginationData.currentPage = parseInt(members.meta.page);
          this.setState({ managers: members.members, paginationData: paginationData });
          this.props.onManagersLoaded();
        }else{
          this.setState({managers: []});
        }
         }

      })
  }

  render() {

    let modal = <Modal/>;
    if(this.state.managerId){
      let currentManager = this.currentManager();
      modal = <Modal title="Edit Manager" iconClassName="mdi mdi-account">
        <ManagerFormEditContainer saveManagerCallback={this.updateManagerState} manager={currentManager}/>
      </Modal>;
    }

    let managerList = this.state.managers.map((manager)=>{
      return(<ManagersTableRow loadManager={this.loadManager} removeManager={this.removeManager} enrichManager={this.enrichManager} key={manager.id} manager={manager}/>)
    })
    let managerColumns = this.state.managerColumns.map((col)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={col.name} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    let paginationSection = <TablePaginationContainer setPage={this.setPage}
                                      paginationData = {this.state.paginationData}
                                      setEntryLength={this.setEntryLength}/>

    return (
      <div className="card card-table">
      {modal}
      <div className="card-header">
        <div className="row table-filters-container">
          <div className="col-12 col-lg-12 col-xl-12">
            Filters
          </div>
        </div>
        </div>
        <div className="card-body">
          <div id="managerTable">
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive">
                  <thead>
                    <tr>
                    {managerColumns}
                    </tr>
                  </thead>
                  <tbody>
                    {managerList}
                  </tbody>
                </table>
              </div>
            </div>
            {paginationSection}
          </div>
        </div>
      </div>
    )
  }
}
export default ManagersTable;









