import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { setKey } from '../utils/KeySetter'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

class EcardPreview extends Component {
  constructor(){
    super();
     this.state = {
      currentEcardId: null
    }

  }

  componentDidMount(){
  }


  render() {
    if(!this.props.currentEcard) return null;
    let playerHeight = this.props.playerHeight ? this.props.playerHeight : '270px';
    let playerWidth = this.props.playerWidth ? this.props.playerWidth : '465px';
    let wistiaUrl = "https://greetingcc.wistia.com/medias/"+this.props.currentEcard.wistia_default_hashed_id;
    return (
      <div  className="card mb-3">
            <ReactPlayer url={wistiaUrl} width={playerWidth} height={playerHeight} />
            <div className="card-header text-center">{this.props.currentEcard.name}</div>
            <div className="card-body text-center">
              <p>{this.props.currentEcard.description}</p>
              {this.props.children}
            </div>
        </div>

      )
  }
}
export default EcardPreview;





