import React, { Component } from 'react';
import TablePaginationButton from './TablePaginationButton'

class TablePagination extends Component {
  constructor(){
    super();
    this.showPages = 5;
    this.showPreviousPages = 4;
    this.showNextPages = 1;
  }

  setPageStart(){
    return this.props.currentPage <= this.showPages ? 1 : this.props.currentPage - this.showPreviousPages;
  }

  setPageEnd(){
    return this.props.currentPage < this.showPages ? this.showPages : this.props.currentPage + this.showNextPages;
  }

  render() {
    let pageList = '';
    if(!this.props.hidePageList){
      pageList = [...Array(this.props.totalPages).keys()].map((i)=>{
        let page = i+1;
        if(page >= this.setPageStart() && page <= this.setPageEnd()){
          return <TablePaginationButton isActive={page === this.props.currentPage} onChangePage={this.props.onChangePage} key={page} pageNumber={page}/>
        } else{
          return '';
        }
      })
    }
    //
    let nextPage = parseInt(this.props.currentPage) + 1;
    let nextPageDisabled = nextPage > this.props.totalPages ? true : false;
    //
    let previousPage = parseInt(this.props.currentPage) - 1;
    let previousPageDisabled = previousPage < 1 ? true : false;
    return (
      <ul className="pagination">
        <TablePaginationButton key={0} pageNumber={previousPage} onChangePage={this.props.onChangePage} isDisabled={previousPageDisabled} isPrevious='true'/>
        {pageList}
        <TablePaginationButton key={parseInt(this.props.totalPages)+1} pageNumber={nextPage} onChangePage={this.props.onChangePage} isDisabled={nextPageDisabled} isNext='true'/>
      </ul>
    )
  }
}
export default TablePagination;