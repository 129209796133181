import React, { Component } from 'react';
import TableColumnHeader from './TableColumnHeader'
import TablePaginationContainer from './TablePaginationContainer'
import OrderItemFormEditContainer from './OrderItemFormEditContainer';
import OrderFormNew from './OrderFormNew';
import Modal from './Modal';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { setKey } from '../utils/KeySetter'

class EventDatesTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/event_dates';
    this.state = {
      eventDateId: null,
      eventDates: [],
      currentSortColumn: 'event_dates.send_on_date',
      currentSortDirection: 'asc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getEventDates = this.getEventDates.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadEventDate = this.loadEventDate.bind(this);
    this.duplicateEventDateState = this.duplicateEventDateState.bind(this);
    this.updateEventDateState = this.updateEventDateState.bind(this);
    this.toggleEventDateState = this.toggleEventDateState.bind(this);
    this.createEventDateOrderState = this.createEventDateOrderState.bind(this);
    this.removeEventDate = this.removeEventDate.bind(this);
  }

  componentDidMount() {
    this.getEventDates();
  }

  setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getEventDates();
    })
  }


  loadEventDate(eventDateId){
    this.setState({
      eventDateId: eventDateId
    })
  }

  removeEventDate(eventDateId){
    if(window.confirm('Are you sure you want to remove this event date?')){
      fetchAuthorized(this.baseUrl+'/'+eventDateId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          eventDateId: eventDateId
          },()=>this.removeEventDateState())
      })
    }
  }

  currentEventDate(){
    let dup = this.duplicateEventDateState();
    return dup.eventDates[dup.index];
  }

  removeEventDateState(){
    let dup = this.duplicateEventDateState();
    let filteredEventDates = dup.eventDates.slice(0, dup.index).concat(dup.eventDates.slice(dup.index + 1, dup.eventDates.length))
    this.setState({eventDateId: null, eventDates: filteredEventDates},()=>console.log(this.state.eventDates));
  }

  duplicateEventDateState(){
    const index = this.state.eventDates.findIndex(item => item.id === this.state.eventDateId);
    let eventDates = [...this.state.eventDates] // important to create a copy, otherwise you'll modify state outside of setState call
    let eventDateDup = Object.assign({},this.state.eventDates[index]);
    return {index: index, eventDate: eventDateDup, eventDates: eventDates}
  }

  toggleEventDateState(eventDateId, attr){
    const index = this.state.eventDates.findIndex(item => item.id === eventDateId);
    let eventDates = [...this.state.eventDates]
    let eventDateDup = Object.assign({},this.state.eventDates[index]);
    let attrList = attr.split(".");

    if(attrList.length === 3){
      let existingVal = eventDateDup[attrList[0]][attrList[1]][attrList[2]];
      eventDateDup[attrList[0]][attrList[1]][attrList[2]] = existingVal === false ? true : false;
    } else if(attrList.length === 2){
      let existingVal = eventDateDup[attrList[0]][attrList[1]];
      eventDateDup[attrList[0]][attrList[1]] = existingVal === false ? true : false;
    }else{
      let existingVal = eventDateDup[attrList[0]];
      eventDateDup[attrList[0]] = existingVal === false ? true : false;
    }

    eventDates[index] = eventDateDup;
    this.setState({eventDates: eventDates});
  }

  updateEventDateState(){
    let dup = this.duplicateEventDateState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.eventDateId, { type: 'connect'})).then(eventDate => {
      dup.eventDates[dup.index] = eventDate.event_date;
      this.setState({eventDateId: null, eventDates: dup.eventDates},()=>console.log(this.state.eventDates));
      let els = document.getElementsByClassName("modal-close-class");
      for (let item of els) { item.click(); }
    });
  }

  createEventDateOrderState(order){
    let dup = this.duplicateEventDateState();
    dup.eventDate.order = order;
    dup.eventDates[dup.index] = dup.eventDate;
    this.setState({eventDateId: dup.eventDate.id, eventDates: dup.eventDates});
  }

  setParams(){
    return {
      type: 'connect',
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }

  getEventDates() {
    let params = Object.assign(this.props.eventDateParams, this.setParams());
    console.log(this.baseUrl);
    console.log(params);
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(eventDates => {
       console.log(eventDates);
        if(eventDates.error || eventDates.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(eventDates.event_dates.length){
          let paginationData = Object.assign({}, this.state.paginationData);
          paginationData.totalPages =  parseInt(eventDates.meta.total_pages);
          paginationData.totalItems =  parseInt(eventDates.meta.total_items);
          paginationData.currentPage = parseInt(eventDates.meta.page);
          this.setState({ eventDates: eventDates.event_dates, paginationData: paginationData });
        }else{
          this.setState({eventDates: []});
        }
      }
    }).catch(error=>{console.log(error)})
  }

  render() {
    let isSenderView = this.props.type === "SenderEventDates" ? true : false;

    /* modal data */

    let modal = <Modal/>;
    let modalTitle = '';
    let modalContents = '';
    if(this.state.eventDateId){
      let currentEventDate = this.currentEventDate();
      if(currentEventDate.order){
        modalTitle = 'Edit Your Send';
        modalContents = <OrderItemFormEditContainer key={setKey('edit-'+this.state.eventDateId)} saveOrderItemCallback={this.updateEventDateState} eventDate={currentEventDate} orderItem={currentEventDate.order.order_item}/>
      } else{
        modalTitle = 'Add Your Send';
        modalContents = <OrderFormNew key={setKey('new-'+this.state.eventDateId)} saveOrderCallback={this.createEventDateOrderState} eventDate={currentEventDate} />
      }
      modal = <Modal title={modalTitle} iconClassName="mdi mdi-account">{modalContents}</Modal>;
    }

    /* table contents and sorting */

    let eventDateList = this.state.eventDates.map((eventDate)=>{
      let rowComponentProps = {key: eventDate.id, loadEventDate: this.loadEventDate, removeEventDate: this.removeEventDate, eventDate: eventDate, rowToggleCallback: this.toggleEventDateState }
      return React.cloneElement(this.props.rowComponent, rowComponentProps);
    })

    let eventDateColumns = this.props.eventDateColumns.map((col,index)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={setKey('col-'+index+col.name)} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    /* pagination data */

    let paginationSection = <TablePaginationContainer
                                      isSenderView={isSenderView}
                                      setPage={this.setPage}
                                      paginationData = {this.state.paginationData}
                                      setEntryLength={this.setEntryLength}/>


    let paginationSectionHeader = '';
    let paginationSectionFooter = '';
    if(this.props.type === 'SenderEventDates'){
      paginationSectionHeader = paginationSection;
    } else{
      paginationSectionFooter = paginationSection;
    }

    return (
      <div className="card card-table">
      {modal}
        <div className="row table-filters-container">
          <div className="col-12 col-lg-12 col-xl-12">
            Filters
          </div>
        </div>
        <div className="card-body">
          <div id="eventDateTable">
            {paginationSectionHeader}
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive">
                  <thead>
                    <tr>
                    {eventDateColumns}
                    </tr>
                  </thead>
                  <tbody>
                    {eventDateList}
                  </tbody>
                </table>
              </div>
            </div>
            {paginationSectionFooter}
          </div>
        </div>
      </div>
    )
  }
}

export default EventDatesTable









