import React, { Component } from 'react';
import { setKey } from '../utils/KeySetter';

class SortByDateStatus extends Component {
  constructor(){
    super();
    this.state = {
      activeOption: null
    }
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount(){
   this.setState({
      activeOption: this.props.options[0]
    })
  }

  onClick(e){
    let newOption = e.target.innerHTML;
    console.log(newOption);
    this.setState({
      activeOption: newOption
    },()=>this.props.onOptionClick(newOption))
  }

  render() {
    if(!this.state.activeOption) return null;
    let optionArray  = this.props.options.map((option)=>{
      let activeClass = this.state.activeOption == option ? 'btn btn-primary' : 'btn btn-secondary';
      return <button key={setKey('date-status-'+option)} onClick={this.onClick} className={activeClass}>{option}</button>
    })


    return (
      <div className="filter-by col-2 col-lg-2 text-right">
                  <div className="label">Sort By: </div>
                  {optionArray}
                </div>
    )
  }
}

export default SortByDateStatus;


