import React, { Component } from 'react';
import AdminNav from '../nav/AdminNav';
import Search from '../generic/Search';
import AdminHomeTabs from './Admin/AdminHomeTabs';

class AdminHome extends Component {
  render() {
    return (
      <div className="be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
          <div className="main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
                <AdminHomeTabs/>
              </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default AdminHome;
