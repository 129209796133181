import React, { Component } from 'react';
import IdLink from '../../generic/IdLink';
import AvatarImage from '../../generic/AvatarImage';
import ContactsEventsList from './ContactsEventsList';
import { formattedDate, formattedMonthDay } from '../../utils/DateFormatter';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ContactsTableRowDetail extends Component {
  constructor(){
    super();
    this.linkToUpcomingSends = this.linkToUpcomingSends.bind(this);
    this.linkToHistory = this.linkToHistory.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
  }

  componentDidMount(){
    this.setState({
      events: this.props.contact.events
    })
  }

  linkToUpcomingSends(){
    let baseUrl = window.location.pathname.replace('/contacts','')
    window.location.href = baseUrl+"?contact="+this.props.contact.id
  }
  linkToHistory(){
    let baseUrl = window.location.pathname.replace('/contacts','')
    window.location.href = baseUrl+"/history?contact="+this.props.contact.id
  }
  toggleRow(){
     document.getElementById('toggle-row-'+this.props.contact.id).click();
  }



  render(){
    let nextSend = "None"
    if(this.props.contact.next_send){
      nextSend = formattedDate(this.props.contact.next_send)
    }
    let companyAccountName = '';
    if(this.props.contact.company_account){
      companyAccountName = this.props.contact.company_account.name
    }
    let baseUrl = window.location.pathname+'/'
    return (
      <tr>
      <td colSpan="6">
      <div className="row contact-details-container">
                      <div className="col-6 ">

                      <div className="profile">
                      <AvatarImage email={this.props.contact.email} url={this.props.contact.avatar_url}/>
                      <div className="profile-details">
                        <h3>{this.props.contact.full_name}</h3>

                         <ul>
                         <li>
                            <div className="label">Email:</div>
                            <div>{this.props.contact.email}</div>
                          </li>
                          <li>
                            <div className="label">Company:</div>
                            <div>{companyAccountName}</div>
                          </li>
                          <li>
                            <div className="label">Title:</div>
                            <div>{this.props.contact.title}</div>
                          </li>
                          <li>
                            <div className="label">Office:</div>
                            <div>{this.props.contact.office}</div>
                          </li>

                        </ul>
                        </div>
                        <div className="profile-actions">
                          <button className="btn btn-secondary"  onClick={()=>this.props.loadContact(this.props.contact.id)} data-toggle="modal" data-target="#edit-row"><span className="mdi mdi-edit"></span> Edit</button>
                        <button className="btn btn-secondary" onClick={()=>this.props.removeContact(this.props.contact.id)}><span className="mdi mdi-delete"></span> Delete</button>
                        <button className="btn btn-secondary disabled"><span className="mdi mdi-refresh-sync"></span>Sync to CRM</button>

                        </div>
                        </div>
                      </div>
                      <div className="col-5">
                       <div className="important-events">
                       <div className="event-list">
                        <h3>Important Events</h3>
                         <ContactsEventsList contactId={this.props.contact.id}/>
                        </div>
                        <div className="event-actions">
                          <button className="btn btn-primary" onClick={()=>this.props.loadEventForm(this.props.contact.id)} data-toggle="modal" data-target="#edit-row"><span className="mdi mdi-plus"></span> Add an Event</button>
                          <button className="btn btn-secondary" onClick={this.linkToUpcomingSends}><span className="mdi mdi-eye"></span> Upcoming Sends</button>
                          <button className="btn btn-secondary" onClick={this.linkToHistory}><span className="mdi mdi-eye"></span> History</button>
                        </div>
                        </div>
                      </div>

                      <div className="col-1">
                      <button className="btn btn-secondary close-button" onClick={this.toggleRow}><span className="mdi mdi-close"></span></button>
                      </div>
                    </div>
      </td>
      </tr>
      )
  }
}


export default ContactsTableRowDetail;