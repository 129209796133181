import React, { Component } from 'react';

class FieldMapper extends Component {
  constructor(){
    super();
    this.state = {
      appColumnId: ''
    }
    this.onChangeEvent = this.onChangeEvent.bind(this);
  }
  componentDidMount(){
    this.setState({
      appColumnId: this.props.appColumns[0].id
    })
  }

  onChangeEvent(e){
    this.setState({appColumnId: e.target.value },()=>{
      this.props.onMap(this.state.appColumnId,this.props.uploadColumn.id);
    });
  }

  render() {
     const appColumns = this.props.appColumns.map(appColumn => (
      <option key={appColumn.id} value={appColumn.id}>{appColumn.text}</option>
      ))
    return (
          <tr>
              <td className="uploadColumnName">{this.props.uploadColumn.name}</td>
                 <td className="divider"><span className="icon mdi mdi-chevron-right"></span></td>
                <td>
                <select className="appColumns form-control" onChange={this.onChangeEvent} value={this.state.value}>
                  <option key='1' value=''>Please Select</option>
                  {appColumns}
                </select>
                </td>

          </tr>

      )
  }
}
export default FieldMapper;












