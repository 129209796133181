import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IdLink extends Component {
  constructor(){
    super();
    this.url = '#';
    this.baseUrl = '/'

  }
  render() {
    if(this.props.baseUrl){
      this.baseUrl = this.props.baseUrl;
    }
    if(this.props.id){
      this.url = this.baseUrl+this.props.id;
    }
    return (
      <div className={this.props.className}>
        <Link to={this.url}>{this.props.label}</Link>
        {this.props.children}
      </div>
      )
  }
}
export default IdLink;