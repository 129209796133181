import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class MemberNoticeForm extends Component {
  constructor(){
    super();
     this.state = {
      errors: null
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }


  onFormSubmit(event){
    console.log("FORM SUBMITTED");
    console.log(this.state)
    event.preventDefault();
      console.log(this.refs.timeToOrderDelivery.value);
      let formObj = {
        memberNotice: {
          time_to_order_delivery: this.refs.timeToOrderDelivery.value,
          member_id: this.props.senderId
        },


      }
      this.props.onSubmitEvent(formObj);
  }


  render() {

    let memberNotice = this.props.memberNotice ? this.props.memberNotice : {};
    let timeToOrderDelivery = this.props.memberNotice ? this.props.memberNotice.time_to_order_delivery : null
    let errors = '';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }

    return (
      <form className="member-notice-form">
        {errors}
        {memberNotice.time_to_order_delivery}

          <div className="row col-12">
            <div className="col-6 form-group">
            <label className="input-header">Send a notification:</label>
            <select ref="timeToOrderDelivery" className="form-control" defaultValue={timeToOrderDelivery}>
              <option value=''>Please Select</option>
              <option value="1440">1 Day</option>
              <option value="2880">2 Days</option>
              <option value="4320">3 Days</option>
              <option value="5760">4 Days</option>
              <option value="7200">5 Days</option>
              <option value="8640">6 Days</option>
              <option value="10080">1 Week</option>
              <option value="20160">2 Weeks</option>
              <option value="30240">3 Weeks</option>
              <option value="40320">4 Weeks</option>
            </select> before event.

            </div>
          </div>

          <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}
export default MemberNoticeForm;










