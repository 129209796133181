import React, { Component } from 'react';
import PasswordField from './PasswordField';
import { fetchAuthorized } from '../utils/Fetcher'

class UserFormEditPassword extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/users/';
    this.state ={
      password: null
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateUser(refs){
    var formData  = new FormData();



    formData.append('type','connect');
    formData.append('user[password]', this.state.password);
    formData.append('user[password_confirmation]', this.state.password);

    return fetchAuthorized(this.baseUrl+this.props.userId,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveUserCallback();
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  handlePasswordChange(val){
    this.setState({password: val})
  }

  onFormSubmit(event){
    event.preventDefault();
    this.updateUser();
  }



  render() {
    let passwordField = <PasswordField onChange={this.handlePasswordChange}/>;

    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
      <form>
      {passwordField}
      <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
      </form>
       </div>
      </div>
      )
  }
}
export default UserFormEditPassword;










