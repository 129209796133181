import React, { Component } from 'react';
import PasswordField from '../../generic/PasswordField';
import PhotoField from '../../generic/PhotoField';
import SenderSelect from '../../generic/SenderSelect'
import RoleSelect from '../../generic/RoleSelect'
import { setKey } from '../../utils/KeySetter'
import { formattedSelect } from '../../utils/Helpers'

class ManagerForm extends Component {
  constructor(){
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
     this.state ={
      role: 'collaborator',
      senderIds: [],
      senderOptions: [],
      avatarData: null,
      updateAvatarData: true,
      password: null
    }
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setSenderId = this.setSenderId.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.onAvatarChangeHandler = this.onAvatarChangeHandler.bind(this);
  }

  componentDidMount(){
    if(this.props.manager){
      this.setState({
        role: this.props.manager.role,
        updateAvatarData: this.props.manager.avatar_url ? false : true
      },()=>this.setSenderId())
    }
  }

  handleSelectChange(event){
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState)
  }

  onFormSubmit(event){
    event.preventDefault();
    this.refs.role = this.state.role;
    this.refs.senderIds = this.state.senderIds;
    this.refs.password = this.state.password;
    this.refs.avatarData = this.state.avatarData;
    this.refs.updateAvatarData = this.state.updateAvatarData;
    this.props.onSubmitEvent(this.refs);
  }

  setSenderId(val){
    let formattedVal = val ? val : formattedSelect(this.props.manager, 'senders');
    console.log(formattedVal);
    this.setState({
      senderIds: formattedVal.ids,
      senderOptions: formattedVal.options
    },()=>console.log(this.state))
  }

  onAvatarChangeHandler(val){
    this.setState({avatarData: val, updateAvatarData: true})
  }

  handlePasswordChange(val){
    this.setState({password: val})
  }

  render() {
    let photoField = <PhotoField fieldLabel="Profile Photo:" defaultValue={this.props.manager.avatar_url} onChange={this.onAvatarChangeHandler}/>
    let passwordField = !Object.keys(this.props.manager).length ? <PasswordField onChange={this.handlePasswordChange}/> : '';
    let roleSelector = Object.keys(this.props.manager).length ? <RoleSelect role={this.state.role} handleChange={this.handleSelectChange}/> : '';
    let senderSelector = <SenderSelect key={setKey} setSenderCallback={this.setSenderId} defaultOptions={this.state.senderOptions} isMulti={true} />
    return (
      <form>
        <input ref="userId" className="form-control" id="userId" type="hidden" defaultValue={this.props.manager.user_id}/>
          <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={this.props.manager.email}/>
          </div>

          <div className="row col-12">
            <div className="col-6 form-group">
            <label className="input-header">First Name:</label>
            <input ref="firstName" className="form-control" id="firstName" type="text" placeholder="Enter First Name" defaultValue={this.props.manager.first_name}/>
            </div>
            <div className="col-6 form-group">
            <label className="input-header">Last Name:</label>
            <input ref="lastName" className="form-control" id="lastName" type="text" placeholder="Enter Last Name" defaultValue={this.props.manager.last_name}/>
            </div>
          </div>
   {photoField}

          {roleSelector}
           {senderSelector}
          {passwordField}
          <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}
export default ManagerForm;










