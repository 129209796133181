import { fetchAuthorized, generateUrl } from './Fetcher'
import { humanizeString } from './StringHumanizer'
import { stringToDate } from './DateFormatter';
import { emailIsValid } from './EmailValidator'

const ContactList = function(options={}){
  this.letters = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

  var rawworkbook = options.uploadedbook;
  this.workbook = rawworkbook.Sheets[rawworkbook.SheetNames[0]];
  this.uploadColumns = this.findUploadColumns();
  this.numberOfRecords = this.findNumberOfRecords();
  this.appEmailColumnId = 'email';
  this.errorContacts = [];
  this.duplicateContacts = [];
  this.contacts = [];
  this.appColumns = [
    { id: "sender", text: "Sender"},
    { id: "send-on", text: "Send Date"},
    { id: "contact-name", text: "Contact Name"},
    { id: "contact-email", text: "Contact Email"}
  ];
}

ContactList.prototype.getEventTypes = function() {
  let params = {type: 'connect', direction: 'asc', manual_sort_by: 'event_types.name',page: 1, per_page: 'All'}

  return fetchAuthorized(generateUrl('/api/v1/event_types', params)).then(event_types => {
    if(event_types.error || event_types.errors){
      console.log('UNAUTHORIZED');
    }else{
      return event_types.event_types;
    }
  })
}

ContactList.prototype.getContactColumns = function() {
  return fetchAuthorized(generateUrl('/api/v1/contacts/columns', {
      method: 'GET'
  })).then(columns => {
    if(columns.error || columns.errors){
      console.log('UNAUTHORIZED');
    }else{
      return columns.contact;
    }
  }).catch(error => {
    console.log(error);
    return error;
  })
}


ContactList.prototype.setAppColumns = function(){
  var _this = this;
  return _this.getContactColumns().then(columns=>{
    return _this.getEventTypes().then(event_types=>{

      console.log(event_types);
      _this.appColumns = _this.formatAppColumns(columns);
      event_types.map(event_type=>{
        _this.appColumns.push({id: "event_type_"+event_type.id, text: "Event - "+event_type.name});
      });
      if(_this.hasAppEmailColumn()){
        return this.appColumns;
      } else{
        console.log("Could not find email column, which is required!");
        return false;
      }
    });
  })
}

ContactList.prototype.formatAppColumns = function(columns){
  let keys = Object.keys(columns);
  return keys.map(key => {
    let textLabel =  columns[key] ? columns[key] : humanizeString(key);
    return { id: key, text: textLabel }
  })
}

ContactList.prototype.findUploadColumns = function(){
  var arr = [];
  for(var i=0;i<this.letters.length;i++){
    if(this.workbook[this.letters[i]+"1"] !== undefined){
      arr.push({id: this.letters[i], maptoid: undefined, name: this.workbook[this.letters[i]+"1"].v});
    }
  }
  return arr;
}

ContactList.prototype.findNumberOfRecords = function(){
    let count = 0;
    let rawNumber= this.workbook["!ref"].split(":")[1].replace(/\D/g,'');

    for(let i=0;i<=rawNumber;i++){
      let cellVals = [];
      for(let p=0;p<this.uploadColumns.length;p++){
        let cell = this.cell(this.uploadColumns[p].id,i);
        if(this.workbook[cell] !== undefined) cellVals.push(cell);
      }
      if(cellVals.length > 0) count = count+1;
    }
    return count;
}

ContactList.prototype.cell = function(col, num){
  return col+num.toString();
}

   /* if(this.get('senders').length==0 || this.get('contactSendOnColumn')==undefined || this.get('contactNameColumn')==undefined || this.get('contactEmailColumn')==undefined){
      return {};
    }
    send_on: moment(this.workbook[contactSendOnCell].w).format(),

    */
ContactList.prototype.hasAppEmailColumn = function(){
  const index = this.appColumns.findIndex(item => item.id === this.appEmailColumnId);
  return index===-1 ? false : true;
}

ContactList.prototype.emailFieldMapped=function(){
  const index = this.uploadColumns.findIndex(item => item.maptoid === this.appEmailColumnId);
  console.log(index);
  return index===-1 ? false : true;
  // check email field to make sure it is appropriately formatted.  If not, add problem contact to another object
  // check to see if
}

ContactList.prototype.formatCellValue = function(rawCellValue, column_id){
  if(rawCellValue==undefined){
    return '';
  }
  // get the cell value from the workbook and format appropriately
  let cellValue = '';

  if(column_id.split("event_type_").length > 1){
    cellValue = rawCellValue.w
  } else{
    cellValue = rawCellValue.v
  }

  if (typeof cellValue === 'string' || cellValue instanceof String){
    cellValue = cellValue.trim();
    try {
      cellValue = decodeURIComponent(escape(cellValue))
    }
    catch(err) {
      console.log(err);
      console.log(cellValue)
    }
  }
  return cellValue;
}

ContactList.prototype.prepareForImport = function(){
  this.contacts = [];
  let validEmails = [];
  this.errorContacts = [];
  this.duplicateContacts = [];
  for(var p=2;p<=parseInt(this.numberOfRecords);p++){

    let contact = {events_attributes: []};
    let emailValidationError = false;
    let emailDuplicationError = false;

    this.uploadColumns.map(col=>{

      // check to see if the column in the upload has a match in our app fields
      if(col.maptoid){


        let rawCellValue = this.workbook[this.cell(col.id,p)];
        let cellValue = this.formatCellValue(rawCellValue, col.maptoid);

        // check if this is the email column
        if(col.maptoid === this.appEmailColumnId){
          // if so, run validation
           if(emailIsValid(cellValue)){
              // if validation passes, check to see if its a dup email in the list.
              if(validEmails.includes(cellValue)){
                emailDuplicationError = true;
              } else{
                validEmails.push(cellValue)
              }
           }else{
              emailValidationError = true;
           }
        }
        // treat this as an event type
        if(col.maptoid.split("event_type_").length > 1){
        console.log("OCCURS ON");
        console.log(this.workbook[this.cell(col.id,p)]);
           console.log(cellValue);
           contact["events_attributes"].push({event_type_id: col.maptoid.split("event_type_")[1], occurs_on: stringToDate(cellValue) });
        } else{
          // otherwise normal field
           contact[col.maptoid] = cellValue;
        }

      }
    });

    contact['row_number'] = p;
    // assign contact to correct array based on validation
    if(emailValidationError){
      this.errorContacts.push(contact);
    } else if(emailDuplicationError){
      this.duplicateContacts.push(contact);
    } else{
      this.contacts.push(contact);
    }
  }
  console.log(this.contacts)
  return this.contacts;
}

export default ContactList;
