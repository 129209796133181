import React, { Component } from 'react';
import MemberNoticeForm from './MemberNoticeForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class MemberNoticeFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/member_notices/';
    this.updateMemberNotice = this.updateMemberNotice.bind(this);
  }


  // called from MemberNoticeForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateMemberNotice(formObj){
    var formData  = new FormData();

    formData.append('type','connect');
    formData.append('member_notice[time_to_order_delivery]', formObj['memberNotice']['time_to_order_delivery']);

    return fetchAuthorized(this.baseUrl+this.props.memberNotice.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveMemberNoticeCallback(formObj);
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }



  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <MemberNoticeForm key={this.props.memberNotice.id} memberNotice={this.props.memberNotice} onSubmitEvent={this.updateMemberNotice} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default MemberNoticeFormEditContainer;










