import React, { Component } from 'react';
import TabPane from '../../generic/TabPane';
import TabNavItem from '../../generic/TabNavItem';
import EventDatesTable from '../../generic/EventDatesTable';
import SenderEventDatesTableRow from './SenderEventDatesTableRow';
import { setKey } from '../../utils/KeySetter'

class SenderTabs extends Component {
  constructor(){
    super();
    this.tabPanels = [];
    this.state = {
      eventDateParams: null,
      eventDateColumns: [
      { name: 'Sending To', sortApiColumn: 'contacts.email', sortable: true },
      { name: 'Scheduled', sortApiColumn: 'event_dates.send_on_date', sortable: true },
      { name: 'E Card' },
      { name: '' }
      ],
      tabPanels: [
      {
        isActive: true,
        key:'upcomingSends',
        name: 'Upcoming Sends',
        content: ''
      },
      {
        key:'history',
        name: 'History',
        content: ''
      }
      ]
    }
    this.setActivePanel = this.setActivePanel.bind(this);
  }
  componentDidUpdate(){
    this.setEventDateParams();
  }

  componentDidMount(){
    this.setEventDateParams(true);
  }



  setEventDateParams(fromComponentMount=false){
    if(!this.state.eventDateParams){
      this.setState({
        eventDateParams: {  sender_id: this.props.senderId  }
      },()=>{
        if(fromComponentMount) this.setActivePanel(true);
      })
    }
  }


  setActivePanel(bypassActive=false){

    let tabPanels = [...this.state.tabPanels];
    if(!bypassActive){
      tabPanels.map((panel)=> panel.isActive = panel.isActive ? false :  true);
    }
    tabPanels.map((panel)=> panel.content = '');
    //
    tabPanels.map((panel)=>{
      if(panel.isActive){
        if(panel.key === 'upcomingSends'){
          panel.content = <EventDatesTable key={setKey('edt')} type="SenderEventDates" rowComponent={<SenderEventDatesTableRow/>} id="upcomingSends" eventDateColumns={this.state.eventDateColumns} eventDateParams={this.state.eventDateParams}/>;
        }else{
          panel.content = <EventDatesTable key={setKey('edt1')} type="SenderEventDates" rowComponent={<SenderEventDatesTableRow/>} id="history" eventDateColumns={this.state.eventDateColumns} eventDateParams={this.state.eventDateParams}/>;
        }
      }
      return panel;
    })
    this.setState({tabPanels: tabPanels});
  }

  render() {
    let navItems = this.state.tabPanels.map((panel) => {
      return <TabNavItem key={panel.key} isActive={panel.isActive} onClick={this.setActivePanel} name={panel.name}/>
    });

    let panels = this.state.tabPanels.map((panel) =>
      <TabPane key={panel.key} isActive={panel.isActive} id={panel.key} name={panel.name}>
        {panel.content}
        {panel.addForm}
      </TabPane>
    );


    return (
      <div className="splash-content-tabs tab-container">
        <ul className="nav nav-tabs nav-tabs-classic" role="tablist">
           {navItems}
        </ul>
        <div className="tab-content">
          {panels}
        </div>
      </div>
      )
  }
}
export default SenderTabs;