import React, { Component } from 'react';

import TableEntryLength from './TableEntryLength'
import TablePagination from './TablePagination'
import TablePaginationText from './TablePaginationText'

class TablePaginationContainer extends Component {

  render() {
    let {currentPage, totalPages, entryLengths, entryLength, totalItems} = this.props.paginationData;
    let pagination = <TablePagination hidePageList={this.props.isSenderView} onChangePage={this.props.setPage} currentPage={currentPage} totalPages={totalPages}/>
    pagination = entryLength === 'All' ? '' : pagination;
    let paginationText = <TablePaginationText currentPage={currentPage} entryLength={entryLength} totalItems={totalItems}/>
    let tableEntryLength = <TableEntryLength entryLengths={entryLengths} entryLength={entryLength} onEntryLengthChange={this.props.setEntryLength}/>

    if(totalPages === 0 && totalItems === 0) pagination =  paginationText = tableEntryLength = '';
    if(totalItems <= 10) pagination = tableEntryLength = '';
    if(this.props.isSenderView){
      paginationText = <div>
        <button className="btn btn-secondary"><span className="icon mdi mdi-calendar-note"></span></button>
        <button className="btn btn-secondary"><span className="icon mdi mdi-format-list-bulleted"></span></button>
      </div>
    }


    return (
        <div className="row table-pagination-container">
          <div className="col-sm-5">
           {paginationText}
           </div>
           <div className="col-sm-7">
             <div className="paging_simple_numbers float-right">
               {pagination}
             </div>
            {tableEntryLength}
           </div>
        </div>
      )
  }
}
export default TablePaginationContainer;