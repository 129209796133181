import React, { Component } from 'react';
import { setKey } from '../utils/KeySetter'

class OrderFormFiller extends Component {
  constructor(){
    super();
  }

  render() {

    let addlImages =[];
    if(this.props.category){
      if(this.props.category.name=='Birthday'){
        addlImages = ['/hb-1.jpg'];
      }
      if(this.props.category.name=='Work Anniversary'){
        addlImages = ['/wa-1.jpg', '/wa-2.jpg','/wa-3.jpg','/wa-4.jpg'];
      }
      if(this.props.category.name=='Thank You'){
        addlImages = ['/ty-1.jpg', '/ty-2.jpg','/ty-3.jpg','/ty-4.jpg','/ty-5.jpg'];
      }
      if(this.props.category.name=='Congratulations'){
        addlImages = ['/c-1.jpg'];
      }
    }
    let filler= addlImages.map((jpg, index)=>{
      return <div key={setKey('plc')} data-cool={index} className="col-6 col-lg-6">
            <img src={jpg} alt="ecard-front"/>
            </div>
    })

    return (
      <div className="row">{filler}</div>
      )
  }
}
export default OrderFormFiller;










