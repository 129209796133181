import React, { Component } from 'react';
import Modal from './Modal';
import { setKey } from '../utils/KeySetter'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

class SendPreviewForm extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/delivery_details/';
     this.state = {
      errors: null,
      previewSent: false
    }
    this.sendPreview = this.sendPreview.bind(this);
  }

  // sent to the component as onSubmitEvent
  sendPreview(event){
    event.preventDefault();

    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('delivery_detail[preview_to_email]', this.refs.sendPreviewEmail.value);

    return fetchAuthorized(this.baseUrl+this.props.eventDate.order.delivery_detail_id+"/send_preview",{
      method: 'PUT',
      body: formData
    }).then(resp=>{
      if (resp.errors){
        this.setState({errors: 'There was an error.  Please try again.',previewSent: false})
      } else{
        this.setState({previewSent: true, errors: false})
      }

    }).catch(error => {
      console.log(error);
      return error;
    })
  }


  render() {

    let classNames = ["col-10","col-lg-10","add-contact-dialog", "from-modal"];
    let errors = '';
    let previewSent ='';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }
    if(this.state.previewSent){
      previewSent = <div className="alert alert-primary">Your preview has been sent!</div>
    }

    return (
      <div className={classNames.join(" ")}>

        <div className="card text-center">
          <div className="card-body">
          <h3>Send Preview</h3>
          <form>
        {errors}
        {previewSent}


          <div className="row col-12">
            <div className="col-6 form-group">
            <input ref="sendPreviewEmail" className="form-control" type="text" placeholder="Enter Email"/>
            </div>
            <div className="col-3 form-group">
                <button onClick={this.sendPreview} className="btn btn-space btn-primary btn-xl" type="submit">Send Preview</button>
            </div>
          </div>
        </form>
          </div>
        </div>
      </div>
      )
  }
}
export default SendPreviewForm;







