import React, { Component } from 'react';

class TablePaginationText extends Component {
  render() {
    let startCount = this.props.currentPage === 1 ? 1 : (((this.props.currentPage-1) * this.props.entryLength) + 1 );
    let rawEndCount = startCount + (this.props.entryLength-1);
    let endCount = rawEndCount < this.props.totalItems ? rawEndCount : this.props.totalItems;
    return (
      <div>Showing {startCount} to {endCount} of {this.props.totalItems} entries</div>
      )
  }
}
export default TablePaginationText;




