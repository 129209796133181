import React, { Component } from 'react';
import EcardThumbnail from './EcardThumbnail';
import ChooseEcard from './ChooseEcard';
import HoldEcardButton from './HoldEcardButton';
import { month, day, year,now,formattedDateTime } from '../utils/DateFormatter';
import moment from 'moment';

class OrderHistoryTimelineEntry extends Component {
  constructor(){
    super();
    this.senderName = 'No Name Given';
    this.contactId = '';
    this.contactName = 'No Name Given';
    this.locations = 'No Office Assigned';
    this.groups = 'None Assigned';
    this.managers = 'None Assigned';

  }

  render() {
    let order = this.props.order;
    let delivery = order.order_item.delivery;

    if(order.order_item.contact!=null){
      this.contactName = order.order_item.contact.full_name ? order.order_item.contact.full_name : order.order_item.contact.email;
      this.contactId = order.order_item.contact.id
    }

    let status = <div className="btn btn-warning">Sent</div>;
      if(delivery){
        if(delivery.opened){
          status = <div className="btn btn-success">Opened</div>;
        }else if(delivery.bounce) {
          status = <div className="btn btn-danger">Bounced <button className="btn btn-secondary edit no-border"  onClick={()=>this.props.loadOrder(this.props.order.id)} data-toggle="modal" data-target="#fixBounce" type="button">
             <span className="icon mdi mdi-edit"></span>&nbsp;&nbsp;Fix it!
            </button></div>;
        }
      }
    let deliveryStatus = '';
    if(delivery){
      if(delivery.opened){
        deliveryStatus =  delivery.opened_on ? <span className='open-detail'>Opened on: {formattedDateTime(delivery.opened_on)}</span> : '';
      } else if(delivery.bounce) {
        deliveryStatus =  <span className='open-detail'>Bounced on: {formattedDateTime(delivery.bounce.timestamp)}</span>;
      } else{
        deliveryStatus = <span className='open-detail'>Not Opened Yet</span>
      }
    }

    let classNames = ['history'];

      if(delivery){
        if(delivery.opened) classNames.push('opened');
        if(delivery.bounce) classNames.push('bounced');
        if(!delivery.opened && !delivery.bounce) classNames.push('sent');
      }


    return (
      <li key={order.id} style={{'zIndex': this.props.index}} className={classNames.join(' ')}>
         <div className="send-on col-lg-1 col-1">
          <p className="month">{month(order.sent_at)}</p>
          <p className="day">{day(order.sent_at)}</p>
          <p className="year">{year(order.sent_at)}</p>
        </div>
        <div className="send-to col-lg-3 col-3">
            {status}

            <div className="send-to-details">to <b>{this.contactName}</b>
             {deliveryStatus}
            </div>
        </div>
        <div className="col-lg-5 col-5">
        <EcardThumbnail ecardId={order.order_item.ecard_id} message={order.order_item.message}/>
       </div>
       <div className="actions text-right col-lg-3 col-3">

       </div>
      </li>
      )
  }
}

export default OrderHistoryTimelineEntry;