import React, { Component } from 'react';
import SenderNav from '../nav/SenderNav';
import Search from '../generic/Search';
import SectionHeader from '../generic/SectionHeader';
import Modal from '../generic/Modal';
import ContactsTable from './Contacts/ContactsTable';
import ContactsSnapshot from './Contacts/ContactsSnapshot';
import ContactFormNewContainer from './Contact/ContactFormNewContainer';
import ContactsImportFormContainer from './Contacts/ContactsImportFormContainer';
import {SenderContext} from '../contexts/sender-context';
import { setKey } from '../utils/KeySetter'

class SenderContacts extends Component {
  constructor(){
    super();
    this.state ={
      contactCreated: false
    }
    this.resetState = this.resetState.bind(this);
    this.addContactToTable = this.addContactToTable.bind(this);
  }
  addContactToTable(){
    this.setState({contactCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  resetState(){
    if(this.state.contactCreated){
      this.setState({contactCreated: false});
    }
  }

  render() {
    let sender = this.context.sender;
    return (
      <div className="senders be-wrapper be-nosidebar-left home-sender with-sub-nav">
      <SenderNav/>
       <div className="be-content">
        <Modal title="New Contact" id="new-contact" iconClassName="mdi mdi-account">
          <ContactFormNewContainer key={setKey()} saveContactCallback={this.addContactToTable} senderId={sender.id}/>
        </Modal>

         <Modal title="Import Contact" id="import-contact" iconClassName="mdi mdi-account">
            <ContactsImportFormContainer saveContactCallback={this.addContactToTable} senderId={sender.id}/>
        </Modal>
          <SectionHeader label='Your Contacts' newModalTarget='#new-contact' importModalTarget='#import-contact'/>
          <div className="main-content container-fluid main-content-padding">
          <div className="row stats-snapshot">
              <div className="col-12 col-lg-12">
                <div className="row">
                <h2>Snapshot</h2>
                </div>
                <ContactsSnapshot senderId={sender.id}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                  <ContactsTable contactCreated={this.state.contactCreated} onContactsLoaded={this.resetState} senderId={sender.id}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SenderContacts.contextType = SenderContext;
export default SenderContacts;
