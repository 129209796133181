import React, { Component } from 'react';
import Select from 'react-select';
import { todaysMonthName,todaysYearName,today,tomorrow, nextSevenDays, formatMomentForDay,formatMomentForStartEndDate, startOfPeriod,endOfPeriod, previousPeriod,nextPeriod } from '../utils/DateFormatter'

class FilterByDateTime extends Component {
  constructor(){
    super();
    this.state = {
      startDate: today(),
      activeDate: null,
      currentYear: '2022-01-01',
      currentMonth: '',
      currentDay: '',
      currentYearOption: {label: '2022', value: '2022-01-01'},
      currentMonthOption: {label: 'All', value: null},
      currentDayOption: {label: 'All', value: null},
      days: [
        {label: 'All', value: null},
        {value:'2021-01-15',label: '15'},
        {value:'2021-01-15',label: '16'}
      ],
      months: [
        {label: 'All', value: null},
        { value: '2022-01-01', label: 'Jan' },
        { value: '2022-02-01', label: 'Feb' }
      ],
      years: []
    }
    this.onClick = this.onClick.bind(this);
    this.onNextArrowClick = this.onNextArrowClick.bind(this);
    this.onBackArrowClick = this.onBackArrowClick.bind(this);
    this.setYear = this.setYear.bind(this);
    this.setMonth = this.setMonth.bind(this);
    this.onDropDownDateSet = this.onDropDownDateSet.bind(this);
    this.buildFutureYearOptions = this.buildFutureYearOptions.bind(this);
    this.buildMonthOptions = this.buildMonthOptions.bind(this);
  }

  componentDidMount(){
    let months = this.buildMonthOptions(this.state.currentYear);
    let years = this.props.history ? this.buildPastYearOptions() : this.buildFutureYearOptions();
    let startDate = this.props.startDate ? this.props.startDate : this.state.startDate;
    let period = this.props.period ? this.props.period : 'day';
    this.setState({
      startDate: startDate,
      activeDate: startDate,
      years: years,
      months: months,
      currentMonthOption: this.todaysMonth(),
      currentMonth: startOfPeriod(today()),
      currentDayOption: {label: 'All', value: null},
      currentDay: '',
      period: period
    })
  }

  todaysMonth(){
    return { value: startOfPeriod(today(), 'month'), label: todaysMonthName() }
  }

  buildFutureYearOptions(){
    return [
    { value: '2022-01-01', label: '2022' },
    { value: '2023-01-01', label: '2023' },
    { value: '2024-01-01', label: '2024' }
    ]
  }

  buildPastYearOptions(){
    return [
    { value: '2021-01-01', label: '2021' },
    { value: '2020-01-01', label: '2020' },
    { value: '2019-01-01', label: '2019' }
    ]
  }

  filterCurrentYearMonths(months){
    let todaysMonth = formatMomentForStartEndDate(today()).split('-')[1];
    let mymonths = months.filter((m)=>{
      if(this.props.history && m.value){
        let monthNumber = parseInt(m.value.split('-')[1]);
        if (monthNumber <= parseInt(todaysMonth)){
          return m;
        }
      }else{
        return m;
      }
    });
    return mymonths;
  }

  buildMonthOptions(date){
    let year = date.split('-')[0];
    let months = [
      {label: 'All', value: null},
      { value: year+'-01-01', label: 'January' },
      { value: year+'-02-01', label: 'February' },
      { value: year+'-03-01', label: 'March' },
      { value: year+'-04-01', label: 'April' },
      { value: year+'-05-01', label: 'May' },
      { value: year+'-06-01', label: 'June' },
      { value: year+'-07-01', label: 'July' },
      { value: year+'-08-01', label: 'August' },
      { value: year+'-09-01', label: 'September' },
      { value: year+'-10-01', label: 'October' },
      { value: year+'-11-01', label: 'November' },
      { value: year+'-12-01', label: 'December' }
    ]

    let todaysYear = formatMomentForStartEndDate(today()).split('-')[0];
    if(todaysYear === year){
      let mymonths = this.filterCurrentYearMonths(months);
      if(!this.props.history){
        mymonths[0].value = formatMomentForStartEndDate(today());
      }
      return mymonths;
    }else{
      return months;
    }
  }

  onClick(e){
    let newDate = e.target.getAttribute('apidate');
    let dateRange = {
      startDate: startOfPeriod(newDate,'day'),
      endDate: endOfPeriod(newDate,'day')
    }
    let options = {
      showAll: true
    }
    this.setState({
      activeDate: newDate
    },()=>this.props.onClick(dateRange, options))
  }

  onNextArrowClick(){
    let newStartDate = nextPeriod(this.state.startDate,'day');
    this.setState({
      startDate: newStartDate
    })
  }

  onBackArrowClick(){
    let newStartDate = previousPeriod(this.state.startDate,'day');
    this.setState({
      startDate: newStartDate
    })
  }

  formattedFilterLabel(date){
    let label = formatMomentForDay(date);
    if(label == formatMomentForDay(today())){
      label = "Today";
    } else if(label===formatMomentForDay(tomorrow())){
      label = "Tomorrow";
    }
    return label;
  }

  onDropDownDateSet(){
    let dateRange = {
      startDate: startOfPeriod(this.state.activeDate, this.state.period),
      endDate: endOfPeriod(this.state.activeDate, this.state.period)
    }
    console.log(dateRange);
    this.props.onClick(dateRange);
  }

  setYear(selectedOption){
    console.log(selectedOption);
    this.setState({
      currentYearOption: selectedOption,
      currentMonthOption: {label: 'All', value: null},
      currentDayOption: {label: 'All', value: null},
      currentYear: selectedOption.value,
      currentMonth: '',
      currentDay: '',
      period: 'year',
      activeDate: selectedOption.value,
      startDate: selectedOption.value,
      months: this.buildMonthOptions(selectedOption.value)
    },()=>{
      this.onDropDownDateSet();
    })

  }
  setMonth(selectedOption){
    if(!selectedOption.value){
      this.setYear({value: startOfPeriod(today(), 'year'),label: todaysYearName() })
    }else{
      let activeDate = (!selectedOption.value) ? this.state.currentYear : selectedOption.value;
      this.setState({
        currentMonthOption: selectedOption,
        currentMonth: selectedOption.value,
        currentDayOption: {label: 'All', value: null},
        currentDay: '',
        period: 'month',
        activeDate: activeDate,
        startDate: activeDate
      },()=>{
        this.onDropDownDateSet();
      })
    }


  }

  render() {
    if(!this.state.activeDate) return null;
    let dateArray = '';
    let backArrow = '';
    let nextArrow = '';
    if(this.state.currentMonth && !this.props.history){
      console.log("I'm in currentmonth");
      console.log(this.state.currentMonth);
      console.log(this.state.startDate);
      dateArray  = nextSevenDays(this.state.startDate).map((date)=>{
        let activeClass = this.state.activeDate == formatMomentForStartEndDate(date) ? 'white noselect' : 'noselect';
        let filterLabel = this.formattedFilterLabel(date);
        let apiDate = formatMomentForStartEndDate(date);
        return <li key={apiDate} apidate={apiDate} onClick={this.onClick} className={activeClass}>{filterLabel}</li>
      })
      backArrow = <li key="backDayArrow" onClick={this.onBackArrowClick}><span className="icon mdi mdi-chevron-left"></span></li>
      nextArrow = <li key="nextDayArrow" onClick={this.onNextArrowClick}><span className="icon mdi mdi-chevron-right"></span></li>
    }

    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        zIndex: 1028
      })
    }

    return (
      <div className="row filter-date-time">
        <div className="col-4 col-lg-4 dropdowns">
        <label className="year-label">Year: </label><Select value={this.state.currentYearOption} styles={customStyles} onChange={this.setYear} options={this.state.years} className="date-select react-select-control"/>
        <label className="month-label">Month: </label> <Select value={this.state.currentMonthOption} styles={customStyles} onChange={this.setMonth} options={this.state.months} className="date-select month react-select-control"/>
        </div>
        <div className="col-8 col-lg-8">
        <ul className="year-list text-right">
          {backArrow}
          {dateArray}
          {nextArrow}
        </ul>
        </div>
      </div>
    )
  }
}

export default FilterByDateTime;