import ls from 'local-storage';

export const fetchRedTailAuthorized = (endpoint,params) => {

  let apiKey = "6F62E0BB-E3C3-426F-A0FF-0D946E92BF41";
  let userKey = "FAAEDDA5-81C1-4328-81F5-D2157F636A45";
  let username = "gccportfolio";
  let password = "GccPortfolio2!";
  let authCreds  = apiKey+":"+username+":"+password;
  let userKeyCreds = apiKey+":"+userKey;
  console.log(authCreds);
  let authObj={};
  if(userKey){
    let base64AuthCreds =  Buffer.from(userKeyCreds).toString('base64');
    authObj['Authorization']='Userkeyauth '+base64AuthCreds;
  }else{
     let base64AuthCreds =  Buffer.from(authCreds).toString('base64');
     authObj['Authorization']='Basic '+base64AuthCreds
  }
  authObj['Content-Type']="text/json";
  let addlHeaderParams = {};
  let endpointWithHost = "https://api2.redtailtechnology.com/crm/v1/rest"+endpoint;
  //let endpointWithHost = "https://api2.redtailtechnology.com/crm/v1/rest"; //process.env.REACT_APP_API_HOST+endpoint;
  let paramsForFetch = params ? params : {};
  if(paramsForFetch['headers']) addlHeaderParams = Object.assign({}, params['headers']);


  // Buffer.from("Hello World").toString('base64');


    paramsForFetch["headers"] = Object.assign(authObj,addlHeaderParams);

    return window.fetch(endpointWithHost,paramsForFetch)
    .then(response => {
      return response.status!==204 ? response.json() : response.status
    })
    .catch(error => console.log(error));
}
