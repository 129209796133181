import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import CreatableSelect from 'react-select/lib/Creatable';

class CompanyAccountSelect extends Component {
  constructor(){
    super();
     this.state = {
      companyAccounts: null
    }

    this.setCompanyAccountOption = this.setCompanyAccountOption.bind(this);
    this.getCompanyAccounts = this.getCompanyAccounts.bind(this);
  }

  componentDidMount(){
    if(!this.state.companyAccounts){
      this.getCompanyAccounts();
    }
  }

  setCompanyAccountOption(selectedOption){
    this.props.setCompanyAccountCallback({
      ids: selectedOption.value,
      options: selectedOption
    });
  }

  getCompanyAccounts() {
    let params = {type: 'connect', direction: 'asc', manual_sort_by: 'company_accounts.name',page: 1, per_page: 'All'}

    fetchAuthorized(generateUrl('/api/v1/company_accounts', params)).then(company_accounts => {
       if(company_accounts.error || company_accounts.errors){
          console.log('UNAUTHORIZED');
        }else{
          console.log(company_accounts);
          let stateObj = {companyAccounts: []}
          if(company_accounts.company_accounts.length){
            stateObj.companyAccounts = company_accounts.company_accounts;
          }
          this.setState(stateObj);
      }
      })
  }


  render() {
    if(!this.state.companyAccounts) return null;
    const companyAccounts = this.state.companyAccounts.map(companyAccount => ({ value: companyAccount.id, label: companyAccount.name }))
    return (
      <div className="form-group">
           <label className="input-header">Company:</label>
            <CreatableSelect isClearable={true} value={this.props.defaultOptions} onChange={this.setCompanyAccountOption} onInputChange={this.buildNewCompanyAccount} options={companyAccounts} className="react-select-control"/>
      </div>
      )
  }
}
export default CompanyAccountSelect;





