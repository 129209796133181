 import React, { Component } from 'react';

class PasswordField extends Component {
  constructor(){
    super()
    this.onChangeEvent = this.onChangeEvent.bind(this);
  }

  onChangeEvent(e){
    console.log(e.target.value);
    this.props.onChange(e.target.value);
  }

  render(){
    let confirmPassword = '';

    if(this.props.showConfirmPassword){
      confirmPassword = <div className="form-group">
            <label className="input-header">Confirm Password:</label>
            <input ref="passwordConfirmation" className="form-control" id="passwordConfirmation" type="password" placeholder="Confirm Password"/>
          </div>
    }


    return (
      <div className="password-fields-container">
          <div className="form-group">
            <label className="input-header">Password:</label>
            <input ref="password" className="form-control" id="password" type="password" onBlur={this.onChangeEvent} placeholder="Enter Password"/>
          </div>
          {confirmPassword}
        </div>
      )
  }
}
export default PasswordField;










