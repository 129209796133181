import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import ContactsImportErrorList from './ContactsImportErrorList'

class ContactsImportStep3 extends Component {
  constructor(){
    super();
    this.state = {
      list: null,
      senderId: null,
      senders: [],
      errorList: null,
      errorType: null
    }
    this.showDuplicates = this.showDuplicates.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.onEmailFix = this.onEmailFix.bind(this);
    this.emailInDuplicateContacts = this.emailInDuplicateContacts.bind(this);
    this.finishStepEvent = this.finishStepEvent.bind(this);
    this.getSenders = this.getSenders.bind(this);
    this.setSenderId = this.setSenderId.bind(this);
  }

  componentDidMount(){
    this.setState({
      list: this.props.list,
      senderId: this.props.senderId
    },()=>this.getSenders())
  }

  showDuplicates(){
    this.setState({
      errorList: this.state.list.duplicateContacts,
      errorType: 'duplicate'
    })
  }

  setSenderId(e){
    this.setState({
      senderId: e.target.value
    })
  }

  getSenders() {
    if(this.state.senderId) {
      return true;
    }
    fetchAuthorized(generateUrl('/api/v1/members', {type: 'connect', direction: 'asc', manual_sort_by: 'members.id',page: 1, per_page: 'All'})).then(members => {
       console.log(members);
        if(members.error || members.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(members.members.length){
            this.setState({senders: members.members});
          }else{
            this.setState({senders: []});
          }
      }
      })
  }

  showErrors(){
    this.setState({
      errorList: this.state.list.errorContacts,
      errorType: 'invalid'
    })
  }

  emailInDuplicateContacts(email){
    const index = this.state.list.duplicateContacts.findIndex(item => item.email === email);
    return index===-1 ? false : true;
  }

  onEmailFix(newValue,index){
    let errorList = [...this.state.errorList] // important to create a copy, otherwise you'll modify state outside of setState call
    let errorListSelectedDup = Object.assign({},this.state.errorList[index]);
    ///// update data goes here
    errorListSelectedDup.email = newValue;
    errorList.splice( index, 1 );
    //
    let listDup = Object.assign({},this.state.list);
    if(this.emailInDuplicateContacts(newValue)){
      listDup.duplicateContacts.push(errorListSelectedDup);
    }else{
      listDup.contacts.push(errorListSelectedDup);
    }
    listDup.errorContacts.splice( index, 1 );
    this.setState({errorList: errorList, list: listDup},()=>{console.log(this.state.list)});

  }

  finishStepEvent(){
    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('contacts', JSON.stringify(this.state.list.contacts));
    formData.append('sender_id', this.state.senderId);

    fetchAuthorized('/api/v1/contacts/create_multiple',{
      method: 'POST',
      body: formData
    }).then(contacts => {
      this.props.onFinish(contacts)
    })
  }

  render() {
    let errorList = '';
    if(this.state.errorList && this.state.errorList.length > 0){
      errorList =  <ContactsImportErrorList onEmailFix={this.onEmailFix} errorType={this.state.errorType} rows={this.state.errorList}/>
    }
    const senders = this.state.senders.map(sender => (
      <option key={sender.id} value={sender.id}>{sender.last_name}</option>
      ))

    let senderSelect = '';
    if(!this.state.senderId){
     senderSelect =  (<div className="col-6 col-lg-6">
              <p>Assign these contacts to: (optional)</p>
              <select className="form-control" onChange={this.setSenderId} value={this.state.senderId}>
                <option value="">Select</option>
                {senders}
              </select>
              </div>);
    }


    return (
      <div className="card-body import-step-three">
        <div className="row">
        <div className="col-12 col-lg-12  text-center">
         <h1>Step Three</h1>
             <div className="row justify-content-md-center">
             {senderSelect}
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-lg-12 secondary-title">We've checked for errors in your list.</div>
             </div>
             <div className="row justify-content-md-center">
                <div className="col-3 col-lg-3">
                <div className="card misformatted text-danger card-border">
                  <div className="card-body">
                    <p className="number">{this.props.list.duplicateContacts.length}</p>
                    <p>Duplicates</p>
                    <button onClick={this.showDuplicates} className="btn btn-secondary"><i className="icon icon-left mdi mdi-chevron-right"></i> View</button>
                  </div>
                </div>
                </div>
                <div className="col-3 col-lg-3">
                <div className="card duplicates text-danger card-border">
                <div className="card-body">
                  <p className="number">{this.props.list.errorContacts.length}</p>
                  <p>Misformatted</p>
                  <button onClick={this.showErrors} className="btn btn-secondary"><i className="icon icon-left mdi mdi-chevron-right"></i> View</button>
                </div>
                </div>
                </div>
                <div className="col-3 col-lg-3">
                <div className="card correct text-success card-border">
                 <div className="card-body">
                 <p className="number">{this.props.list.contacts.length}</p>
                  <p>Correct</p>
                  <button onClick={this.finishStepEvent} className="btn btn-secondary btn-success"><i className="icon icon-left mdi mdi-chevron-right"></i> Import Now</button>
                </div>
                </div>
                </div>
            </div>
            <div className="row justify-content-md-center">
            {errorList}
            </div>
        </div>
        </div>

      </div>
      )
  }
}
export default ContactsImportStep3;



/* TO DO */

/*
 * Assign Contacts to an attorney
 * Crewate rails method for handling contact imports
 * call reails method from react
 * check for incorrectly formatted emails
 * have stat for number of contacts to be imported
 * have state for number of incorrectly formatted contacts
 *
 *
 */






