import React, { Component } from 'react';
import {Promise} from "bluebird";
import ErrorList from '../generic/ErrorList';
import { fetch, generateUrl,authenticate } from '../utils/Fetcher'
import ls from 'local-storage';

class IdSelectorForm extends Component {
  constructor(){
    super();
    this.state = {
      error: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  componentDidMount(){
    document.body.classList.add('be-splash-screen');
  }


  // Handle form submission.
  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    fetch('/api/v1/contacts/'+_this.refs.senderId.value+'?use_custom_field=true', {
      method: 'GET',
      headers: { subdomain: window.location.hostname.split(".")[0] }
    }).then(result => {
      if(!result){
         this.setState({error: 'Could not find.  Please try again.'})
      }
      else{
        console.log(result.contact);
        this.props.onIdSelected(result.contact);
      }
   }).catch(err=>{
    this.setState({error: err})
   })
  };

  render() {



  let errors = <ErrorList errors={this.state.error}/>

  return (

      <form>
      {errors}
 <div className="form-group">
            <label className="input-header">Please enter your Agent Id:</label>
            <input ref="senderId" className="form-control" id="senderId" type="text" placeholder="Enter Id"/>
          </div>
        <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.handleSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Find Agent</button>
                </p>
              </div>
            </div>
          </div>
    </form>

      )
  }
}

export default IdSelectorForm;
