import React, { Component } from 'react';

class OrderHistorySnapshot extends Component {
  constructor(){
    super();
  }

  render() {
    if(!this.props.breakdown) return null;
    let breakdown = this.props.breakdown;
    return (
          <div className="row">
               <div className="col-3 col-lg-3">
              <div className="card total">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-mail-send bg-primary"></span>
                      <span className="counter">{breakdown.sends}</span> <span className="label">Sends</span></h3>
                  </div>
              </div>
            </div>
              <div className="col-3 col-lg-3">
              <div className="card birthdays">
                  <div className="card-body">
                    <h3><span className="icon mdi mdi-email-open bg-success"></span>
                                        <span className="counter">{breakdown.opens}</span> <span className="label">Opens</span></h3>
                  </div>
              </div>
            </div>
             <div className="col-3 col-lg-3">
              <div className="card work-anniversaries">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-star bg-success"></span>
                                    <span className="counter">{breakdown.open_ratio}%</span><span className="label">Open Ratio</span></h3></div>
              </div>
            </div>
             <div className="col-3 col-lg-3">
              <div className="card other">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-alert-circle bg-danger"></span>
                                    <span className="counter">{breakdown.bounces}</span><span className="label">Bounces</span></h3>
                  </div>
              </div>
            </div>
            </div>

      )
  }
}
export default OrderHistorySnapshot;












