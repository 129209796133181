import React, { Component } from 'react';
import EcardThumbnail from './EcardThumbnail';
import ChooseEcard from './ChooseEcard';
import ApplyTemplateForm from './ApplyTemplateForm';
import HoldEcardButton from './HoldEcardButton';
import { month, day, year,now,formattedDateTime } from '../utils/DateFormatter';
import moment from 'moment';

class EventDatesTimelineEntry extends Component {
  constructor(){
    super();
    this.senderName = 'No Name Given';
    this.contactId = '';
    this.contactName = 'No Name Given';
    this.locations = 'No Office Assigned';
    this.groups = 'None Assigned';
    this.managers = 'None Assigned';
  }

  hasOrder(){
    return this.props.eventDate.order && this.props.eventDate.order.order_item;
  }




  render() {
    let eventDate = this.props.eventDate;

    if(eventDate.contact!=null){
      this.contactName = eventDate.contact.full_name ? eventDate.contact.full_name : eventDate.contact.email;
      this.contactId = eventDate.contact.id
    }
    let status = <div className="btn btn-secondary">Choose E Card</div>;
    if(this.hasOrder()){
      if(eventDate.order.order_item.held){
        status = <div className="btn btn-danger">Held</div>;
      } else{
        status = <div className="btn btn-primary">Scheduled</div>;
      }
    }
    let occurrenceNumber = "";
    if(eventDate.occurrence_number){
      occurrenceNumber = "YEAR "+eventDate.occurrence_number.toString();
    }
    let classNames = this.props.classNames;
      classNames.push(this.hasOrder() ? 'scheduled' : 'not-ready');
      classNames.push(this.hasOrder() && eventDate.order.order_item.held ? 'held' : '');

    let ecardLink = "";
    if(this.hasOrder()){
      ecardLink = <EcardThumbnail ecardId={eventDate.order.order_item.ecard_id} message={eventDate.order.order_item.message}/>
    } else{
      ecardLink = <ChooseEcard loadEventDate={this.props.loadEventDate} eventDate={eventDate}/>
    }

    let editLink="";
    let sendPreviewLink="";
    let holdLink = "";
    let sendNowLink ="";
    let  removeLink = <button onClick={()=>this.props.removeEventDate(eventDate.id)} className="icon btn btn-space btn-secondary remove" type="button">
        <i className="mdi mdi-delete"></i>
      </button>
    if(this.hasOrder()){
      holdLink = <div className="send-hold">
              <HoldEcardButton eventDateId={eventDate.id} orderItem={eventDate.order.order_item} toggleCallback={this.props.rowToggleCallback}/>
            </div>
      editLink =  <button onClick={()=>this.props.loadEventDate(eventDate.id)} className="btn btn-secondary edit" data-toggle="modal" data-target="#eventDateTimelineModal" type="button">
             <span className="icon mdi mdi-edit"></span>&nbsp;&nbsp;Edit
            </button>
      sendPreviewLink =  <button onClick={()=>this.props.loadEventDate(eventDate.id,true)} className="icon btn-space btn btn-secondary remove" data-toggle="modal" data-target="#eventDateTimelineModal" type="button">
             <i className="mdi mdi-eye"></i>
            </button>
      sendNowLink = <button onClick={()=>this.props.sendNow(eventDate.id)} className="icon btn btn-space btn-secondary remove" type="button">
              <i className="mdi mdi-email"></i>
            </button>
    }

    let applyTemplateForm = '';

    if(!this.hasOrder()){
        applyTemplateForm = <ApplyTemplateForm sender={this.props.sender} loadEventDate={this.props.loadEventDate} eventDate={this.props.eventDate} saveOrderCallback={this.props.saveOrderCallback}/>;
    }


    return (
      <li key={eventDate.id} style={{'zIndex': this.props.index}}className={classNames.join(' ')}>
         <div className="send-on col-lg-1 col-1">
          <p className="month">{month(eventDate.send_on_date)}</p>
          <p className="day">{day(eventDate.send_on_date)}</p>
          <p className="year">{year(eventDate.send_on_date)}</p>
        </div>
        <div className="send-to col-lg-3 col-3">
            {status}

            <div className="send-to-details">to <b>{this.contactName}</b>
             <span className="occurrence-note">{occurrenceNumber}</span>
            </div>

        </div>
        <div className="col-lg-5 col-5">
       {ecardLink}
       </div>
       <div className="actions text-right col-lg-3 col-3">
        {applyTemplateForm}
        {holdLink}
        {editLink}
        {sendPreviewLink}
        {sendNowLink}
        {removeLink}
       </div>
      </li>
      )
  }
}

export default EventDatesTimelineEntry;