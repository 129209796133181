import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { emailIsValid } from '../utils/EmailValidator'
import { setKey } from '../utils/KeySetter'

class FixBounceForm extends Component {
  constructor(){
    super();
    this.state = {
      errors: ''
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(event){
    event.preventDefault();
    if(emailIsValid(this.refs.email.value)){


    var formData  = new FormData();
    formData.append('email', this.refs.email.value);


    return fetchAuthorized('/api/v1/ecard_analytics/'+this.props.secureCode+'/fix_and_resend/'+this.props.deliveryId,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveCallback();
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })


    } else{
      this.setState({errors: 'Please enter a valid email'})
    }
  }

  render() {



    return (
      <form>
        {this.state.errors}
          <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={this.props.email}/>
          </div>



          <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save and Resend</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}
export default FixBounceForm;










