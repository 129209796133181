import React, { Component } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import {Promise} from "bluebird";
import ErrorList from '../../generic/ErrorList';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'


class BillingUpdateForm extends Component {
  constructor(){
    super();
    this.state = {
      error: null,
      setError: null,
      cardUpdated: false,
      newSubscription: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stripePaymentMethodHandler = this.stripePaymentMethodHandler.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
  }

  // Handle real-time validation errors from the card Element.
  handleChange(event) {
    if (event.error) {
      this.setState({error: event.error.message});
    } else {
      this.setState({error: null});
    }
  }


  // Handle form submission.
  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    let elements = this.props.elements;
    let card = elements.getElement(CardElement);
    let stripe = this.props.stripe;
    let paymentMethodDetails = {
      type: 'card',
      card: card
    }

    Promise.resolve(stripe.createPaymentMethod(paymentMethodDetails)).then((response)=>{
      console.log(response);
      if (response.error) {
        console.log("ERROR");
        // Inform the user if there was an error.
        _this.setState({error: response.error.message});
      } else {
        console.log("NO ERROR");
        _this.setState({error: null});
        // Send the token to your server.
        _this.stripePaymentMethodHandler(response);
      }
    });
  };


  stripePaymentMethodHandler(response) {
    let _this = this;
    let formData = new FormData();
    formData.append('member[stripe_token]',response.paymentMethod.id);
    fetchAuthorized('/api/v1/members/'+this.props.sender.id+'/update_payment_method', {
      method: 'POST',
      body: formData
    }).then(result => {
      if(!result){
        console.log("NO RESULT< ERRRORS")
      } else{
        if(result.errors){
          _this.setState({error: result.errors})
        }else{
          console.log("stripePaymentMethodHandler - NO ERRORS");
          console.log(result);
          if(_this.props.sender.subscribed){
            _this.setState({cardUpdated: true})
          } else{
            _this.createSubscription(result, response.paymentMethod.id);
          }
        }

      }

   }).catch(err=>{
     _this.setState({error: err})
   })
  }

  createSubscription(response, paymentMethodId){
    let _this = this;
    let formData = new FormData();
    formData.append('member[stripe_token]',paymentMethodId);
    fetchAuthorized('/api/v1/members/'+_this.props.sender.id+'/create_subscription', {
      method: 'POST',
      body: formData
    }).then(result => {
      if(result.errors){
        this.setState({error: result.errors})
      }else{
        _this.setState({error: null});
        // Create Subscription
        _this.setState({newSubscription: true})
      }
   })
  }



  render() {
    // Custom styling can be passed to options when creating an Element.
    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };


    let errors = <ErrorList errors={this.state.error}/>

    let cardUpdated = "";
    let formClass = 'form-group';
    if(this.state.cardUpdated){
      cardUpdated = <div role="alert">Your card was updated!</div>
    } else if(this.state.newSubscription){
      cardUpdated = <div role="alert">Your subscription has started!</div>
      formClass = 'form-group hide-element';
    }

    let buttonText = 'Update Card';
    if(!this.props.sender.subscribed && !this.state.newSubscription){
      buttonText = 'Subscribe Now'
    }


    return (

      <form>
 {errors}
 {cardUpdated}

          <div className={formClass}>
        <label className="input-header">
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={this.handleChange}
        />
        </div>
        <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.handleSubmit} className="btn btn-space btn-primary btn-xl" type="submit">{buttonText}</button>
                </p>
              </div>
            </div>
          </div>
    </form>

      )
  }
}

export default BillingUpdateForm;
