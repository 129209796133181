import React, { Component } from 'react';
import {SendersContext} from '../contexts/senders-context';
import {CompanyContext} from '../contexts/company-context';
import AvatarImage from '../generic/AvatarImage';
import Select from 'react-select';

class ManagerNavSenderSelect extends Component {
  constructor(){
    super();
    this.setSenderOption = this.setSenderOption.bind(this);
  }

  setSenderOption(selectedOption){
    this.props.setSenderCallback({
      ids: selectedOption.value,
      options: selectedOption
    });
  }

  render() {
    if(!this.context.senders) return null;
    const senders = this.context.senders.map(sender => ({ value: sender.id, label: <AvatarImage email={sender.email} url={sender.avatar_url} label={sender.full_name}/> }))
    const customStyles = {
      option: (provided,  { data, isDisabled, isFocused, isSelected }) => {
        return {...provided,
        borderBottom: '1px dotted CCCCCC',
        padding: 10,
        color: isSelected ? 'white' : '#666'
      }
      },
      control: (provided) => {
        return {...provided,
        padding: 5}
      },
      singleValue: (provided, state) => {
        return { ...provided, padding: 5 };
      }
    }
    return (
      <div className="row">
        <div className="selector-label text-left">Select Your {this.context.company.sender_label}:</div>
        <Select value={this.props.defaultOptions} styles={customStyles} onChange={this.setSenderOption} options={senders} isMulti={this.props.isMulti} isSearchable={true} className="react-select-control"/>
      </div>
      )
  }
}
ManagerNavSenderSelect.contextType = CompanyContext;
ManagerNavSenderSelect.contextType = SendersContext;
export default ManagerNavSenderSelect;





