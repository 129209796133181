import React, { Component } from 'react';
import BillingUpdateForm from './BillingUpdateForm';
import {loadStripe} from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

class BillingPanel extends Component {
  constructor(){
    super();
  }

render() {
    const stripePromise = loadStripe('pk_test_3Dv62q58K4PO6J0BiNoUA0t7');
    let formDescription = 'Please update your credit card details.';
    if(!this.props.sender.subscribed){
      formDescription = 'Start your subscription now'
    }
return (

<div><div className="row">
           <div className="col-8 col-lg-8">
              <h2>Billing</h2>
              </div>

              </div>
              <div className="row">
               <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                {formDescription}

                <Elements stripe={stripePromise}>

                <ElementsConsumer>
                {({stripe, elements}) => (
                 <BillingUpdateForm stripe={stripe} elements={elements} sender={this.props.sender}/>
                 )}
                </ElementsConsumer>
                </Elements>
                </div>
              </div>
            </div></div>
            )
}
}

export default BillingPanel;

