import React, { Component } from 'react';
import Modal from './Modal';
import ContactFormNewContainer from '../pages/Contact/ContactFormNewContainer';
import ContactsImportFormContainer from '../pages/Contacts/ContactsImportFormContainer';
import { setKey } from '../utils/KeySetter'

class AddContactSplash extends Component {
  render() {
    return (
      <div className="col-10 col-lg-10 add-contact-dialog">
        <Modal title="New Contact" id="new-contact" iconClassName="mdi mdi-account">
          <ContactFormNewContainer key={setKey()} saveContactCallback={this.props.saveContactCallback} senderId={this.props.senderId}/>
        </Modal>

         <Modal title="Import Contact" id="import-contact" iconClassName="mdi mdi-account">
            <ContactsImportFormContainer saveContactCallback={this.props.saveContactCallback} senderId={this.props.senderId}/>
        </Modal>
        <div className="card text-center">
          <div className="card-header">
            <h2>You need to add contacts for this person first.</h2>
            <h3>How do you want to add your contacts?</h3>
          </div>
          <div className="card-body">
          <div className="row">
            <div className="add-contact-option float-left" data-toggle="modal" data-target="#new-contact">
             <div className="icon-container">
                  <div className="icon"><span className="mdi mdi-account-add"></span></div>
                  <span className="icon-class">Add One-By-One</span>
                </div>
            </div>
            <div className="add-contact-option float-right" data-toggle="modal" data-target="#import-contact">
             <div className="icon-container">
                  <div className="icon"><span className="mdi mdi-cloud-upload"></span></div>
                  <span className="icon-class">Import From a List</span>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      )
  }
}
export default AddContactSplash;







