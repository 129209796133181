import React, { Component } from 'react';

class Modal extends Component {
  constructor(){
    super();
    this.headerContent = '';
  }

  render() {
    if(this.props.title){
      let icon = this.props.iconClassName ? <span className={this.props.iconClassName}></span> : '';
      this.headerContent = <h1>{icon} {this.props.title}</h1>
    }
    let id = this.props.id ? this.props.id :  "edit-row";


    let dialogClassNames = (this.props.size=='small') ? 'modal-dialog small' : 'modal-dialog';

    return (
      <div className="modal fade" id={id} tabIndex="-1" role="dialog">
      <div className={dialogClassNames}>
        <div className="modal-content">
          <div className="modal-header modal-border">
            {this.headerContent}
            <button onClick={this.props.onClose} id="modal-close-btn" className="close modal-close-class" type="button" data-dismiss="modal" aria-hidden="true"><span className="mdi mdi-close-circle"></span></button>
          </div>
          <div className="modal-body">
              {this.props.children}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
    )
  }
}
export default Modal;










