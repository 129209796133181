import React, { Component } from 'react';
import ContactsImportStep1 from './ContactsImportStep1';
import ContactsImportStep2 from './ContactsImportStep2';
import ContactsImportStep3 from './ContactsImportStep3';
import ContactsImportFinished from './ContactsImportFinished';
import RedTailImportStep1 from './RedTailImportStep1';

class ContactsImportFormContainer extends Component {
  constructor(){
    super();
    this.state = {
      step: 1,
      inputMethod: 'file',
      workbook: {},
      list: {},
      result: {}
    }
    this.advanceToStep2 = this.advanceToStep2.bind(this);
    this.advanceToStep3 = this.advanceToStep3.bind(this);
    this.advanceToFinished = this.advanceToFinished.bind(this);
    this.resetSteps = this.resetSteps.bind(this);
    this.changeImportMethod =this.changeImportMethod.bind(this);
    }
    advanceToStep2(workbook={}){
      this.setState({
        step: 2,
        workbook: workbook,
        list: {},
        result: {}
      })
    }
    advanceToStep3(list={}){
      this.setState({
        step: 3,
        list: list,
        result: {}
      })
    }
    advanceToFinished(result={}){
      this.setState({
        step: 4,
        list: {},
        workbook: {},
        result: result
      })
    }
    changeImportMethod(type){
      this.setState({
        inputMethod: type
      },()=>{
        this.resetSteps()
      })
    }
    resetSteps(){
      new Promise((resolve, reject) => {
        resolve(
          this.setState({
            step: 1,
            workbook: {},
            list: {},
            result: {}
          })
        )
      }).then(() => this.state.inputMethod=='file' ? this.openDropZone() : true)
    }

    openDropZone() {
      this.refs.contactsImportStep1.triggerOpenDialog()
    }

  render() {
    let currentStep = "";
    let currentFooter = "";
    if(this.state.inputMethod =='file'){
      if(this.state.step === 1){
     currentStep = <ContactsImportStep1 ref="contactsImportStep1" onFinish={this.advanceToStep2}/>
     currentFooter = <button className="btn btn-space btn-secondary"><i className="icon icon-left mdi mdi-download"></i>Download Example File</button>
      }else if(this.state.step === 2){
      currentStep = <ContactsImportStep2 workbook={this.state.workbook} onFinish={this.advanceToStep3}/>
   } else if(this.state.step === 3){
       currentStep = <ContactsImportStep3 list={this.state.list} senderId={this.props.senderId} onFinish={this.advanceToFinished}/>
   } else if(this.state.step === 4){
      currentStep = <ContactsImportFinished onFinish={this.resetSteps} result={this.state.result}/>
   }
    } else if (this.state.inputMethod=='redtail'){
      if(this.state.step === 1){
        currentStep = <RedTailImportStep1 ref="redTailImportStep1" senderId={this.props.senderId} onFinish={this.advanceToFinished}/>
      } else if(this.state.step === 4){
        currentStep =<ContactsImportFinished onFinish={this.resetSteps} result={this.state.result}/>
      }
    }

    return (
      <div className="card">
       <div className="card-header">
        <div className="col-12 col-lg-12 text-left">
          <button className="btn btn-space btn-secondary btn-xl" onClick={()=>this.changeImportMethod('file')}><i className="icon icon-left mdi mdi-upload"></i>Upload from File</button>
        </div>
      </div>
        {currentStep}
        <div className="card-footer text-right">
            {currentFooter}
        </div>
      </div>
      )
  }
}
export default ContactsImportFormContainer;










