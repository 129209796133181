import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { emailIsValid } from '../../utils/EmailValidator'
import { setKey } from '../../utils/KeySetter'
import EventForm from './EventForm';
import { formattedSelect, formattedSingleSelect} from '../../utils/Helpers'
import CompanyAccountSelect from '../../generic/CompanyAccountSelect';
import SenderSelect from '../../generic/SenderSelect';

class ContactForm extends Component {
  constructor(){
    super();
     this.state = {
      senderIds: [],
      senderOptions: [],
      companyAccountId: null,
      companyAccountOptions: null,
      newCompanyAccount: null,
      categories: [],
      errors: null,
      complete: null,
      events: [],
      eventForms: [],
      selectedOption: null
    }
    this.setCompanyAccountId = this.setCompanyAccountId.bind(this);
    this.setSenderId = this.setSenderId.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.loadEventForm = this.loadEventForm.bind(this);
    this.updateEventObject = this.updateEventObject.bind(this);
    this.removeEventObject = this.removeEventObject.bind(this);
  }


  componentDidMount(){
    if(!this.state.categories.length){
      this.getCategories();
    }
    this.setSenderId();
    this.setCompanyAccountId();
  }

  setCompanyAccountId(val){
    console.log(val);
    let formattedVal = val ? val : formattedSingleSelect(this.props.contact, 'company_account');

    this.setState({
        companyAccountId: formattedVal.ids,
        companyAccountOptions: formattedVal.options,
        newCompanyAccount: formattedVal.options.__isNew__ ? formattedVal.ids : null
    },()=>console.log(this.state))
  }


  setSenderId(val){
    let formattedVal = val ? val : formattedSelect(this.props.contact, 'members');
    this.setState({
      senderIds: this.props.senderId ? this.props.senderId : formattedVal.ids,
      senderOptions: formattedVal.options
    },()=>console.log(this.state))
  }

  getCategories() {
    fetchAuthorized(generateUrl('/api/v1/categories', {type: 'connect', direction: 'asc', manual_sort_by: 'id',page: 1, per_page: 'All'})).then(categories => {
       if(categories.error || categories.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(categories.categories.length){
            this.setState({categories: categories.categories});
          }else{
            this.setState({categories: []});
          }
      }
      })
  }

  removeEventObject(eventId,eventFormArrayIndex){
    let events = [...this.state.events]
    let eventForms = [...this.state.eventForms]
    const index = this.state.events.findIndex(item => item.id === eventId);
    if(index !== -1){
      events.splice(index,1);
    }
    console.log(eventFormArrayIndex);
    console.log(eventId);
    eventForms.splice(eventFormArrayIndex, 1);
    this.setState({events: events, eventForms: eventForms},()=>console.log(this.state.events));
  }

  updateEventObject(event){
    console.log(event);
    let events = [...this.state.events]
    const index = this.state.events.findIndex(item => item.id === event.id);
    if(index !== -1){
      events[index] = event;
    }else{
      events.push(event);
    }
    this.setState({events: events},()=>console.log(this.state.events));
  }

  onFormSubmit(event){
    console.log("FOEM SUBMITTED");
    console.log(this.state)
    event.preventDefault();
    if(emailIsValid(this.refs.email.value)){
      console.log(this.refs.startdate);
      let formObj = {
        contact: {
          email: this.refs.email.value,
          title: this.refs.title.value,
          office: this.refs.office.value,
          first_name: this.refs.firstname.value,
          last_name: this.refs.lastname.value,
        },
        events: this.state.events,
        senderIds: this.state.senderIds
      }
      if(this.state.newCompanyAccount){
        formObj["newCompanyAccount"] = this.state.newCompanyAccount;
      } else{
        formObj["companyAccountId"] = this.state.companyAccountId;
      }
      this.props.onSubmitEvent(formObj);
    } else{
      this.setState({errors: 'Please enter a valid email'})
    }
  }

  loadEventForm(e){
    e.preventDefault();
    let key = setKey();
    let eventForms = [...this.state.eventForms]
    let arrayIndex = eventForms.length;
    eventForms.push(<EventForm onRemove={this.removeEventObject} updateEventObject={this.updateEventObject} key={key} id={key} arrayIndex={arrayIndex} categories={this.state.categories}/>)
    console.log(eventForms.length);
    console.log(arrayIndex);

    this.setState({
      eventForms: eventForms
    })
  }


  render() {

    let contact = this.props.contact ? this.props.contact : {};
    let errors = '';
    let completed = '';

    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    } else if(this.props.errors){
      errors = []
      for (var key in this.props.errors) {
        errors.push(<div className="alert alert-danger">{key} {this.props.errors[key]}</div>)
      }
    }

    if(this.props.completed){
      completed = <div className="alert alert-success">{this.props.completed}</div>
    }

    let eventForm = '';
    if(!this.props.contact){
      eventForm = <div className="form-group row">
            <div className="col-lg-12 col-12">
            <button className="btn btn-secondary btn-xl" onClick={this.loadEventForm}><i className="mdi mdi-plus"></i> Add An Event</button>
            </div>
          </div>
    }
    let companyAccountSelector = <CompanyAccountSelect defaultOptions={this.state.companyAccountOptions} setCompanyAccountCallback={this.setCompanyAccountId}/>
    let senderSelector = '';
    if(!this.props.senderId){
      senderSelector = <SenderSelect key={setKey} setSenderCallback={this.setSenderId} defaultOptions={this.state.senderOptions} isMulti={true}/>
    }

    return (
      <form>
        {errors}
        {completed}

          <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={contact.email}/>
          </div>

          <div className="row col-12">
            <div className="col-6 form-group">
            <label className="input-header">First Name:</label>
            <input ref="firstname" className="form-control" id="email" type="text" placeholder="Enter First Name" defaultValue={contact.first_name}/>
            </div>
            <div className="col-6 form-group">
            <label className="input-header">Last Name:</label>
            <input ref="lastname" className="form-control" id="email" type="text" placeholder="Enter Last Name" defaultValue={contact.last_name}/>
            </div>
          </div>

          <div className="form-group">
            <label className="input-header">Title:</label>
            <input ref="title" className="form-control" id="title" type="text" placeholder="Enter Title" defaultValue={contact.title}/>
          </div>


          <div className="form-group">
            <label className="input-header">Office:</label>
            <input ref="office" className="form-control" id="office" type="text" placeholder="Enter Office" defaultValue={contact.office}/>
          </div>


          {companyAccountSelector}
          {senderSelector}
          {this.state.eventForms}
          {eventForm}

          <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}
export default ContactForm;










