import React, { Component } from 'react';
import pluralize from 'pluralize';
import AdminNav from '../nav/AdminNav';
import Search from '../generic/Search';
import SectionHeader from '../generic/SectionHeader';
import Modal from '../generic/Modal';
import SendersTable from './Admin/SendersTable';
import SenderFormNewContainer from './Admin/SenderFormNewContainer';
import {CompanyContext} from '../contexts/company-context';
import { setKey } from '../utils/KeySetter'

class AdminSenders extends Component {
  constructor(){
    super();
    this.state ={
      senderCreated: false
    }
    this.resetState = this.resetState.bind(this);
    this.addSenderToTable = this.addSenderToTable.bind(this);
  }
  addSenderToTable(refs){
    this.setState({senderCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  resetState(){
    if(this.state.senderCreated){
      this.setState({senderCreated: false});
    }
  }

  render() {
    let sectionHeaderLabel = 'Your '+pluralize(this.context.company.sender_label);
    return (
      <div className="senders be-wrapper be-nosidebar-left">
      <AdminNav/>
        <div className="be-content">
        <Modal title="New Sender" id="new-sender" iconClassName="mdi mdi-account">
          <SenderFormNewContainer key={setKey()} saveSenderCallback={this.addSenderToTable}/>
        </Modal>
        <Modal title="Import Senders" id="import-senders" iconClassName="mdi mdi-account"/>
          <SectionHeader label={sectionHeaderLabel} newModalTarget='#new-sender' importModalTarget='#import-senders'/>
          <div className="senders-content main-content container-fluid main-content-padding">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="top-search float-right col-4 col-lg-4">
                  <div className="form-group row">
                    <Search/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="splash-content-tabs tab-container">
                  <SendersTable senderCreated={this.state.senderCreated} onSendersLoaded={this.resetState}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminSenders.contextType = CompanyContext;
export default AdminSenders;
