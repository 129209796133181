import React, { Component } from 'react';

class TableColumnHeader extends Component {
  constructor(){
    super();
    this.defaultClassNames = []
    this.handleClick = this.handleClick.bind(this);
  }
  setSortDirection(){
    let sortDirection = 'asc';
    if(this.props.sortDirection){
      sortDirection = this.props.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    return sortDirection;
  }
  handleClick(){
    if(!this.props.sortable) return false;
    this.props.onSort(this.props.sortApiColumn,this.setSortDirection());
  }
  setClassNames(){
    let classNames = [...this.defaultClassNames];
    if(this.props.sortable) classNames.push('sorting');
    if(this.props.sortDirection) classNames.push('sorting-'+this.props.sortDirection);
    return classNames;
  }
  render() {
    return (
      <th onClick={this.handleClick} className={this.setClassNames().join(' ')} key={this.props.name}>{this.props.name}</th>
    )
  }
}
export default TableColumnHeader;




