 import React, { Component } from 'react';

class PhotoField extends Component {
  constructor(){
    super();
    this.state ={
      photoRemoved: false
    }
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  onChangeEvent(e){
    this.props.onChange(e.target.files[0]);
  }
  removePhoto(){
    this.setState({photoRemoved: true})
    this.props.onChange(null);
  }

  render(){

    let defaultField = <input type="file" name="file" onChange={this.onChangeEvent}/>
    if(this.props.defaultValue && !this.state.photoRemoved){
      defaultField = <div><img src={this.props.defaultValue}/> <a onClick={this.removePhoto}>Remove</a></div>
    }
    return (
       <div className="form-group">
          <label className="input-header">{this.props.fieldLabel}</label>
           {defaultField}
          </div>
      )
  }
}
export default PhotoField;










