import React, { Component } from 'react';

class Search extends Component {
  constructor(){
    super();
    this.state = {
        inputValue: null
    }
    this.performSearch = this.performSearch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    let inputValue = e.target.value.replace(/\W/g, '');
    this.setState({ inputValue: inputValue });
  };

  performSearch() {
    if(!this.state.inputValue || this.state.inputValue.length < 2) return true;
    this.props.searchCallback(this.state.inputValue);
  }


  render() {
    return (
      <div className="input-group mb-3">
      <input className="form-control" onChange={(e) => this.handleInputChange(e)} type="text" placeholder="Search..." value={this.state.inputValue}/>
      <div className="input-group-append">
      <button className="btn btn-secondary active" type="button" onClick={this.performSearch}><span className="mdi icon mdi-search"/></button>
      </div>
      </div>
      )
  }
}
export default Search;









