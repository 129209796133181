import React, { Component } from 'react';

class TablePaginationButton extends Component {
  constructor(){
    super();
    this.defaultClassNames = ['paginate_button', 'page-item']
    this.handleClick = this.handleClick.bind(this);
  }

  setClassNames(){
    let classNames = [...this.defaultClassNames];
    if(this.props.isDisabled) classNames.push('disabled');
    if(this.props.isPrevious) classNames.push('previous');
    if(this.props.isNext) classNames.push('next');
    if(this.props.isActive) classNames.push('active');
    return classNames;
  }

  setText(){
    let defaultText = this.props.pageNumber;
    if(this.props.isPrevious) defaultText = <span className="icon mdi mdi-chevron-left"/>;
    if(this.props.isNext) defaultText =  <span className="icon mdi mdi-chevron-right"/>;
    return defaultText;
  }

  handleClick(e){
    e.preventDefault();
    this.props.onChangePage(this.props.pageNumber);
  }

  render() {

    return (
        <li className={this.setClassNames().join(' ')}>
          <a href="#" id={this.props.pageNumber} onClick={this.handleClick} className="page-link">
            {this.setText()}
          </a>
        </li>
      )
  }
}
export default TablePaginationButton;