import React, { Component } from 'react';
import TablePaginationContainer from './TablePaginationContainer'
import OrderItemFormEditContainer from './OrderItemFormEditContainer';
import OrderFormNew from './OrderFormNew';
import Modal from './Modal';
import ContactSelect from './ContactSelect'
import AddEventSplash from './AddEventSplash';
import SendPreviewForm from './SendPreviewForm';
import FilterByDateTime from './FilterByDateTime';
import SortByDateStatus from './SortByDateStatus';
import EventTypeNav from './EventTypeNav'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { now,today,formatMomentForStartEndDate } from '../utils/DateFormatter';
import { setKey } from '../utils/KeySetter';



class EventDatesTimeline extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/event_dates';
    this.state = {
      eventDateId: null,
      contactId: null,
      contactOptions: {label: 'Filter By Contact...', value: ''},
      eventTypeId: null,
      eventTypeOptions: null,
      eventDates: [],
      newEvent: false,
      startDate: formatMomentForStartEndDate(today()),
      endDate: formatMomentForStartEndDate(today()),
      sortAttribute: 'date',
      breakdown: [],
      showSendPreviewDialog: false,
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }

    };
    this.getEventDates = this.getEventDates.bind(this);
    this.loadEventDate = this.loadEventDate.bind(this);
    this.duplicateEventDateState = this.duplicateEventDateState.bind(this);
    this.updateEventDateState = this.updateEventDateState.bind(this);
    this.toggleEventDateState = this.toggleEventDateState.bind(this);
    this.createEventDateOrderState = this.createEventDateOrderState.bind(this);
    this.removeEventDate = this.removeEventDate.bind(this);
    this.sendNow = this.sendNow.bind(this);
    this.loadNewEventDate = this.loadNewEventDate.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.updateStartEndDate = this.updateStartEndDate.bind(this);
    this.updateSortAttribute = this.updateSortAttribute.bind(this);
    this.getEventDateStatus = this.getEventDateStatus.bind(this);
    this.organizeByStatus = this.organizeByStatus.bind(this);
    this.setEventTypeId = this.setEventTypeId.bind(this);
    this.setContactId = this.setContactId.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);

  }

  componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('contact')){
      this.setContactId({ids: urlParams.get('contact'),contactOptions: {} });
    }
    this.getEventDates();
  }

  setContactId(val){
    let formattedVal = val ? val : {ids: null, options: {}}
    this.setState({
      contactId: formattedVal.ids,
      contactOptions: formattedVal.options
    },()=>this.getEventDates());
  }

  setEventTypeId(val){
    let formattedVal = val ? val : {ids: '', options: ''}
    this.setState({
        eventTypeId: formattedVal.ids,
        eventTypeOptions: formattedVal.options,
        eventDateId: null
    },()=>this.getEventDates({skipBreakdown: true}))
  }

  updateStartEndDate(dateRange, options={}){
    let paginationData = Object.assign({},this.state.paginationData);
    let stateVals = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      paginationData: paginationData,
      eventTypeId: null,
      eventTypeOptions: null
    }

    if(options.showAll){
      stateVals.paginationData.currentPage =  1;
      stateVals.paginationData.entryLength = 'all';
    } else{
      stateVals.paginationData.currentPage =  1;
      stateVals.paginationData.entryLength = 25;
    }
    this.setState(stateVals,()=>this.getEventDates());
  }

  updateSortAttribute(attr){
    this.setState({
      sortAttribute: attr
    },()=>this.getEventDates({skipBreakdown: true}));
  }

  loadEventDate(eventDateId,showPreview=false){
    this.setState({
      eventDateId: eventDateId,
      showSendPreviewDialog: showPreview
    })
  }

  loadNewEventDate(eventDate){
    let dup = this.duplicateEventDateState();
    let newBreakdown = [...this.state.breakdown];
    let newEventTypeId  = eventDate.eventTypeId;
    newBreakdown.map((item)=>{
      if(item.id == newEventTypeId){
        item.count = item.count+1
      }
    })
    dup.eventDates.push(eventDate);
    console.log(eventDate);
    this.setState({
      eventDates: dup.eventDates,
      eventDateId: eventDate.id,
      breakdown: newBreakdown
    },()=>this.state)
  }

  removeEventDate(eventDateId){
    if(window.confirm('Are you sure you want to remove this event date?')){
      fetchAuthorized(this.baseUrl+'/'+eventDateId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          eventDateId: eventDateId
          },()=>this.removeEventDateState())
      })
    }
  }

  sendNow(eventDateId){
    if(window.confirm('This will send your e card right away.  Are you sure you want to send now?')){
      fetchAuthorized(this.baseUrl+'/'+eventDateId+'/send_now',{}).then(resp=>{
        this.setState({
          eventDateId: eventDateId
          },()=>this.removeEventDateState())
      })
    }
  }

  currentEventDate(){
    let dup = this.duplicateEventDateState();
    return dup.eventDates[dup.index];
  }

  removeEventDateState(){
    let dup = this.duplicateEventDateState();
    let filteredEventDates = dup.eventDates.slice(0, dup.index).concat(dup.eventDates.slice(dup.index + 1, dup.eventDates.length))
    this.setState({eventDateId: null, eventDates: filteredEventDates},()=>console.log(this.state.eventDates));
  }

  duplicateEventDateState(){
    const index = this.state.eventDates.findIndex(item => item.id === this.state.eventDateId);
    let eventDates = [...this.state.eventDates] // important to create a copy, otherwise you'll modify state outside of setState call
    let eventDateDup = Object.assign({},this.state.eventDates[index]);
    return {index: index, eventDate: eventDateDup, eventDates: eventDates}
  }

  toggleEventDateState(eventDateId, attr){
    const index = this.state.eventDates.findIndex(item => item.id === eventDateId);
    let eventDates = [...this.state.eventDates]
    let eventDateDup = Object.assign({},this.state.eventDates[index]);
    let attrList = attr.split(".");

    if(attrList.length === 3){
      let existingVal = eventDateDup[attrList[0]][attrList[1]][attrList[2]];
      eventDateDup[attrList[0]][attrList[1]][attrList[2]] = existingVal === false ? true : false;
    } else if(attrList.length === 2){
      let existingVal = eventDateDup[attrList[0]][attrList[1]];
      eventDateDup[attrList[0]][attrList[1]] = existingVal === false ? true : false;
    }else{
      let existingVal = eventDateDup[attrList[0]];
      eventDateDup[attrList[0]] = existingVal === false ? true : false;
    }

    eventDates[index] = eventDateDup;
    this.setState({eventDates: eventDates});
  }

  updateEventDateState(){
    let dup = this.duplicateEventDateState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.eventDateId, { type: 'connect'})).then(eventDate => {
      dup.eventDates[dup.index] = eventDate.event_date;
      this.setState({eventDateId: null, eventDates: dup.eventDates},()=>console.log(this.state.eventDates));
      let els = document.getElementsByClassName("modal-close-class");
      for (let item of els) { item.click(); }
    });
  }

  createEventDateOrderState(order){
    let dup = this.duplicateEventDateState();
    dup.eventDate.order = order;
    dup.eventDates[dup.index] = dup.eventDate;
    console.log("CALLBACK - createEventDateOrderState");
    console.log(dup.eventDate);

    this.setState({eventDateId: dup.eventDate.id, eventDates: dup.eventDates});
  }

  organizeByStatus(){
    if(this.state.sortAttribute!=='status') return;
    let groupings = {'scheduled':[],'held':[],'not-ready':[]};
    let newEventDateArr = [];
    let dup = this.duplicateEventDateState();
    dup.eventDates.map((eventDate)=>{
      let status = this.getEventDateStatus(eventDate);
      groupings[status].push(eventDate);
    });
    for (var property in groupings) {
      if (groupings.hasOwnProperty(property)) newEventDateArr.push(groupings[property]);
    }
    /**/
    this.setState({
      eventDates: [...newEventDateArr.flat()]
    })
  }

  getEventDateStatus(eventDate){
    if(!eventDate.order || !eventDate.order.order_item) return 'not-ready';
    return eventDate.order.order_item.held ? 'held' : 'scheduled';
  }

  setParams(){
    return {
      history: false,
      type: 'connect',
      direction: 'asc',
      manual_sort_by: 'event_dates.send_on_date',
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }

  getEventDates(options={}) {
    let params = Object.assign(this.props.eventDateParams, this.setParams());
    if(this.state.startDate) params['start_date'] = this.state.startDate;
    if(this.state.endDate) params['end_date'] = this.state.endDate;
    if(this.state.contactId) params['contact_id'] = this.state.contactId;
    console.log("getEventDates");
    console.log(this.state.eventTypeId);
    console.log(params);
    if(this.state.eventTypeId) {
      params['event_type_id'] = this.state.eventTypeId;
    } else{
      delete params['event_type_id'];
    }
    console.log(params);
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(eventDates => {
       console.log(eventDates);
        if(eventDates.error || eventDates.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(eventDates.event_dates.length){
          let paginationData = Object.assign({}, this.state.paginationData);
          paginationData.totalPages =  parseInt(eventDates.meta.total_pages);
          paginationData.totalItems =  parseInt(eventDates.meta.total_items);
          paginationData.currentPage = parseInt(eventDates.meta.page);
          let stateHash = { eventDates: eventDates.event_dates, paginationData: paginationData}
          if(!options.skipBreakdown){
            stateHash.breakdown = eventDates.breakdown
          }
          this.setState(stateHash,()=>this.organizeByStatus());
        }else{
          this.setState({eventDates: []});
        }
      }
    }).catch(error=>{console.log(error)})
  }

  onModalClose(){
    this.setState({eventDateId: null,showSendPreviewDialog: false})
  }


  setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({eventDateId: null,paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({eventDateId: null,paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  render() {

    /* modal data - create event but also edits */
    let modalTitle = 'Create Your Event';
    let modalContents = <AddEventSplash key={setKey('new-event-'+this.props.eventDateParams.sender_id)} senderId={this.props.eventDateParams.sender_id} saveEventCallback={this.loadNewEventDate}/>
    let modalSize = 'small';

    if(this.state.eventDateId){
      let currentEventDate = this.currentEventDate();
      if(currentEventDate.order){
        modalSize = 'big';
        modalTitle = 'Edit Your Send';
        modalContents = <OrderItemFormEditContainer key={setKey('edit-'+this.state.eventDateId)} sender={this.props.sender} saveOrderItemCallback={this.updateEventDateState} eventDate={currentEventDate} orderItem={currentEventDate.order.order_item}/>
      } else{
        modalSize = 'big';
        modalTitle = 'Add Your Send';
        modalContents = <OrderFormNew key={setKey('new-'+this.state.eventDateId)} saveOrderCallback={this.createEventDateOrderState} eventDate={currentEventDate} sender={this.props.sender}/>
      }
      if(this.state.showSendPreviewDialog){
       modalTitle = 'Send Your Preview';
       modalContents = <SendPreviewForm key={setKey('edit-'+this.state.eventDateId)} eventDate={currentEventDate}/>;
       modalSize = 'small';
     }
    }
    let modal = <Modal title={modalTitle} size={modalSize} id="eventDateTimelineModal" onClose={this.onModalClose} iconClassName="mdi mdi-account">{modalContents}</Modal>;


    /* table contents and sorting */
    let eventDateTimelineEntry = this.state.eventDates.map((eventDate,i)=>{
      let classNames = i===0  ? ['active'] :  [];
      let rowComponentProps = {key: eventDate.id, index: (this.state.eventDates.length - i), saveOrderCallback: this.createEventDateOrderState, sender: this.props.sender, classNames: classNames, loadEventDate: this.loadEventDate, removeEventDate: this.removeEventDate, sendNow: this.sendNow, eventDate: eventDate, rowToggleCallback: this.toggleEventDateState }
      return React.cloneElement(this.props.rowComponent, rowComponentProps);
    })

    let eventDateTimeline = ''
    let dateFilter = '';
    let sortList = '';
    let eventTypeNav = '';
    let contactList = '';
    let paginationSection = <TablePaginationContainer setPage={this.setPage}
                                      paginationData = {this.state.paginationData}
                                      setEntryLength={this.setEntryLength}/>

    if(this.state.contactId || this.state.eventTypeId) {
      eventTypeNav = <EventTypeNav eventTypeId={this.state.eventTypeId} senderId={this.props.eventDateParams.sender_id} setEventTypeCallback={this.setEventTypeId} breakdown={this.state.breakdown}/>
    }

    if(this.state.eventDates.length > 0 || this.props.totalEvents > 0){
      dateFilter = <FilterByDateTime onClick={this.updateStartEndDate} period="day" startDate={this.state.startDate} />;
      contactList = <div className="contact-select-container"><ContactSelect senderId={this.props.eventDateParams.sender_id} setContactCallback={this.setContactId} defaultOptions={this.state.contactOptions}/></div>
    }

    if(this.state.eventDates.length > 0){
      sortList = <SortByDateStatus onOptionClick={this.updateSortAttribute} options={['date','status']}/>
      eventTypeNav = <EventTypeNav eventTypeId={this.state.eventTypeId} senderId={this.props.eventDateParams.sender_id} setEventTypeCallback={this.setEventTypeId} breakdown={this.state.breakdown}/>
      eventDateTimeline = <ul className="eventDateTimeline user-timeline user-timeline-compact">
               {eventDateTimelineEntry}
           </ul>
    } else if(this.props.totalEvents > 0){
      eventDateTimeline = <div className="clear-fix">
        <div className="col-10 col-lg-10 add-contact-dialog">
        <h3>You do not have any sends for this period.</h3>
        </div>
        </div>
    } else{
      eventDateTimeline = <div className="clear-fix">
        <div className="col-10 col-lg-10 add-contact-dialog">

          <div className="card text-center">
            <div className="card-header">
             <h2>You've added your contacts. Great job!</h2>
             <h2>Now let's add some events.</h2>
            </div>
            <div className="card-body">
              <div className="event-form">
                <button data-toggle="modal" data-target="#eventDateTimelineModal" data-backdrop="static" data-keyboard="false" className='btn btn-primary create-event'>Click here to get started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }


    return (
      <div>
        {modal}
        {dateFilter}
        <div className="row">
          <div className="col-lg-10 col-10">
          {eventTypeNav}
          </div>
          {sortList}
        </div>
        {eventDateTimeline}
        {paginationSection}
      </div>
    )
  }
}

export default EventDatesTimeline;








