import React, { Component } from 'react';
import { setKey } from '../utils/KeySetter'

class ErrorList extends Component {
  constructor(){
    super();
  }


  render() {
    if(!this.props.errors) return null;
    let errorArray = "";
    if(typeof this.props.errors === 'object'){
      errorArray = Object.entries(this.props.errors).map(([key, value]) => {
        let displayValue = value[0];
        if(key=="email" && displayValue=="has already been taken"){
          return <div key={setKey(key)} role="alert">{key} {displayValue}. <a href="/">Login here.</a></div>
        } else{
          return <div key={setKey(key)} role="alert">{key} {value[0]}</div>
        }

      })
  } else{
    errorArray = <div role="alert">{this.props.errors}</div>
  }

    return (
      <div  className="card">
          {errorArray}
        </div>

      )
  }
}
export default ErrorList;





