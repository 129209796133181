import React, { Component } from 'react';
import EventTypeSelect from "../../generic/EventTypeSelect";
import EcardSelect from "../../generic/EcardSelect";
import RecurrenceSelect from "../../generic/RecurrenceSelect";
import EcardPreview from "../../generic/EcardPreview";
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'
import { formattedSelect, formattedSingleSelect} from '../../utils/Helpers'
import { setKey } from '../../utils/KeySetter'
import Select from 'react-select';

class EventTypeTemplateForm extends Component {
  constructor(){
    super();
     this.state = {
      errors: null,
      eventTypeId: null,
      eventTypeOptions: null,
      textAreaValue: '',
      currentEcard: null,
      recurrenceYear: null
    }
    this.setRecurrenceYear = this.setRecurrenceYear.bind(this);
    this.setEventTypeId = this.setEventTypeId.bind(this);
    this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.setSelection = this.setSelection.bind(this);
  }

  componentDidMount(){
    this.setState({
      recurrenceYear: this.props.eventTypeTemplate ? this.props.eventTypeTemplate.recurrence_year : null,
      textAreaValue: this.props.eventTypeTemplate ? this.props.eventTypeTemplate.email_message : ''
    },()=>this.setEventTypeId());

  }

  setEventTypeId(val){
    console.log(val);
    let formattedVal = val ? val : formattedSingleSelect(this.props.eventTypeTemplate, 'event_type');
    this.setState({
        eventTypeId: formattedVal.ids,
        eventTypeOptions: formattedVal.options
    })
  }


  handleTextAreaChange(event){
    this.setState({
      textAreaValue: event.target.value
    })

  }
  setSelection(ecard){
    console.log(ecard)
    this.setState({
      currentEcard: ecard
    })
  }
  setRecurrenceYear(year){
    this.setState({
      recurrenceYear: year.ids
    })
  }


  onFormSubmit(event){
    console.log("FORM SUBMITTED");
    console.log(this.state)
    event.preventDefault();
      console.log(this.refs.subject.value);
      let formObj = {
        eventTypeTemplate: {
          subject: this.refs.subject.value,
          event_type_id: this.state.eventTypeId,
          email_message: this.state.textAreaValue,
          member_id: this.props.senderId,
          recurrence_year: this.state.recurrenceYear
        },
      }
      if(this.props.eventTypeTemplate){
        formObj.eventTypeTemplate.ecard_id = this.state.currentEcard ? this.state.currentEcard.id : this.props.eventTypeTemplate.source_id
      } else{
        if(this.state.currentEcard) formObj.eventTypeTemplate.ecard_id = this.state.currentEcard.id;
      }
      this.props.onSubmitEvent(formObj);
  }


  render() {

    let eventTypeSelector = <EventTypeSelect defaultOptions={this.state.eventTypeOptions} setEventTypeCallback={this.setEventTypeId}/>
    let eventTypeTemplate = this.props.eventTypeTemplate ? this.props.eventTypeTemplate : {};
    let subject = this.props.eventTypeTemplate ? this.props.eventTypeTemplate.subject : null
    let currentEcardId = this.props.eventTypeTemplate ? this.props.eventTypeTemplate.source_id : null
    let errors = '';
    if(this.state.errors){
      errors = <div className="alert alert-danger">{this.state.errors}</div>
    }
    let recurrence = "";
    if(this.state.eventTypeId && this.state.eventTypeId==2){
      recurrence = <RecurrenceSelect setRecurrenceCallback={this.setRecurrenceYear}/>
    }
    let personalMessage = '';
      personalMessage = (<div className="row col-12">
           <div className="col-lg-12 col-12">
            <label className="input-header">Personal Message</label>
            <textarea ref="emailMessage"  className="form-control" name="description" type="text"  id="emailMessage"  value={this.state.textAreaValue} onChange={this.handleTextAreaChange}/>
          </div>
           </div>)
    return (
      <form className="event-type-template-form">
        {errors}
          <div className="row col-12">
           <div className="col-lg-6 col-6">
            {eventTypeSelector}
           </div>
            <div className="col-6 form-group">
            <label className="input-header">Subject Line:</label>
            <input ref="subject" className="form-control" name="subject" defaultValue={subject}/>
            </div>
          </div>




          {recurrence}
            {personalMessage}
            <div className="row col-12">
              <div className="col-lg-6 col-6 event-type-template ecard-selector">
                <label className="input-header">Select Your E Card</label>
                <EcardSelect showEcardLabel={true} onEcardSelected={this.setSelection} classNamePrefixes="col-4 col-lg-4 thumbnail" currentEcardId={currentEcardId}/>
              </div>
              <div className="col-lg-6 col-6 ecard-preview">
                <label className="input-header">&nbsp;</label>
                <EcardPreview playerHeight='252px' playerWidth='432px' currentEcard={this.state.currentEcard}/>
              </div>
            </div>
           <div className="row col-12">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}
export default EventTypeTemplateForm;










