import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class NavHeader extends Component {
  render() {
   return (
      <div className="be-navbar-header">
      <Link to='/' className='navbar-brand' style={{backgroundImage: `url(${this.props.imageUrl})`}}/>
      </div>
    )
  }
}

export default NavHeader;

