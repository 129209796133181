import React, { Component } from 'react';

import AvatarImage from '../../generic/AvatarImage';
import IdLink from '../../generic/IdLink';
import EcardThumbnail from '../../generic/EcardThumbnail';
import ChooseEcard from '../../generic/ChooseEcard';
import { formattedDate } from '../../utils/DateFormatter';

class ManagerEventDatesTableRow extends Component {
  constructor(){
    super();
    this.managerName = 'No Name Given';
    this.contactId = '';
    this.contactEmail = '';
    this.contactAvatar = null;
    this.contactName = 'No Name Given';
    this.locations = 'No Office Assigned';
    this.groups = 'None Assigned';
    this.managers = 'None Assigned';

  }

  render() {
    let eventDate = this.props.eventDate;

    if(eventDate.contact!=null){
      this.contactName = eventDate.contact.full_name
      this.contactId = eventDate.contact.id
      this.contactEmail = eventDate.contact.email
      this.contactAvatar = eventDate.contact.avatar_url
    }

    let ecardLink = "";
    if(eventDate.order){
      ecardLink = <EcardThumbnail ecardId={eventDate.order.order_item.ecard_id} message={eventDate.order.order_item.message}/>
    } else{
      ecardLink = <ChooseEcard loadEventDate={this.props.loadEventDate} eventDate={eventDate}/>
    }

    let editLink="";
    if(eventDate.order){
      editLink =  <button onClick={()=>this.props.loadEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" data-toggle="modal" data-target="#edit-row" type="button">
              <i className="mdi mdi-edit"></i>
            </button>
    }
    let removeLink = <button onClick={()=>this.props.removeEventDate(eventDate.id)} className="icon btn btn-space btn-secondary" type="button">
              <i className="mdi mdi-delete"></i>
            </button>

    return (
      <tr key={eventDate.id}>
        <td className="hidden">{eventDate.id}</td>
        <td>
          <div className="recipient">
            <AvatarImage email={this.contactEmail} url={this.contactAvatar}/>
            <IdLink label={this.contactName} id={this.contactId} className='name' baseUrl='/contacts/'/>
            <div className="stars">
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star"/>
              <span className="icon mdi mdi-star-half"/>
            </div>
          </div>
        </td>
        <td>{formattedDate(this.props.eventDate.send_on_date)}</td>
        <td>
          {ecardLink}
        </td>
        <td>
          <div className="actions">
          {editLink}
          {removeLink}
          </div>
        </td>
      </tr>
      )
  }
}

export default ManagerEventDatesTableRow;