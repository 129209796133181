import React, { Component } from 'react';
import TableColumnHeader from '../../generic/TableColumnHeader'
import TablePaginationContainer from '../../generic/TablePaginationContainer'
import Modal from '../../generic/Modal';
import ContactsTableRow from './ContactsTableRow'
import ContactFormEditContainer from '../Contact/ContactFormEditContainer'
import EventFormNewContainer from '../Contact/EventFormNewContainer';
import Search from '../../generic/Search';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ContactsTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/contacts';
    this.state = {
      contactId: null,
      contacts: [],
      showEventForm: null,
      query: '',
      contactColumns: [
      { name: 'Contact Name', sortApiColumn: 'contacts.last_name', sortable: true },
      { name: 'Contact Email', sortApiColumn: 'contacts.email', sortable: true },
      { name: 'Company', sortApiColumn: 'contacts.company_account_id', sortable: true },
      { name: 'Relationship Strength',sortApiColumn: 'contacts.relationship_strength', sortable: true},
      { name: 'Upcoming Send', sortApiColumn: 'contacts.created_at', sortable: true },
      { name: '' }
      ],
      currentSortColumn: 'contacts.email',
      currentSortDirection: 'asc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getContacts = this.getContacts.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadContact = this.loadContact.bind(this);
    this.loadEventForm = this.loadEventForm.bind(this);
    this.enrichContact = this.enrichContact.bind(this);
    this.updateContactState = this.updateContactState.bind(this);
    this.duplicateContactState = this.duplicateContactState.bind(this);
    this.removeContactState = this.removeContactState.bind(this);
    this.removeContact = this.removeContact.bind(this);
    this.addEventToTable =this.addEventToTable.bind(this);
    this.searchContacts = this.searchContacts.bind(this);
  }

  componentDidMount() {
    this.getContacts();
  }
  componentDidUpdate() {
    if(this.props.contactCreated){
      this.getContacts();
    }
  }



  loadContact(contactId){
    this.setState({
      contactId: contactId,
      showEventForm: null
    })
  }

  loadEventForm(contactId){
    let _this = this;
    console.log("in loaded EVENT FORM");
    _this.setState({
      showEventForm: false
    },()=>{
      _this.setState({
        contactId: contactId,
        showEventForm: true
      })
    })
  }

  addEventToTable(){
    console.log('EVENT ADDED');
    document.getElementById('toggle-row-'+this.state.contactId).click();
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    document.getElementById('toggle-row-'+this.state.contactId).click();

  }

  enrichContact(contactId){
    fetchAuthorized(generateUrl(this.baseUrl+"/"+contactId+"/enrich",{})).then(contact => {
      console.log(contact);
    })
  }

  removeContact(contactId){
    if(window.confirm('Are you sure you want to remove this contact?')){
      fetchAuthorized(this.baseUrl+'/'+contactId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          contactId: contactId
          },()=>this.removeContactState())
      })
    }
  }

  duplicateContactState(){
    const index = this.state.contacts.findIndex(item => item.id === this.state.contactId);
    let contacts = [...this.state.contacts] // important to create a copy, otherwise you'll modify state outside of setState call
    let contactDup = Object.assign({},this.state.contacts[index]);
    return {index: index, contact: contactDup, contacts: contacts}
  }

  currentContact(){
    let dup = this.duplicateContactState();
    return dup.contacts[dup.index];
  }

  // called from UpdateContactForm Container component after the api is updated
  // sent to the comonent as saveContactCallback
  updateContactState(){
    let dup = this.duplicateContactState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.contactId, { type: 'connect'})).then(contact => {
      dup.contacts[dup.index] = contact.contact;
      this.setState({contactId: null, contacts: dup.contacts});
      Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    });
  }

  removeContactState(){
    let dup = this.duplicateContactState();
    let filteredContacts = dup.contacts.slice(0, dup.index).concat(dup.contacts.slice(dup.index + 1, dup.contacts.length))
    this.setState({contactsId: null, contacts: filteredContacts},()=>console.log(this.state.contacts));
  }

 setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getContacts();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getContacts();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getContacts();
    })
  }


  /*
  http://localhost:3000/api/v1/contacts?
        by_status=
        &direction=desc
        &end_date=2019-01-16
        &items_type=Electronic
        &not_archived=true
        &page=1
        &per_page=10
        &production_only=true
        &q=
        &sort=placedAt
        &start_date=2019-01-01
        &type=
  */

  setParams(){
    return {
      type: 'connect',
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase(),
      query: this.state.query
    }
  }

  searchContacts(val){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage=1;

    let _this = this;
    _this.setState({
      paginationData: paginationData,
      query: val
    },()=>{
      _this.getContacts();
    })
  }

  getContacts() {
    let params = this.setParams();
    if(this.props.senderId) params['sender_id'] = this.props.senderId;
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(contacts => {
       console.log(contacts);
        if(contacts.error || contacts.errors){
          console.log('UNAUTHORIZED');
        }else{
         let paginationData = Object.assign({}, this.state.paginationData);
         if(contacts.contacts.length){
          paginationData.totalPages =  parseInt(contacts.meta.total_pages);
          paginationData.totalItems =  parseInt(contacts.meta.total_items);
          paginationData.currentPage = parseInt(contacts.meta.page);
          this.setState({ contacts: contacts.contacts, paginationData: paginationData });
          this.props.onContactsLoaded();
        }else{
          paginationData.totalPages =  parseInt(0);
          paginationData.totalItems =  parseInt(0);
          paginationData.currentPage = parseInt(0);
          this.setState({contacts: [], paginationData: paginationData});
        }
        }

      })
  }

  render() {

    let modal = <Modal/>;
    if(this.state.contactId && this.currentContact()!=undefined){
      let currentContact = this.currentContact();
      modal = <Modal title="Edit Contact" iconClassName="mdi mdi-account">
        <ContactFormEditContainer saveContactCallback={this.updateContactState} contact={currentContact} senderId={this.props.senderId}/>
      </Modal>;
    }
    if(this.state.contactId && this.state.showEventForm){
      modal = <Modal title="New Event Form" iconClassName="mdi mdi-account">
        <EventFormNewContainer saveEventCallback={this.addEventToTable} contactId={this.state.contactId}/>
      </Modal>;
    }
    //

    let contactList = this.state.contacts.map((contact)=>{
      return <ContactsTableRow loadContact={this.loadContact} loadEventForm={this.loadEventForm} removeContact={this.removeContact} enrichContact={this.enrichContact} key={contact.id} contact={contact}/>
    })

    let contactColumns = this.state.contactColumns.map((col)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={col.name} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    let paginationSection = <TablePaginationContainer setPage={this.setPage} paginationData = {this.state.paginationData} setEntryLength={this.setEntryLength}/>

    return (
      <div className="card card-table">
      {modal}
        <div className="card-body">
          <div id="contactTable">
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive">
                  <thead>
                    <tr>
                    <td >
                       <Search searchCallback={this.searchContacts}/>
                    </td>
                    </tr>
                    <tr>
                    {contactColumns}
                    </tr>
                  </thead>
                    {contactList}
                </table>
              </div>
            </div>

            {paginationSection}
          </div>
        </div>
      </div>
    )
  }
}

export default ContactsTable;
