import React, { Component } from 'react';
import Select from 'react-select';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { formatDateForApi } from '../utils/DateFormatter'

class ApplyTemplateForm extends Component {
  constructor(){
    super();
    this.state = {
      templateApplied: false,
      templates: [],
      selectedTemplateOption: {},
      selectedTemplate: {}
    }
    this.setTemplateOption = this.setTemplateOption.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.findTemplateById = this.findTemplateById.bind(this);
  }


  setTemplateOption(selectedOption){
    let selectedTemplate = this.findTemplateById(selectedOption.value);
    this.setState({
      selectedTemplateOption: selectedOption,
      selectedTemplate: selectedTemplate
    })
  }


  findTemplateById(id){
    const index = this.state.templates.findIndex(item => item.id === id);
    let templateDup = Object.assign({},this.state.templates[index]);
    return templateDup;
  }



  componentDidMount(){
    let sender = this.props.sender;
    let selectedTemplate = {};
    if(sender.event_type_templates){
      let correctTemplates = sender.event_type_templates.filter(( obj ) => {
        if(obj.event_type.id===2 && obj.recurrence_year!=0){
          return obj.event_type.id == this.props.eventDate.event_type_id && obj.recurrence_year===this.props.eventDate.occurrence_number
        } else{
          return obj.event_type.id == this.props.eventDate.event_type_id;
        }

      });
      if(correctTemplates.length === 1){
        selectedTemplate = correctTemplates[0];
      }

      this.setState({
        templates: correctTemplates,
        selectedTemplate: selectedTemplate
      })
    }
  }

  createOrder(){
    this.props.loadEventDate(this.props.eventDate.id);

    return fetchAuthorized('/api/v1/shopping_carts',{method: 'POST'}).then(resp=>{
      var orderItemFormData  = new FormData();
      orderItemFormData.append('type', 'connect');
      orderItemFormData.append('contact_id',this.props.eventDate.contact.id);
      orderItemFormData.append('order_item[template_id]', this.state.selectedTemplate.id);
      orderItemFormData.append('order_item[category_id]',this.props.eventDate.category_id);
      orderItemFormData.append('order_item[event_type_id]',this.props.eventDate.event_type_id);
      orderItemFormData.append('order_item[skip_delivery_processing]', true);
      orderItemFormData.append('order_item[source_id]', this.state.selectedTemplate.source_id);
      orderItemFormData.append('order_item[held]', true);
      orderItemFormData.append('order_item[source_type]', "Products::Ecard");
      orderItemFormData.append('order_item[send_on]', formatDateForApi(this.props.eventDate.send_on_date));
      orderItemFormData.append('order_item[shopping_cart_id]', resp.shopping_cart.id);
      if(this.props.sender){
        orderItemFormData.append('member_id',this.props.sender.id);
      }
      //
      return fetchAuthorized('/api/v1/order_items',{method: 'POST', body: orderItemFormData}).then(orderItem=>{
        var orderFormData  = new FormData();
        orderFormData.append('type', 'connect');
        orderFormData.append('event_date_id', this.props.eventDate.id);
        orderFormData.append('shopping_cart_id', resp.shopping_cart.id);
        orderFormData.append('order[dummy_data]', null);
        //
        return fetchAuthorized('/api/v1/orders',{method: 'POST', body: orderFormData}).then(order=>{

          console.log(order.order.order_item.secure_code);
          this.props.saveOrderCallback(order.order);
        }).catch(orderError=>{
          console.log(orderError);
          return orderError;
        });
      }).catch(orderItemError=>{
        console.log(orderItemError)
        return orderItemError;
      });
    }).catch(shoppingCartError=>{
      console.log(shoppingCartError);
      return shoppingCartError;
    })
  }

  render() {
    if(this.state.templates.length === 0) return false;
    let templateSelector = '';
    if(this.state.templates.length > 1){
      const templates = this.state.templates.map(template => ({ value: template.id, label: template.subject }))
      templateSelector = <Select value={this.state.selectedTemplateOption} onChange={this.setTemplateOption} options={templates} className="react-select-control apply-template-selector"/>
    }
    return (
     <div className="apply-template-container">{templateSelector}
     <button onClick={()=>this.createOrder()} className="apply-template-btn btn btn-secondary" type="button">
             <span className="icon mdi mdi-plus-circle-o-duplicate"></span>&nbsp;&nbsp;Apply Template
            </button>
     </div>

      )
  }
}
export default ApplyTemplateForm;





