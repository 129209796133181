import React, { Component } from 'react';
import SenderNav from '../nav/SenderNav';
import EventFormNewContainer from './Contact/EventFormNewContainer';
import AddContactSplash from '../generic/AddContactSplash';
import AddEventSplash from '../generic/AddEventSplash';
import SectionHeader from '../generic/SectionHeader';
import EventDatesTimeline from '../generic/EventDatesTimeline';
import EventDatesTimelineEntry from '../generic/EventDatesTimelineEntry';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { SenderContext } from '../contexts/sender-context';
import { setKey } from '../utils/KeySetter'

class SenderHome extends Component {
  constructor(){
    super();
    this.state = {
      sender: null,
      eventCreated: false,
      eventDateParams: null
    }
    this.getSender = this.getSender.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
    this.resetEventCreatedState = this.resetEventCreatedState.bind(this);
  }

  componentDidMount() {
    this.setState({
      sender: this.context.sender,
      eventDateParams: {  sender_id: this.context.sender.id , start_date: '2022-01-01', end_date: '2022-12-31' }
    });
  }

  getSender(){
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    fetchAuthorized(generateUrl('/api/v1/members/current', {type: 'connect'})).then(member => {
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
          if(member){
            this.setState({
              sender: member.member,
              orderParams: {  sender_id: member.member.id  }
            });
          }else{
            this.setState({sender: {}});
          }
        }

    })
  }

  updateSenderState(){
    this.getSender();
  }

  resetEventCreatedState(){
    if(this.state.eventCreated){
      this.setState({eventCreated: false});
    }
  }

  render() {
    if(!this.state.sender) return null;
    if(!this.state.eventDateParams) return null;
    let eventDates = '';
    let sectionHeader  = '';

    if(this.state.sender.contacts_count==0){
      eventDates = <AddContactSplash senderId={this.state.sender.id} saveContactCallback={this.getSender}/>
    } else{
      sectionHeader = <SectionHeader label='Upcoming Sends' icon="mdi mdi-mail-send" newModalTarget='#eventDateTimelineModal'/>;
      eventDates = <EventDatesTimeline key={setKey('edt')} type="SenderEventDates" sender={this.state.sender} totalEvents={this.state.sender.upcoming_sends_count} rowComponent={<EventDatesTimelineEntry/>} id="upcomingSends" eventDateParams={this.state.eventDateParams} saveCallback={this.updateSenderState}/>;
    }

    return (
      <div className="be-wrapper be-nosidebar-left site-manager with-sub-nav">
      <SenderNav sender={this.state.sender}/>
        <div className="be-content">
        {sectionHeader}
          <div className="main-content container-fluid main-content-padding manager-sender-home">
            <div className="row">
            <div className="col-12 col-lg-12">
                {eventDates}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SenderHome.contextType = SenderContext;
export default SenderHome;
