import React, { Component } from 'react';
import PasswordField from '../../generic/PasswordField';
import PhotoField from '../../generic/PhotoField';
import SenderSelect from '../../generic/SenderSelect'
import GroupSelect from '../../generic/GroupSelect'
import LocationSelect from '../../generic/LocationSelect'
import RoleSelect from '../../generic/RoleSelect'
import { setKey } from '../../utils/KeySetter'
import { formattedSelect,formattedSingleSelect } from '../../utils/Helpers'
import { SenderContext } from '../../contexts/sender-context';

class SenderForm extends Component {
  constructor(){
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state ={
      role: 'user',
      managerIds: [],
      managerOptions: [],
      groupIds: [],
      groupOptions: [],
      locationIds: [],
      locationOptions: [],
      avatarData: null,
      updateAvatarData: true,
      signatureData: null,
      updateSignatureData: true,
      password: null
    }
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setManagerId = this.setManagerId.bind(this);
    this.setGroupId = this.setGroupId.bind(this);
    this.setLocationId = this.setLocationId.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.onAvatarChangeHandler = this.onAvatarChangeHandler.bind(this);
    this.onSignatureChangeHandler = this.onSignatureChangeHandler.bind(this);
    this.canEditAll = this.canEditAll.bind(this);
  }

  componentDidMount(){
    this.setState({
      role: this.props.sender.role,
      updateAvatarData: this.props.sender.avatar_url ? false : true,
      updateSignatureData: this.props.sender.signature_url ? false : true
    },()=>this.setManagerId());
    this.setGroupId();
    this.setLocationId();
  }



  handleSelectChange(event){
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState)
  }

  onFormSubmit(event){
    console.log(this.state);
    event.preventDefault();
    this.refs.role = this.state.role;
    this.refs.managerIds = this.state.managerIds;
    this.refs.groupIds = this.state.groupIds;
    this.refs.locationIds = this.state.locationIds;
    this.refs.password = this.state.password;
    this.refs.avatarData = this.state.avatarData;
    this.refs.updateAvatarData = this.state.updateAvatarData;
    this.refs.signatureData = this.state.signatureData;
    this.refs.updateSignatureData = this.state.updateSignatureData;
    this.props.onSubmitEvent(this.refs);
  }

  setManagerId(val){
    let formattedVal = val ? val : formattedSelect(this.props.sender, 'managers');
    this.setState({
      managerIds: formattedVal.ids,
      managerOptions: formattedVal.options
    })
  }

  setGroupId(val){
    let formattedVal = val ? val : formattedSelect(this.props.sender, 'groups');
    this.setState({
      groupIds: formattedVal.ids,
      groupOptions: formattedVal.options
    })
  }

  setLocationId(val){
    let formattedVal = val ? val : formattedSelect(this.props.sender, 'locations');
    this.setState({
      locationIds: formattedVal.ids,
      locationOptions: formattedVal.options
    })
  }
  onAvatarChangeHandler(val){
    this.setState({avatarData: val, updateAvatarData: true})
  }

  onSignatureChangeHandler(val){
    this.setState({signatureData: val, updateSignatureData: true})
  }

  handlePasswordChange(val){
    this.setState({password: val})
  }

  canEditAll(){
    if(this.context.sender.role=="admin"){
      return true;
    } else{
      return false;
    }
  }

  render() {
    let signatureField = <PhotoField fieldLabel="Signature:" defaultValue={this.props.sender.signature_url} onChange={this.onSignatureChangeHandler}/>
    let photoField = <PhotoField fieldLabel="Profile Photo:" defaultValue={this.props.sender.avatar_url} onChange={this.onAvatarChangeHandler}/>
    let passwordField = !Object.keys(this.props.sender).length ? <PasswordField onChange={this.handlePasswordChange}/> : '';
    let roleSelector = Object.keys(this.props.sender).length && this.canEditAll() ? <RoleSelect role={this.state.role} handleChange={this.handleSelectChange}/> : '';
    let senderId = this.props.sender ? this.props.sender.id : '' ;
    let managerSelector =  this.canEditAll() ? <SenderSelect key={setKey('sender-select-'+senderId)} setSenderCallback={this.setManagerId} defaultOptions={this.state.managerOptions} managersOnly="true"/> : '';
    let groupSelector = this.canEditAll() ? <GroupSelect key={setKey('group-select-'+senderId)} setGroupCallback={this.setGroupId} defaultOptions={this.state.groupOptions}/> : '';
    let locationSelector = this.canEditAll() ? <LocationSelect key={setKey('location-select-'+senderId)} setLocationCallback={this.setLocationId} defaultOptions={this.state.locationOptions}/> : '';

    return (
      <form>
        <input ref="userId" className="form-control" id="userId" type="hidden" defaultValue={this.props.sender.user_id}/>
          <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={this.props.sender.email}/>
          </div>

          <div className="row col-12">
            <div className="col-6 form-group">
            <label className="input-header">First Name:</label>
            <input ref="firstName" className="form-control" id="firstName" type="text" placeholder="Enter First Name" defaultValue={this.props.sender.first_name}/>
            </div>
            <div className="col-6 form-group">
            <label className="input-header">Last Name:</label>
           <input ref="lastName" className="form-control" id="lastName" type="text" placeholder="Enter Last Name" defaultValue={this.props.sender.last_name}/>
            </div>
          </div>
          {photoField}
          {signatureField}



          <div className="form-group">
            <label className="input-header">Logo URL:</label>
            <input ref="logoUrl" className="form-control" id="logoUrl" type="text" placeholder="Enter Logo Url" defaultValue={this.props.sender.logo_url}/>
          </div>

          {roleSelector}
          {locationSelector}
          {groupSelector}
          {managerSelector}
          {passwordField}
          <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.onFormSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Save Changes</button>
                </p>
              </div>
            </div>
          </div>
        </form>


      )
  }
}

SenderForm.contextType = SenderContext;
export default SenderForm;










