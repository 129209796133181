import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import SenderHome from '../pages/SenderHome'
import Logout from '../pages/Logout'
import SenderContact from '../pages/SenderContact'
import SenderContacts from '../pages/SenderContacts'
import SenderHistory from '../pages/SenderHistory'
import SenderSettings from '../pages/SenderSettings'
import TrialExpired from '../pages/TrialExpired'
import NotFound from '../pages/NotFound'

class SenderRoutes extends Component {

  render() {
    return <Switch>
          <Route path='/' exact component={SenderHome} />
          <Route path='/trial_expired' exact component={TrialExpired} />
          <Route path='/logout' exact component={Logout} />
          <Route path="/contacts/:id" component={SenderContact} />
          <Route path="/contacts" component={SenderContacts} />
          <Route path="/history" component={SenderHistory} />
          <Route path="/settings" component={SenderSettings} />
          <Route component={NotFound} />
      </Switch>;
  }
}

export default SenderRoutes;



