import React, { Component } from 'react';

class TabNavItem extends Component {
  constructor(){
    super();
    this.defaultClassNames = ['nav-link']
    this.state = {
      classNames: this.defaultClassNames
    }
  }
  setClassNames(){
    let classNames = this.state.classNames;
    if(this.props.isActive){
      classNames.push('active');
      this.setState({classNames: classNames})
    }else{
      this.setState({classNames: this.defaultClassNames})
    }
  }

  componentDidMount(){
    this.setClassNames();
  }


  render() {
    let index = this.props.index ? this.props.index : false;
    return (
      <li className="nav-item">
        <a className={this.state.classNames.join(' ')} onClick={()=>{if(!this.props.href) {this.props.onClick(index)}}} href={this.props.href} data-toggle="tab" role="tab">
          {this.props.name}
        </a>
      </li>
    )
  }
}
export default TabNavItem;








