import React, { Component } from 'react';
import ManagerForm from './ManagerForm';
import { fetchAuthorized } from '../../utils/Fetcher'

class ManagerFormEditContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members/';
    this.updateManager = this.updateManager.bind(this);
  }


  // called from ContactForm when the form is submitted
  // sent to the component as onSubmitEvent
  updateManager(refs){
    var formData  = new FormData();

    let formObjectValues = {
      email: refs.email.value,
      last_name: refs.lastName.value,
      first_name: refs.firstName.value,
      user_id: refs.userId.value,
      role: refs.role,
      sender_ids: refs.senderIds,
      avatar_data: refs.avatarData
    }

    formData.append('type','connect');
    formData.append('member[sender_ids]', formObjectValues.sender_ids);
    formData.append('member[role]', formObjectValues.role);
    if(refs.updateAvatarData){
      formData.append('member[user_attributes][avatar]', formObjectValues.avatar_data);
    }
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][first_name]', formObjectValues.first_name);
    formData.append('member[user_attributes][id]', formObjectValues.user_id);

    return fetchAuthorized(this.baseUrl+this.props.manager.id,{
      method: 'PUT',
      body: formData
    }).then(resp=>{
        this.props.saveManagerCallback(formObjectValues);
        console.log('Successfully completed the put');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }



  render() {
    console.log(this.props.manager);
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <ManagerForm key={this.props.manager.id} manager={this.props.manager} onSubmitEvent={this.updateManager}/>
      </div>
      </div>
      )
  }
}
export default ManagerFormEditContainer;










