import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class ContactsSnapshot extends Component {
  constructor(){
    super();
    this.state = {
      contactId: null,
      stats: {
        total_contacts: 0,
        total_birthdays: 0,
        total_anniversaries: 0,
        total_other:0
      }
    }
    this.getContactsSnapshot = this.getContactsSnapshot.bind(this);
  }

  componentDidMount() {
    this.getContactsSnapshot();
  }

  setParams(){
    return {
      type: 'connect'
    }
  }

  getContactsSnapshot() {
    let params = this.setParams();
    fetchAuthorized(generateUrl('/api/v1/members/'+this.props.senderId+'/contacts_snapshot', params)).then(member => {
       console.log(member);
        if(member.error || member.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(member){
          this.setState({ stats: member.member });
        }else{
          this.setState({stats: {}});
        }
        }

      })
  }




  render() {
    return (
          <div className="row">
               <div className="col-3 col-lg-3">
              <div className="card total">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-accounts bg-primary"></span>
                      <span className="counter">{this.state.stats.total_contacts}</span> <span className="label">Contacts Total</span></h3>
                  </div>
              </div>
            </div>
              <div className="col-3 col-lg-3">
              <div className="card birthdays">
                  <div className="card-body">
                    <h3><span className="icon mdi mdi-cake bg-warning"></span>
                                        <span className="counter">{this.state.stats.total_birthdays}</span> <span className="label">Birthdays</span></h3>
                  </div>
              </div>
            </div>
             <div className="col-3 col-lg-3">
              <div className="card work-anniversaries">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-star bg-danger"></span>
                                    <span className="counter">{this.state.stats.total_anniversaries}</span><span className="label">Work Anniversaries</span></h3></div>
              </div>
            </div>
             <div className="col-3 col-lg-3">
              <div className="card other">
                  <div className="card-body">
                  <h3><span className="icon mdi mdi-mail-send bg-success"></span>
                                    <span className="counter">{this.state.stats.total_other}</span><span className="label">Other Events</span></h3>
                  </div>
              </div>
            </div>
            </div>

      )
  }
}
export default ContactsSnapshot;












