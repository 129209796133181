import React, { Component } from 'react';
import FixableField from './FixableField';

class ContactsImportErrorList extends Component {

  render() {
    const rows = this.props.rows.map((row,index)=> {
      let button = <span className="text-danger">This contact will not be imported.</span>;
      let email = row.email;
      if(this.props.errorType === 'invalid'){
        email = <FixableField index={index} field="email" value={email} onFieldFix={this.props.onEmailFix}/>
        button = '';
      }
     return <tr key={row.row_number}>
        <td>{row.row_number}</td>
        <td>{email}</td>
        <td>{button}</td>
      </tr>
    })
    return (
      <div className="col-10 col-lg-10 error-list">
                <table className="main-table table table-striped table-hover be-table-responsive">
                <thead>
                <tr>
                  <th>Line Number</th>
                  <th>Email</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                  {rows}
                  </tbody>
                </table>
            </div>


      )
  }
}
export default ContactsImportErrorList;













