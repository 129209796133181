import React, { Component } from 'react';

class RoleSelect extends Component {
  constructor(){
    super();
    this.roles = ['user','collaborator','admin'];
    this.handleRoleChange = this.handleRoleChange.bind(this);
  }

  handleRoleChange(event){
    this.props.handleChange(event);
  }
  render() {

    const roles = this.roles.map(role => (
      <option key={role} value={role}>{role}</option>
      ))

    return (
      <div className="form-group">
           <label className="input-header">Role:</label>
            <select className="form-control" name='role' className="form-control" onChange={this.handleRoleChange} value={this.props.role}>
            {roles}
            </select>
          </div>

      )
  }
}
export default RoleSelect;










