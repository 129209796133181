import React, { Component } from 'react';
import TablePaginationContainer from './TablePaginationContainer'
import Modal from './Modal';
import FilterByDateTime from './FilterByDateTime';
import FixBounceForm from './FixBounceForm';
import SortByDateStatus from './SortByDateStatus';
import ContactSelect from './ContactSelect'
import OrderHistorySnapshot from './OrderHistorySnapshot'
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { now, today, startOfPeriod, formatMomentForStartEndDate} from '../utils/DateFormatter';
import { setKey } from '../utils/KeySetter'

class OrderHistoryTimeline extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/orders';
    this.state = {
      orderId: null,
      contactId: null,
      contactOptions: {label: 'Filter By Contact...', value: ''},
      eventTypeId: null,
      eventTypeOptions: null,
      orders: [],
      breakdown: null,
      newEvent: false,
      sortAttribute: 'date',
      startDate: startOfPeriod(today(), 'month'),
      endDate: formatMomentForStartEndDate(today()),
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getOrders = this.getOrders.bind(this);
    this.loadOrder = this.loadOrder.bind(this);
    this.duplicateOrderState = this.duplicateOrderState.bind(this);
    this.updateOrderState = this.updateOrderState.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.updateStartEndDate = this.updateStartEndDate.bind(this);
    this.updateSortAttribute = this.updateSortAttribute.bind(this);
    this.getOrderStatus = this.getOrderStatus.bind(this);
    this.organizeByStatus = this.organizeByStatus.bind(this);
    this.setContactId = this.setContactId.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('contact')){
      this.setContactId({ids: urlParams.get('contact'),contactOptions: {} })
    }else{
      this.getOrders();
    }

  }

  setContactId(val){
    let formattedVal = val ? val : {ids: null, options: {}}
    this.setState({
      contactId: formattedVal.ids,
      contactOptions: formattedVal.options
    },()=>this.getOrders());
  }

  updateStartEndDate(dateRange, options={}){
    let paginationData = Object.assign({},this.state.paginationData);
    let stateVals = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      paginationData: paginationData,
      eventTypeId: null,
      eventTypeOptions: null
    }

    if(options.showAll){
      stateVals.paginationData.currentPage =  1;
      stateVals.paginationData.entryLength = 'all';
    } else{
      stateVals.paginationData.currentPage =  1;
      stateVals.paginationData.entryLength = 25;
    }
    this.setState(stateVals,()=>this.getOrders());
  }

  updateSortAttribute(attr){
    this.setState({
      sortAttribute: attr
    },()=>this.getOrders());
  }

  loadOrder(orderId){
    this.setState({
      orderId: orderId
    })
  }


  currentOrder(){
    let dup = this.duplicateOrderState();
    return dup.orders[dup.index];
  }

  duplicateOrderState(){
    const index = this.state.orders.findIndex(item => item.id === this.state.orderId);
    let orders = [...this.state.orders] // important to create a copy, otherwise you'll modify state outside of setState call
    let orderDup = Object.assign({},this.state.orders[index]);
    return {index: index, order: orderDup, orders: orders}
  }

  updateOrderState(){
    let dup = this.duplicateOrderState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.orderId, { type: 'connect'})).then(order => {
      dup.orders[dup.index] = order.order;
      this.setState({orderId: null, orders: dup.orders},()=>console.log(this.state.orders));
      let els = document.getElementsByClassName("modal-close-class");
      for (let item of els) { item.click(); }
    });
  }

  organizeByStatus(){
    if(this.state.sortAttribute!=='status') return;
    let groupings = {'opened': [],'sent':[]};
    let newOrderArr = [];
    let dup = this.duplicateOrderState();
    dup.orders.map((order)=>{
      let status = this.getOrderStatus(order);
      groupings[status].push(order);
    });
    for (var property in groupings) {
      if (groupings.hasOwnProperty(property)) newOrderArr.push(groupings[property]);
    }
    /**/
    this.setState({
      orders: [...newOrderArr.flat()]
    })
  }

  getOrderStatus(order){
    return order.order_item.delivery.opened ? 'opened' : 'sent';
  }

  setParams(){
    return {
      history: true,
      type: 'connect',
      items_type: 'Electronic',
      direction: 'desc',
      manual_sort_by: 'orders.id',
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }


  getOrders(options={}) {
    let params = Object.assign(this.props.orderParams, this.setParams());
    if(this.state.startDate) params['start_date'] = this.state.startDate;
    if(this.state.endDate) params['end_date'] = this.state.endDate;
    if(this.state.contactId) params['contact_id'] = this.state.contactId;
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(orders => {
       console.log(orders);
        if(orders.error || orders.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(orders.orders.length){
          let paginationData = Object.assign({}, this.state.paginationData);
          paginationData.totalPages =  parseInt(orders.meta.total_pages);
          paginationData.totalItems =  parseInt(orders.meta.total_items);
          paginationData.currentPage = parseInt(orders.meta.page);
          let stateHash = { orders: orders.orders, paginationData: paginationData }
          if(!options.skipBreakdown){
            stateHash.breakdown = orders.breakdown
          }
          this.setState(stateHash,()=>this.organizeByStatus());
        }else{
          this.setState({orders: []});
        }
      }
    }).catch(error=>{console.log(error)})
  }

  onModalClose(){
    this.setState({orderId: null})
  }

  setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getOrders();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getOrders();
    });
  }

  render() {
    /* fix bounce modal */
    let modalFixBounce = <Modal id="fixBounce"/>;
    let modalFixBounceTitle ='';
    let modalFixBounceContents = '';
    let modalSize = 'small';
    if(this.state.orderId){
      let currentOrder = this.currentOrder();
      modalFixBounceTitle = 'Fix Your Bounce';
      modalFixBounceContents = <FixBounceForm key={setKey('new-fix-bounce-'+this.state.orderId)} email={currentOrder.order_item.contact.email} secureCode={currentOrder.order_item.secure_code} deliveryId={currentOrder.order_item.delivery.id} saveCallback={this.updateOrderState}/>
      modalFixBounce = <Modal title={modalFixBounceTitle} id="fixBounce" onClose={this.onModalClose} iconClassName="mdi mdi-account">{modalFixBounceContents}</Modal>;
    }

    /* table contents and sorting */
    let orderHistoryTimelineEntry = this.state.orders.map((order,i)=>{
      let rowComponentProps = {key: order.id, index: (this.state.orders.length - i), loadOrder: this.loadOrder, order: order }
      return React.cloneElement(this.props.rowComponent, rowComponentProps);
    })
    let orderHistorySnapshot = <OrderHistorySnapshot breakdown={this.state.breakdown}/>
    let orderHistoryTimeline = ''
    let dateFilter = <FilterByDateTime onClick={this.updateStartEndDate} history={true} period="month" startDate={this.state.startDate}/>;
    let sortList = '';
    let paginationSection = <TablePaginationContainer setPage={this.setPage}
                                      paginationData = {this.state.paginationData}
                                      setEntryLength={this.setEntryLength}/>
    let contactList = <div className="contact-select-container"><ContactSelect senderId={this.props.orderParams.sender_id} setContactCallback={this.setContactId} defaultOptions={this.state.contactOptions}/></div>;

    if(this.state.orders.length > 0){
      sortList = <SortByDateStatus onOptionClick={this.updateSortAttribute} options={['date','status']}/>
      orderHistoryTimeline = <ul className="eventDateTimeline user-timeline user-timeline-compact">
               {orderHistoryTimelineEntry}

           </ul>
    } else {
      orderHistoryTimeline = <div className="clear-fix">
        <div className="col-10 col-lg-10 add-contact-dialog">
        <h3>You do not have any sends for this time period.</h3>
        </div>
        </div>
    }



    return (
      <div>
        {modalFixBounce}
        {dateFilter}
        <div className="row stats-snapshot">
              <div className="col-12 col-lg-12">
                {orderHistorySnapshot}
              </div>
            </div>
            <div className="row">
             <div className="col-12 col-lg-12 text-right mb-3">
             {sortList}
              </div>
            </div>
        {orderHistoryTimeline}
        {paginationSection}
      </div>
    )
  }
}

export default OrderHistoryTimeline;








