import React, { Component } from 'react';
import TableColumnHeader from '../../generic/TableColumnHeader'
import { setKey } from '../../utils/KeySetter'
import TablePaginationContainer from '../../generic/TablePaginationContainer'
import Modal from '../../generic/Modal';
import SendersTableRow from './SendersTableRow'
import SenderFormEditContainer from '../Admin/SenderFormEditContainer'
import { fetchAuthorized, generateUrl } from '../../utils/Fetcher'

class SendersTable extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/members';
    this.state = {
      senderId: null,
      senders: [],
      senderColumns: [
      { name: 'Name', sortApiColumn: 'users.last_name', sortable: true },
      { name: 'Practice Group', sortApiColumn: 'groups.name', sortable: true },
      { name: 'Office',sortApiColumn: 'locations.display_name', sortable: true},
      { name: 'Upcoming Send', sortApiColumn: 'order_items.send_on', sortable: true },
      { name: 'Stats', sortApiColumn: 'sender', sortable: true },
      { name: '' }
      ],
      currentSortColumn: 'users.last_name',
      currentSortDirection: 'asc',
      paginationData: {
        totalItems: 0,
        totalPages: 0,
        entryLengths: [10,25,50,100,'All'],
        entryLength: 10,
        currentPage: 1
      }
    };
    this.getSenders = this.getSenders.bind(this);
    this.setEntryLength = this.setEntryLength.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setSort = this.setSort.bind(this);
    this.loadSender = this.loadSender.bind(this);
    this.duplicateSenderState = this.duplicateSenderState.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
    this.enrichSender = this.enrichSender.bind(this);
    this.removeSender = this.removeSender.bind(this);
    this.removeSenderState = this.removeSenderState.bind(this);
  }

  loadSender(senderId){
    this.setState({
      senderId: senderId
    })
  }

  enrichSender(senderId){
    fetchAuthorized(generateUrl(this.baseUrl+"/"+senderId+"/enrich",{})).then(sender => {
      console.log(sender);
    })
  }

  removeSender(senderId){
    if(window.confirm('Are you sure you want to remove this sender?')){
      fetchAuthorized(this.baseUrl+'/'+senderId,{ method: 'DELETE' }).then(resp=>{
        this.setState({
          senderId: senderId
          },()=>this.removeSenderState())
      })
    }
  }

  removeSenderState(){
    let dup = this.duplicateSenderState();
    let filteredSenders = dup.senders.slice(0, dup.index).concat(dup.senders.slice(dup.index + 1, dup.senders.length))
    this.setState({senderId: null, senders: filteredSenders},()=>console.log(this.state.senders));
  }


  duplicateSenderState(){
    const index = this.state.senders.findIndex(item => item.id === this.state.senderId);
    let senders = [...this.state.senders] // important to create a copy, otherwise you'll modify state outside of setState call
    let senderDup = Object.assign({},this.state.senders[index]);
    return {index: index, sender: senderDup, senders: senders}
  }


  currentSender(){
    let dup = this.duplicateSenderState();
    return dup.senders[dup.index];
  }

  // called from UpdateContactForm Container component after the api is updated
  // sent to the comonent as saveContactCallback
  updateSenderState(){
    let dup = this.duplicateSenderState();
    fetchAuthorized(generateUrl(this.baseUrl+"/"+this.state.senderId, { type: 'connect'})).then(member => {
      dup.senders[dup.index] = member.member;
      this.setState({senderId: null, senders: dup.senders},()=>console.log(this.state.senders));
      Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
    });
  }

  componentDidMount() {
    this.getSenders();
  }

  componentDidUpdate() {
    if(this.props.senderCreated){
      this.getSenders();
    }
  }

  setEntryLength(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.entryLength = i.target.value;
    paginationData.currentPage = 1;
    this.setState({paginationData: paginationData},()=>{
      this.getEventDates();
    });
  }

  setPage(i){
    let paginationData = Object.assign({}, this.state.paginationData);
    paginationData.currentPage = i;
    this.setState({paginationData: paginationData},()=>{
      this.getSenders();
    });
  }

  setSort(column, sortDirection){
    this.setState({currentSortColumn: column, currentSortDirection: sortDirection},()=>{
      this.getSenders();
    })
  }




  /*
  http://localhost:3000/api/v1/senders?
        by_status=
        &direction=desc
        &end_date=2019-01-16
        &items_type=Electronic
        &not_archived=true
        &page=1
        &per_page=10
        &production_only=true
        &q=
        &sort=placedAt
        &start_date=2019-01-01
        &type=
  */

  setParams(){
    return {
      type: 'connect',
      direction: this.state.currentSortDirection,
      manual_sort_by: this.state.currentSortColumn,
      page: this.state.paginationData.currentPage,
      per_page: (this.state.paginationData.entryLength).toString().toLowerCase()
    }
  }


  getSenders() {
    let params = this.setParams();
    fetchAuthorized(generateUrl(this.baseUrl, params)).then(members => {
        if(members.error || members.errors){
          console.log('UNAUTHORIZED');
        }else{
        if(members.members.length){
          let paginationData = Object.assign({}, this.state.paginationData);
          paginationData.totalPages =  parseInt(members.meta.total_pages);
          paginationData.totalItems =  parseInt(members.meta.total_items);
          paginationData.currentPage = parseInt(members.meta.page);
          this.setState({ senders: members.members, paginationData: paginationData });
          this.props.onSendersLoaded();
        }else{
          this.setState({senders: []});
        }
         }

      })
  }

  render() {

    let modal = <Modal/>;
    if(this.state.senderId){
      console.log('SENDER ID EXISTS');
      let currentSender = this.currentSender();
      modal = <Modal title="Edit Sender" iconClassName="mdi mdi-account">
        <SenderFormEditContainer saveSenderCallback={this.updateSenderState} sender={currentSender}/>
      </Modal>;
    }

    let senderList = this.state.senders.map((sender)=>{
      return(<SendersTableRow loadSender={this.loadSender} removeSender={this.removeSender} enrichSender={this.enrichSender} key={setKey('sender-row-'+sender.id)} sender={sender}/>)
    })
    let senderColumns = this.state.senderColumns.map((col)=>{
      let sortDirection = this.state.currentSortColumn === col.sortApiColumn ? this.state.currentSortDirection : false;
      return <TableColumnHeader key={col.name} name={col.name} onSort={this.setSort} sortable={col.sortable} sortDirection={sortDirection} sortApiColumn={col.sortApiColumn}/>
    })

    let paginationSection = <TablePaginationContainer setPage={this.setPage}
                                      paginationData = {this.state.paginationData}
                                      setEntryLength={this.setEntryLength}/>

    return (
      <div className="card card-table">
      {modal}
      <div className="card-header">
        <div className="row table-filters-container">
          <div className="col-12 col-lg-12 col-xl-12">
            Filters
          </div>
        </div>
        </div>
        <div className="card-body">
          <div id="senderTable">
            <div className="row">
              <div className="col-sm-12">
                <table className="main-table table table-striped table-hover be-table-responsive">
                  <thead>
                    <tr>
                    {senderColumns}
                    </tr>
                  </thead>
                  <tbody>
                    {senderList}
                  </tbody>
                </table>
              </div>
            </div>
            {paginationSection}
          </div>
        </div>
      </div>
    )
  }
}

export default SendersTable









