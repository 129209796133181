import React, { Component } from 'react';

class SectionHeader extends Component {
  constructor(){
    super();
    this.state = {
      isClicked: false,
      classNames: "circle closed"
    }
    this.onClick = this.onClick.bind(this);
  }
  onClick(){
    if(this.state.isClicked){
      this.setState({
      isClicked: false,
      classNames: "circle closed"
    });
    }else{
      this.setState({
      isClicked: true,
      classNames: "circle clicked"
    });
    }

  }
  render() {
    let addArea = <div className="add-btn-container float-right">
                        <div className='add-btn-label float-left'>Add...</div>
                        <div className='add-btn float-right'>
                          <nav onClick={this.onClick} className={this.state.classNames} tabIndex="0">
                             <div className="circle-nav-background"></div>
                             <div className="circle-nav-container">
                               <div className="icon-container" onClick={this.props.loadModal}  data-toggle="modal" data-target={this.props.newModalTarget} >
                                 <div className="icon"><span className="mdi mdi-account-add"></span></div><span className="icon-class">Add one</span>
                               </div>
                               <div className="icon-container" onClick={this.props.loadModal} data-toggle="modal" data-target={this.props.importModalTarget}>
                                 <div className="icon"><span className="mdi mdi-upload"></span></div><span className="icon-class">Import Multiple</span>
                               </div>
                             </div>
                          </nav>
                        </div>
                      </div>;

    if(!this.props.importModalTarget) {
      let nav = <nav className={this.state.classNames} tabIndex="0" data-toggle="modal"  data-target={this.props.newModalTarget} data-backdrop="static" data-keyboard="false">
                          <div className="circle-nav-background"></div>
                        </nav>
      if(this.props.customOnClick){
         nav = <nav className={this.state.classNames} tabIndex="0" data-toggle="modal" onClick={()=>this.props.customOnClick()} data-target={this.props.newModalTarget} data-backdrop="static" data-keyboard="false">
                          <div className="circle-nav-background"></div>
                        </nav>
      }
      addArea = <div className="add-btn-container float-right">
                        <div className='add-btn-label float-left'>Add...</div>
                        <div className='add-btn float-right'>
                        {nav}
                        </div>
                      </div>
    }
    if(this.props.hideNew) addArea = '';
    let icon='';
    if(this.props.icon){
      icon = <div className="icon"><span className={this.props.icon}></span></div>
    }
    return (
      <div className="section-header">
            <div className="main-content container-fluid main-content-padding">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="row">
                    <div className="col-9 col-lg-9">

                      <h3> {icon} {this.props.label}</h3>
                    </div>
                    <div className="col-3 col-lg-3">
                      {addArea}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
  }
}
export default SectionHeader;