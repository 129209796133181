import React, { Component } from 'react';
import MemberNoticeForm from './MemberNoticeForm';
import { fetchAuthorized } from '../../utils/Fetcher'
import { setKey } from '../../utils/KeySetter'

class MemberNoticeFormNewContainer extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/member_notices/';
    this.memberNotice = null;
    this.createMemberNotice = this.createMemberNotice.bind(this);
  }


  // called from MemberNoticeForm when the form is submitted
  // sent to the component as onSubmitEvent
  createMemberNotice(formObj){
    var formData  = new FormData();
    formData.append('type','connect');
    formData.append('member_notice[notice_type]', 'pre_send');
    formData.append('member_notice[delivery_method]', 'email');
    formData.append('member_notice[time_to_order_delivery]', formObj['memberNotice']['time_to_order_delivery']);
    formData.append('member_notice[member_id]', formObj['memberNotice']['member_id']);

    return fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    }).then(resp=>{
        this.props.saveMemberNoticeCallback(formObj);
        console.log('Successfully completed the post');
       return console.log(resp);
    }).catch(error => {
      console.log(error);
      return error;
    })
  }

  render() {
    return (
      <div className="row">
      <div className="col-12 col-lg-12  text-left ecard-form">
        <MemberNoticeForm key={setKey('member-notice-new-')} contact={this.memberNotice} onSubmitEvent={this.createMemberNotice} senderId={this.props.senderId}/>
      </div>
      </div>
      )
  }
}
export default MemberNoticeFormNewContainer;










