import React, { Component } from 'react';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'
import { formatDateForApi } from '../utils/DateFormatter'
import ReactPlayer from 'react-player'
import OrderFormFiller from './OrderFormFiller'
import EcardSelect from './EcardSelect'
import EcardPreview from './EcardPreview'

class OrderFormNew extends Component {
  constructor(){
    super();
    this.baseUrl = '/api/v1/orders/';
    this.state = {
      category: {},
      currentEcard: null
    }
    this.createOrder = this.createOrder.bind(this);
    this.previewSelection = this.previewSelection.bind(this);
  }

   previewSelection(ecard){
   this.setState({
      currentEcard: ecard
    })
   }

  createOrder(){

    return fetchAuthorized('/api/v1/shopping_carts',{method: 'POST'}).then(resp=>{
      var orderItemFormData  = new FormData();
      orderItemFormData.append('type', 'connect');
      orderItemFormData.append('contact_id',this.props.eventDate.contact.id);
      if(this.state.currentEcard.subject_line){
        orderItemFormData.append('subject', this.state.currentEcard.subject_line);
      }
      orderItemFormData.append('order_item[category_id]',this.props.eventDate.category_id);
      orderItemFormData.append('order_item[event_type_id]',this.props.eventDate.event_type_id);
      orderItemFormData.append('order_item[skip_delivery_processing]', true);
      orderItemFormData.append('order_item[source_id]', this.state.currentEcard.id);
      orderItemFormData.append('order_item[source_type]', "Products::Ecard");
      orderItemFormData.append('order_item[send_on]', formatDateForApi(this.props.eventDate.send_on_date));
      orderItemFormData.append('order_item[shopping_cart_id]', resp.shopping_cart.id);
      if(this.props.sender){
        orderItemFormData.append('member_id',this.props.sender.id);
      }
      //
      return fetchAuthorized('/api/v1/order_items',{method: 'POST', body: orderItemFormData}).then(orderItem=>{
        var orderFormData  = new FormData();
        orderFormData.append('type', 'connect');
        orderFormData.append('event_date_id', this.props.eventDate.id);
        orderFormData.append('shopping_cart_id', resp.shopping_cart.id);
        orderFormData.append('order[dummy_data]', null);
        //
        return fetchAuthorized('/api/v1/orders',{method: 'POST', body: orderFormData}).then(order=>{
          this.props.saveOrderCallback(order.order);
          console.log(order.order_item.secure_code);
        }).catch(orderError=>{
          console.log(orderError);
          return orderError;
        });
      }).catch(orderItemError=>{
        console.log(orderItemError)
        return orderItemError;
      });
    }).catch(shoppingCartError=>{
      console.log(shoppingCartError);
      return shoppingCartError;
    })
  }


  render() {


    let ecardPreview = <div className="card mb-3 placeholder"><div className="card-body text-center">Please select an e card to continue</div></div>;
    if(this.state.currentEcard){
      let wistiaUrl = "https://greetingcc.wistia.com/medias/"+this.state.currentEcard.wistia_default_hashed_id;
      ecardPreview = <EcardPreview currentEcard={this.state.currentEcard}>
                <button onClick={this.createOrder} className="card-link btn btn-primary btn-xl">Select E Card</button>
            </EcardPreview>
    }

    // to show fake cards, use:
    // <OrderFormFiller category={this.state.category}/>
    return (
    <div className="row ecard-select">
      <div className="col-6 col-lg-6  text-center scroll-container">
        <EcardSelect onEcardSelected={this.previewSelection} categoryId={this.props.eventDate.category_id}/>
      </div>
      <div className="col-6 col-lg-6  text-center">
        <div className="row">
          <div className="col-12 col-lg-12">
            {ecardPreview}
          </div>
        </div>
      </div>
    </div>
      )
  }
}

export default OrderFormNew;










