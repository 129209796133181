import moment from 'moment';

export const formattedDate = (date)=>{
  return moment.unix(date).format('MM.DD.YYYY');
}

export const formattedDateTime = (date)=>{
  return moment.unix(date).format('MM.DD.YYYY hh:mm A');
}

export const formattedMonthDay = (date)=>{
  return moment.unix(date).format('MM.DD');
}

export const now = ()=>{
  return moment().unix();
}
export const today = ()=>{
  return moment().startOf('day');
}
export const todaysMonthName = ()=>{
  return moment().format('MMMM');
}
export const todaysYearName = ()=>{
  return moment().format('YYYY');
}

export const tomorrow = ()=>{
  return moment().add(1,'days').startOf('day');
}

export const unixDate = (date)=>{
  return moment(date).unix();
}
export const formatMomentForStartEndDate = (date)=>{
  return date.format('YYYY-MM-DD');
}
export const formatStartEndDateForMoment = (date)=>{
  return moment(date);
}
export const formatMomentForDay = (date)=>{
  return date.format('ddd MM/DD');
}
export const formatDateForApi = (date)=>{
  return moment.unix(date).format();
}
export const formatDatePickerForApi = (date)=>{
  return moment(date).format();
}
export const stringToDate =(datestr)=>{
  return moment(datestr).toDate();
}
export const nextSevenDays = (startDate)=>{
 let newArr = [0,1,2,3,4,5,6].map((day)=>{
  let newDay = moment(startDate).add(day,'days').startOf('day');
  return newDay;
 })
 return newArr;
}

export const month = (date)=>{
  return moment.unix(date).format('MMM');
}
export const day = (date)=>{
  return moment.unix(date).format('D');
}
export const year = (date)=>{
  return moment.unix(date).format('YYYY');
}
export const startOfYear = (year)=>{
  let myyear = year.toString();
  return moment(myyear+'-10-10').startOf('year').format('YYYY-MM-DD');
}
export const endOfYear = (year)=>{
  let myyear = year.toString();
  return moment(myyear+'-10-10').endOf('year').format('YYYY-MM-DD');
}
export const startOfPeriod = (dateStr, period)=>{
  return moment(dateStr).startOf(period).format('YYYY-MM-DD');
}

export const endOfPeriod = (dateStr, period)=>{
  return moment(dateStr).endOf(period).format('YYYY-MM-DD');
}

export const previousPeriod = (dateStr, period)=>{
   let newDate= moment(dateStr).subtract(1,period);
   let str = formatMomentForStartEndDate(newDate);
   return moment(str).startOf(period).format('YYYY-MM-DD');
}

export const nextPeriod = (dateStr, period)=>{
   let newDate= moment(dateStr).add(1,period);
   let str = formatMomentForStartEndDate(newDate);
   return moment(str).startOf(period).format('YYYY-MM-DD');
}
