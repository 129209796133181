import React, { Component } from 'react';
import SenderNav from '../nav/SenderNav';
import Modal from '../generic/Modal';
import { setKey } from '../utils/KeySetter'
import SectionHeader from '../generic/SectionHeader';
import MemberNoticeFormNewContainer from './Settings/MemberNoticeFormNewContainer';
import MemberNoticesTable  from './Settings/MemberNoticesTable';
import SettingsTabs from './Settings/SettingsTabs';
import EventTypeTemplateFormNewContainer from './Settings/EventTypeTemplateFormNewContainer';
import EventTypeTemplatesTable  from './Settings/EventTypeTemplatesTable';
import NotificationEmailsForm from './Settings/NotificationEmailsForm';
import DailyDigestForm from './Settings/DailyDigestForm';
import WeeklyDigestForm from './Settings/WeeklyDigestForm';
import { SenderContext } from '../contexts/sender-context';
import { fetchAuthorized, generateUrl } from '../utils/Fetcher'

class SenderSettings extends Component {
  constructor(){
    super();
    this.state = {
      sender: null
    }
    this.getSender = this.getSender.bind(this);
    this.updateSenderState = this.updateSenderState.bind(this);
    this.addMemberNoticeToTable = this.addMemberNoticeToTable.bind(this);
    this.addEventTypeTemplateToTable = this.addEventTypeTemplateToTable.bind(this)
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.getSender();
  }

  getSender(){
    this.setState({
      sender: this.context.sender,
      eventDateParams: {  sender_id: this.context.sender.id , start_date: '2022-01-01', end_date: '2022-12-31' }
    });
  }

  updateSenderState(){
    this.getSender();
  }

  addMemberNoticeToTable(){
    this.setState({memberNoticeCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  addEventTypeTemplateToTable(){
    this.setState({eventTypeTemplateCreated: true});
    Array.from(document.getElementsByClassName("modal-close-class")).map((el, index)=> el.click());
  }

  resetState(){
    if(this.state.memberNoticeCreated || this.state.eventTypeTemplateCreated ){
      this.setState({
        memberNoticeCreated: false,
        eventTypeTemplateCreated: false});
    }
  }

  render() {
    if(!this.state.sender) return null;
    console.log("STATE RESET IN MANAGER SENDING SETTINGS")
    /*
     {newMemberNoticeModal}
    let newMemberNoticeModal = <Modal title="New Notification" id="new-member-notice" iconClassName="mdi mdi-account">
          <MemberNoticeFormNewContainer key={setKey()} saveMemberNoticeCallback={this.addMemberNoticeToTable} senderId={this.state.sender.id}/>
        </Modal>
        */

    let newEventTypeTemplateModal = <Modal title="New Template" id="new-event-type-template" iconClassName="mdi mdi-account">
          <EventTypeTemplateFormNewContainer key={setKey()} saveEventTypeTemplateCallback={this.addEventTypeTemplateToTable} senderId={this.state.sender.id}/>
        </Modal>
    let eventTypeTemplates = <EventTypeTemplatesTable eventTypeTemplateCreated={this.state.eventTypeTemplateCreated} onEventTypeTemplatesLoaded={this.resetState} senderId={this.state.sender.id}/>


   /* notificationEmailsForm={notificationEmailsForm}
    * let notificationEmailsForm = <NotificationEmailsForm onSubmitCallback={this.updateSenderState} sender={this.state.sender} />
    * let memberNoticesTable = <MemberNoticesTable memberNoticeCreated={this.state.memberNoticeCreated} onMemberNoticesLoaded={this.resetState} senderId={this.state.sender.id}/>
    */
    let dailyDigestForm = <DailyDigestForm onSubmitCallback={this.updateSenderState} sender={this.state.sender} />
    let weeklyDigestForm = <WeeklyDigestForm onSubmitCallback={this.updateSenderState} sender={this.state.sender} />

    return (
      <div className="be-wrapper be-nosidebar-left site-sender with-sub-nav">

      {newEventTypeTemplateModal}
      <SenderNav sender={this.state.sender}/>
        <div className="be-content">
        <SectionHeader label='Settings' hideNew={true}/>
          <div className="main-content container-fluid main-content-padding sender-settings">
            <SettingsTabs sender={this.state.sender}
              dailyDigestForm={dailyDigestForm}
              weeklyDigestForm={weeklyDigestForm}
              eventTypeTemplates={eventTypeTemplates}
              />
          </div>
        </div>
      </div>
    );
  }
}

SenderSettings.contextType = SenderContext;
export default SenderSettings;
