import React, { Component } from 'react';
import {Promise} from "bluebird";
import PasswordField from '../generic/PasswordField';
import ErrorList from '../generic/ErrorList';
import { fetch, generateUrl,authenticate } from '../utils/Fetcher'
import ls from 'local-storage';

class SignupForm extends Component {
  constructor(){
    super();
    this.state = {
      error: null,
      password: null
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handlePasswordChange(val){
    this.setState({password: val})
  }

  componentDidMount(){
    document.body.classList.add('be-splash-screen');
  }


  // Handle form submission.
  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    let formData = new FormData();
    formData.append('member[user_attributes][email]', _this.refs.email.value);
    formData.append('member[user_attributes][first_name]', _this.refs.firstName.value);
    formData.append('member[user_attributes][last_name]', _this.refs.lastName.value);
    formData.append('member[user_attributes][password]', _this.state.password);
    fetch('/api/v1/members/create_with_user', {
      method: 'POST',
      body: formData,
      headers: { subdomain: window.location.hostname.split(".")[0] }

    }).then(result => {
      if(result.errors){
        this.setState({error: result.errors})
      }else{
        authenticate(_this.refs.email.value,  this.state.password).then(resp=>{
          if(resp=="redirect"){
            window.location.href = '/';
          } else{
            _this.setState({error: resp});
          }
        })
      }

   }).catch(err=>{
    this.setState({error: err})
   })
  };



/*
 *fetchAuthorized(this.baseUrl,{
      method: 'POST',
      body: formData
    })
 *formData.append('member[user_attributes][id]', formObjectValues.user_id);
    formData.append('member[user_attributes][email]', formObjectValues.email);
    formData.append('member[user_attributes][last_name]', formObjectValues.last_name);
    formData.append('member[user_attributes][password]', formObjectValues.password);
 */





  render() {



  let errors = <ErrorList errors={this.state.error}/>
  let passwordField = <PasswordField onChange={this.handlePasswordChange}/>;
  return (

      <form>
      {errors}
 <div className="form-group">
            <label className="input-header">Email:</label>
            <input ref="email" className="form-control" id="email" type="text" placeholder="Enter Email" defaultValue={this.props.email}/>
          </div>

           <div className="form-group">
            <label className="input-header">First Name:</label>
            <input ref="firstName" className="form-control" id="firstName" type="text" placeholder="Enter First Name" defaultValue={this.props.firstName}/>
          </div>

           <div className="form-group">
            <label className="input-header">Last Name:</label>
            <input ref="lastName" className="form-control" id="lastName" type="text" placeholder="Enter Last Name" defaultValue={this.props.lastName}/>
          </div>
          {passwordField}

        <div className="form-group row">
            <div className="row save-changes-container">
              <div className="col-sm-6">
                <p className="text-left">
                  <button onClick={this.handleSubmit} className="btn btn-space btn-primary btn-xl" type="submit">Sign Up</button>
                </p>
              </div>
            </div>
          </div>
    </form>

      )
  }
}

export default SignupForm;
