import React, { Component } from 'react';

class CssLoader extends Component {

  render() {
    return (
      <style>
      {this.props.css}
      </style>
      )
  }
}
export default CssLoader;





